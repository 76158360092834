nav {
	padding: 1rem;
	width: 100%;
	background: #161616;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	img {
		width: 120px; } }
#spielfeld {
	width: 100vw;
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	.game--content {
		box-shadow: 0 0 20px rgba(black, 0.15);
		// border: 2px solid white
		overflow: hidden;
		border-radius: 5px;
		width: 80vw;
		position: relative;
		display: grid;
		grid-template-columns: repeat(20, calc(80vw / 20));
		grid-template-rows: repeat(11, calc(80vw / 20));
		grid-template-areas: "h h h h h h h h h h h h h h h h h h h h" "v a1 b1 c1 d1 e1 f1 g1 h1 i1 j1 k1 robert m1 n1 o1 p1 heike heike heike" "v a2 b2 c2 d2 e2 sabrina g2 h2 i2 j2 k2 robert m2 n2 o2 p2 q2 r2 s2" "v a3 b3 c3 d3 e3 sabrina g3 h3 i3 j3 k3 l3 m3 n3 o3 p3 q3 r3 s3" "v a4 b4 c4 d4 e4 sabrina g4 h4 i4 j4 schulze schulze schulze n4 o4 p4 q4 r4 s4" "v a5 b5 c5 d5 e5 f5 g5 h5 i5 j5 schulze schulze schulze n5 o5 p5 q5 r5 s5" "v a6 b6 c6 d6 e6 f6 g6 h6 stefan j6 schulze schulze schulze n6 o6 p6 q6 r6 s6" "v a7 b7 c7 d7 e7 f7 g7 h7 stefan j7 k7 l7 m7 n7 o7 torben torben torben s7" "v thomas thomas c8 d8 emma emma emma h8 stefan j8 k8 marcel marcel marcel o8 torben torben torben s8" "v thomas thomas c9 d9 e9 f9 g9 h9 i9 j9 k9 marcel marcel marcel o9 torben torben torben s9" "v thomas thomas c10 d10 e10 f10 g10 h10 i10 j10 k10 marcel marcel marcel o10 p10 q10 r10 s10";
		.info--border-h {
			background: rgba(#67DBF8, 0.8);
			grid-area: h;
			display: grid;
			grid-template-columns: repeat(20, calc(80vw / 20));
			align-items: end;
			position: relative;
			.raster {
				padding-bottom: 1rem;
				color: #0E586D;
				font-weight: bold;
				text-align: center; } }
		.info--border-v {
			background: rgba(#67DBF8, 0.8);
			grid-area: v;
			display: grid;
			position: relative;
			grid-template-rows: repeat(10, calc(80vw / 20));
			.raster {
				color: #0E586D;
				font-weight: bold;
				text-align: center;
				padding: 0 0.5rem;
				display: flex;
				align-items: center;
				justify-content: center; } } }
	video {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
		z-index: 0; }
	.input--group {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		.interaction {
			display: none;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%; }
		&.hit {
			// background: url('../img/treffer2.gif') center no-repeat
			// background-size: contain
			.interaction {
				display: block; } }
		&.miss {
			animation: waves 1s forwards ease;
			.interaction {
				display: block; } }
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%; }
		label {
			width: 100%;
			height: 100%;
			// background: rgba(#0E586D, 1)
			background: url('../img/m.svg'), linear-gradient(45deg, #0E586D, #318DA6);
			background-size: 20%, cover;
			background-repeat: no-repeat;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			cursor: url('../img/cursor.png'), auto;
			// border: 1px solid rgba(white, 0.2)
			&:hover {
				background: url('../img/m.svg'), linear-gradient(45deg, #318DA6, #318DA6);
				border-color: #ffb81c;
				background-size: 20%, cover;
				background-repeat: no-repeat;
				background-position: center; } }
		input {
			display: none; }
		input:checked ~ label {
			background: transparent;
			border-color: transparent; } }
	.contain {
		background-size: 90%;
		background-position: center;
		background-repeat: no-repeat;
		animation: wiggle forwards infinite 6s; }

	.trefferbild {
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		img {
			width: 100%;
			display: block;
			height: auto; } }
	.manager {
		position: relative;
		object-fit: contain;
		width: 100%;
		z-index: 0;
		pointer-events: none;
		animation: wiggle alternate 3s infinite ease;
		transform-origin: center; }
	.small {
		width: 80%;
		left: 10%;
		top: 10%; }
	.robert {
		grid-area: robert; }
	.stefan {
		grid-area: stefan; }
	.thomas {
		grid-area: thomas; }
	.schulze   {
		grid-area: schulze  ; }
	.torben   {
		grid-area: torben  ; }
	.heike   {
		grid-area: heike  ; }
	.a1   {
		grid-area: a1  ; }
	.sabrina   {
		grid-area: sabrina  ; }
	.c6   {
		grid-area: c6  ; }
	.marcel   {
		grid-area: marcel  ; }
	.p4   {
		grid-area: p4  ; }
	.s3   {
		grid-area: s3  ; }
	.emma   {
		grid-area: emma  ; }
	// Reihen

	@for $i from 0 through 189 {
		@for $j from 0 through 18 {
			.group-#{$i * 19 + $j} {
				grid-column: #{$j + 2} / #{$j + 3}; } } }



	// Zeilen

	@for $i from 0 through 18 {
		.group-#{$i} {
			grid-row: 2 / 3; } }
	@for $i from 19 through 37 {
		.group-#{$i} {
			grid-row: 3 / 4; } }
	@for $i from 38 through 56 {
		.group-#{$i} {
			grid-row: 4 / 5; } }
	@for $i from 57 through 75 {
		.group-#{$i} {
			grid-row: 5 / 6; } }
	@for $i from 76 through 94 {
		.group-#{$i} {
			grid-row: 6 / 7; } }
	@for $i from 95 through 113 {
		.group-#{$i} {
			grid-row: 7 / 8; } }
	@for $i from 114 through 132 {
		.group-#{$i} {
			grid-row: 8 / 9; } }
	@for $i from 133 through 151 {
		.group-#{$i} {
			grid-row: 9 / 10; } }
	@for $i from 152 through 170 {
		.group-#{$i} {
			grid-row: 10 / 11; } }
	@for $i from 171 through 189 {
		.group-#{$i} {
			grid-row: 11 / 12; } } }

#endscreen {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	z-index: 10;
	background: rgba(#318DA6, 0.8);
	display: none;
	&.active {
		display: flex; }
	.content {
		background: #161616;
		padding: 2rem;
		width: 40rem;
		max-width: 90vw;
		text-align: center;
		border-radius: 5px;
		h1 {
			color: #ffb81c; }
		h2 {
			color: white; } } }
