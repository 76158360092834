* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: sans-serif; }

html, body {
	height: 100%;
	font-size: 100%;
	line-height: 1.4;
	transition: all 1s ease;
	background: #67DBF8;
	background: #318DA6;
	background: linear-gradient(45deg, #318DA6, #67DBF8); }

.clearfix::after {
	content: ".";
	display: block;
	clear: both;
	font-size: 0;
	height: 0;
	visibility: hidden; }


