@keyframes wiggle {
	0%, 100% {
		transform: translate(0%,1%); }
	50% {
		transform: translate(1%,0%); } }


@keyframes waves {
	from {
		background: radial-gradient(rgba(#67DBF8, 0.5), transparent, rgba(#67DBF8, 0.5), transparent, rgba(#67DBF8, 0.5), transparent, rgba(#67DBF8, 0.5), transparent);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center; }
	to {
		background: radial-gradient(rgba(#67DBF8, 0), transparent, rgba(#67DBF8, 0), transparent, rgba(#67DBF8, 0), transparent, rgba(#67DBF8, 0), transparent);
		background-repeat: no-repeat;
		background-size: 400% 400%;
		background-position: center; } }
