@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif; }

html, body {
  height: 100%;
  font-size: 100%;
  line-height: 1.4;
  transition: all 1s ease;
  background: #67DBF8;
  background: #318DA6;
  background: linear-gradient(45deg, #318DA6, #67DBF8); }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

@keyframes wiggle {
  0%, 100% {
    transform: translate(0%, 1%); }
  50% {
    transform: translate(1%, 0%); } }

@keyframes waves {
  from {
    background: radial-gradient(rgba(103, 219, 248, 0.5), transparent, rgba(103, 219, 248, 0.5), transparent, rgba(103, 219, 248, 0.5), transparent, rgba(103, 219, 248, 0.5), transparent);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; }
  to {
    background: radial-gradient(rgba(103, 219, 248, 0), transparent, rgba(103, 219, 248, 0), transparent, rgba(103, 219, 248, 0), transparent, rgba(103, 219, 248, 0), transparent);
    background-repeat: no-repeat;
    background-size: 400% 400%;
    background-position: center; } }

nav {
  padding: 1rem;
  width: 100%;
  background: #161616;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10; }
  nav img {
    width: 120px; }

#spielfeld {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  #spielfeld .game--content {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 5px;
    width: 80vw;
    position: relative;
    display: grid;
    grid-template-columns: repeat(20, calc(80vw / 20));
    grid-template-rows: repeat(11, calc(80vw / 20));
    grid-template-areas: "h h h h h h h h h h h h h h h h h h h h" "v a1 b1 c1 d1 e1 f1 g1 h1 i1 j1 k1 robert m1 n1 o1 p1 heike heike heike" "v a2 b2 c2 d2 e2 sabrina g2 h2 i2 j2 k2 robert m2 n2 o2 p2 q2 r2 s2" "v a3 b3 c3 d3 e3 sabrina g3 h3 i3 j3 k3 l3 m3 n3 o3 p3 q3 r3 s3" "v a4 b4 c4 d4 e4 sabrina g4 h4 i4 j4 schulze schulze schulze n4 o4 p4 q4 r4 s4" "v a5 b5 c5 d5 e5 f5 g5 h5 i5 j5 schulze schulze schulze n5 o5 p5 q5 r5 s5" "v a6 b6 c6 d6 e6 f6 g6 h6 stefan j6 schulze schulze schulze n6 o6 p6 q6 r6 s6" "v a7 b7 c7 d7 e7 f7 g7 h7 stefan j7 k7 l7 m7 n7 o7 torben torben torben s7" "v thomas thomas c8 d8 emma emma emma h8 stefan j8 k8 marcel marcel marcel o8 torben torben torben s8" "v thomas thomas c9 d9 e9 f9 g9 h9 i9 j9 k9 marcel marcel marcel o9 torben torben torben s9" "v thomas thomas c10 d10 e10 f10 g10 h10 i10 j10 k10 marcel marcel marcel o10 p10 q10 r10 s10"; }
    #spielfeld .game--content .info--border-h {
      background: rgba(103, 219, 248, 0.8);
      grid-area: h;
      display: grid;
      grid-template-columns: repeat(20, calc(80vw / 20));
      align-items: end;
      position: relative; }
      #spielfeld .game--content .info--border-h .raster {
        padding-bottom: 1rem;
        color: #0E586D;
        font-weight: bold;
        text-align: center; }
    #spielfeld .game--content .info--border-v {
      background: rgba(103, 219, 248, 0.8);
      grid-area: v;
      display: grid;
      position: relative;
      grid-template-rows: repeat(10, calc(80vw / 20)); }
      #spielfeld .game--content .info--border-v .raster {
        color: #0E586D;
        font-weight: bold;
        text-align: center;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center; }
  #spielfeld video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0; }
  #spielfeld .input--group {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1; }
    #spielfeld .input--group .interaction {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
    #spielfeld .input--group.hit .interaction {
      display: block; }
    #spielfeld .input--group.miss {
      animation: waves 1s forwards ease; }
      #spielfeld .input--group.miss .interaction {
        display: block; }
    #spielfeld .input--group img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
    #spielfeld .input--group label {
      width: 100%;
      height: 100%;
      background: url("../img/m.svg"), linear-gradient(45deg, #0E586D, #318DA6);
      background-size: 20%, cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      cursor: url("../img/cursor.png"), auto; }
      #spielfeld .input--group label:hover {
        background: url("../img/m.svg"), linear-gradient(45deg, #318DA6, #318DA6);
        border-color: #ffb81c;
        background-size: 20%, cover;
        background-repeat: no-repeat;
        background-position: center; }
    #spielfeld .input--group input {
      display: none; }
    #spielfeld .input--group input:checked ~ label {
      background: transparent;
      border-color: transparent; }
  #spielfeld .contain {
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    animation: wiggle forwards infinite 6s; }
  #spielfeld .trefferbild {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; }
    #spielfeld .trefferbild img {
      width: 100%;
      display: block;
      height: auto; }
  #spielfeld .manager {
    position: relative;
    object-fit: contain;
    width: 100%;
    z-index: 0;
    pointer-events: none;
    animation: wiggle alternate 3s infinite ease;
    transform-origin: center; }
  #spielfeld .small {
    width: 80%;
    left: 10%;
    top: 10%; }
  #spielfeld .robert {
    grid-area: robert; }
  #spielfeld .stefan {
    grid-area: stefan; }
  #spielfeld .thomas {
    grid-area: thomas; }
  #spielfeld .schulze   {
    grid-area: schulze  ; }
  #spielfeld .torben   {
    grid-area: torben  ; }
  #spielfeld .heike   {
    grid-area: heike  ; }
  #spielfeld .a1   {
    grid-area: a1  ; }
  #spielfeld .sabrina   {
    grid-area: sabrina  ; }
  #spielfeld .c6   {
    grid-area: c6  ; }
  #spielfeld .marcel   {
    grid-area: marcel  ; }
  #spielfeld .p4   {
    grid-area: p4  ; }
  #spielfeld .s3   {
    grid-area: s3  ; }
  #spielfeld .emma   {
    grid-area: emma  ; }
  #spielfeld .group-0 {
    grid-column: 2 / 3; }
  #spielfeld .group-1 {
    grid-column: 3 / 4; }
  #spielfeld .group-2 {
    grid-column: 4 / 5; }
  #spielfeld .group-3 {
    grid-column: 5 / 6; }
  #spielfeld .group-4 {
    grid-column: 6 / 7; }
  #spielfeld .group-5 {
    grid-column: 7 / 8; }
  #spielfeld .group-6 {
    grid-column: 8 / 9; }
  #spielfeld .group-7 {
    grid-column: 9 / 10; }
  #spielfeld .group-8 {
    grid-column: 10 / 11; }
  #spielfeld .group-9 {
    grid-column: 11 / 12; }
  #spielfeld .group-10 {
    grid-column: 12 / 13; }
  #spielfeld .group-11 {
    grid-column: 13 / 14; }
  #spielfeld .group-12 {
    grid-column: 14 / 15; }
  #spielfeld .group-13 {
    grid-column: 15 / 16; }
  #spielfeld .group-14 {
    grid-column: 16 / 17; }
  #spielfeld .group-15 {
    grid-column: 17 / 18; }
  #spielfeld .group-16 {
    grid-column: 18 / 19; }
  #spielfeld .group-17 {
    grid-column: 19 / 20; }
  #spielfeld .group-18 {
    grid-column: 20 / 21; }
  #spielfeld .group-19 {
    grid-column: 2 / 3; }
  #spielfeld .group-20 {
    grid-column: 3 / 4; }
  #spielfeld .group-21 {
    grid-column: 4 / 5; }
  #spielfeld .group-22 {
    grid-column: 5 / 6; }
  #spielfeld .group-23 {
    grid-column: 6 / 7; }
  #spielfeld .group-24 {
    grid-column: 7 / 8; }
  #spielfeld .group-25 {
    grid-column: 8 / 9; }
  #spielfeld .group-26 {
    grid-column: 9 / 10; }
  #spielfeld .group-27 {
    grid-column: 10 / 11; }
  #spielfeld .group-28 {
    grid-column: 11 / 12; }
  #spielfeld .group-29 {
    grid-column: 12 / 13; }
  #spielfeld .group-30 {
    grid-column: 13 / 14; }
  #spielfeld .group-31 {
    grid-column: 14 / 15; }
  #spielfeld .group-32 {
    grid-column: 15 / 16; }
  #spielfeld .group-33 {
    grid-column: 16 / 17; }
  #spielfeld .group-34 {
    grid-column: 17 / 18; }
  #spielfeld .group-35 {
    grid-column: 18 / 19; }
  #spielfeld .group-36 {
    grid-column: 19 / 20; }
  #spielfeld .group-37 {
    grid-column: 20 / 21; }
  #spielfeld .group-38 {
    grid-column: 2 / 3; }
  #spielfeld .group-39 {
    grid-column: 3 / 4; }
  #spielfeld .group-40 {
    grid-column: 4 / 5; }
  #spielfeld .group-41 {
    grid-column: 5 / 6; }
  #spielfeld .group-42 {
    grid-column: 6 / 7; }
  #spielfeld .group-43 {
    grid-column: 7 / 8; }
  #spielfeld .group-44 {
    grid-column: 8 / 9; }
  #spielfeld .group-45 {
    grid-column: 9 / 10; }
  #spielfeld .group-46 {
    grid-column: 10 / 11; }
  #spielfeld .group-47 {
    grid-column: 11 / 12; }
  #spielfeld .group-48 {
    grid-column: 12 / 13; }
  #spielfeld .group-49 {
    grid-column: 13 / 14; }
  #spielfeld .group-50 {
    grid-column: 14 / 15; }
  #spielfeld .group-51 {
    grid-column: 15 / 16; }
  #spielfeld .group-52 {
    grid-column: 16 / 17; }
  #spielfeld .group-53 {
    grid-column: 17 / 18; }
  #spielfeld .group-54 {
    grid-column: 18 / 19; }
  #spielfeld .group-55 {
    grid-column: 19 / 20; }
  #spielfeld .group-56 {
    grid-column: 20 / 21; }
  #spielfeld .group-57 {
    grid-column: 2 / 3; }
  #spielfeld .group-58 {
    grid-column: 3 / 4; }
  #spielfeld .group-59 {
    grid-column: 4 / 5; }
  #spielfeld .group-60 {
    grid-column: 5 / 6; }
  #spielfeld .group-61 {
    grid-column: 6 / 7; }
  #spielfeld .group-62 {
    grid-column: 7 / 8; }
  #spielfeld .group-63 {
    grid-column: 8 / 9; }
  #spielfeld .group-64 {
    grid-column: 9 / 10; }
  #spielfeld .group-65 {
    grid-column: 10 / 11; }
  #spielfeld .group-66 {
    grid-column: 11 / 12; }
  #spielfeld .group-67 {
    grid-column: 12 / 13; }
  #spielfeld .group-68 {
    grid-column: 13 / 14; }
  #spielfeld .group-69 {
    grid-column: 14 / 15; }
  #spielfeld .group-70 {
    grid-column: 15 / 16; }
  #spielfeld .group-71 {
    grid-column: 16 / 17; }
  #spielfeld .group-72 {
    grid-column: 17 / 18; }
  #spielfeld .group-73 {
    grid-column: 18 / 19; }
  #spielfeld .group-74 {
    grid-column: 19 / 20; }
  #spielfeld .group-75 {
    grid-column: 20 / 21; }
  #spielfeld .group-76 {
    grid-column: 2 / 3; }
  #spielfeld .group-77 {
    grid-column: 3 / 4; }
  #spielfeld .group-78 {
    grid-column: 4 / 5; }
  #spielfeld .group-79 {
    grid-column: 5 / 6; }
  #spielfeld .group-80 {
    grid-column: 6 / 7; }
  #spielfeld .group-81 {
    grid-column: 7 / 8; }
  #spielfeld .group-82 {
    grid-column: 8 / 9; }
  #spielfeld .group-83 {
    grid-column: 9 / 10; }
  #spielfeld .group-84 {
    grid-column: 10 / 11; }
  #spielfeld .group-85 {
    grid-column: 11 / 12; }
  #spielfeld .group-86 {
    grid-column: 12 / 13; }
  #spielfeld .group-87 {
    grid-column: 13 / 14; }
  #spielfeld .group-88 {
    grid-column: 14 / 15; }
  #spielfeld .group-89 {
    grid-column: 15 / 16; }
  #spielfeld .group-90 {
    grid-column: 16 / 17; }
  #spielfeld .group-91 {
    grid-column: 17 / 18; }
  #spielfeld .group-92 {
    grid-column: 18 / 19; }
  #spielfeld .group-93 {
    grid-column: 19 / 20; }
  #spielfeld .group-94 {
    grid-column: 20 / 21; }
  #spielfeld .group-95 {
    grid-column: 2 / 3; }
  #spielfeld .group-96 {
    grid-column: 3 / 4; }
  #spielfeld .group-97 {
    grid-column: 4 / 5; }
  #spielfeld .group-98 {
    grid-column: 5 / 6; }
  #spielfeld .group-99 {
    grid-column: 6 / 7; }
  #spielfeld .group-100 {
    grid-column: 7 / 8; }
  #spielfeld .group-101 {
    grid-column: 8 / 9; }
  #spielfeld .group-102 {
    grid-column: 9 / 10; }
  #spielfeld .group-103 {
    grid-column: 10 / 11; }
  #spielfeld .group-104 {
    grid-column: 11 / 12; }
  #spielfeld .group-105 {
    grid-column: 12 / 13; }
  #spielfeld .group-106 {
    grid-column: 13 / 14; }
  #spielfeld .group-107 {
    grid-column: 14 / 15; }
  #spielfeld .group-108 {
    grid-column: 15 / 16; }
  #spielfeld .group-109 {
    grid-column: 16 / 17; }
  #spielfeld .group-110 {
    grid-column: 17 / 18; }
  #spielfeld .group-111 {
    grid-column: 18 / 19; }
  #spielfeld .group-112 {
    grid-column: 19 / 20; }
  #spielfeld .group-113 {
    grid-column: 20 / 21; }
  #spielfeld .group-114 {
    grid-column: 2 / 3; }
  #spielfeld .group-115 {
    grid-column: 3 / 4; }
  #spielfeld .group-116 {
    grid-column: 4 / 5; }
  #spielfeld .group-117 {
    grid-column: 5 / 6; }
  #spielfeld .group-118 {
    grid-column: 6 / 7; }
  #spielfeld .group-119 {
    grid-column: 7 / 8; }
  #spielfeld .group-120 {
    grid-column: 8 / 9; }
  #spielfeld .group-121 {
    grid-column: 9 / 10; }
  #spielfeld .group-122 {
    grid-column: 10 / 11; }
  #spielfeld .group-123 {
    grid-column: 11 / 12; }
  #spielfeld .group-124 {
    grid-column: 12 / 13; }
  #spielfeld .group-125 {
    grid-column: 13 / 14; }
  #spielfeld .group-126 {
    grid-column: 14 / 15; }
  #spielfeld .group-127 {
    grid-column: 15 / 16; }
  #spielfeld .group-128 {
    grid-column: 16 / 17; }
  #spielfeld .group-129 {
    grid-column: 17 / 18; }
  #spielfeld .group-130 {
    grid-column: 18 / 19; }
  #spielfeld .group-131 {
    grid-column: 19 / 20; }
  #spielfeld .group-132 {
    grid-column: 20 / 21; }
  #spielfeld .group-133 {
    grid-column: 2 / 3; }
  #spielfeld .group-134 {
    grid-column: 3 / 4; }
  #spielfeld .group-135 {
    grid-column: 4 / 5; }
  #spielfeld .group-136 {
    grid-column: 5 / 6; }
  #spielfeld .group-137 {
    grid-column: 6 / 7; }
  #spielfeld .group-138 {
    grid-column: 7 / 8; }
  #spielfeld .group-139 {
    grid-column: 8 / 9; }
  #spielfeld .group-140 {
    grid-column: 9 / 10; }
  #spielfeld .group-141 {
    grid-column: 10 / 11; }
  #spielfeld .group-142 {
    grid-column: 11 / 12; }
  #spielfeld .group-143 {
    grid-column: 12 / 13; }
  #spielfeld .group-144 {
    grid-column: 13 / 14; }
  #spielfeld .group-145 {
    grid-column: 14 / 15; }
  #spielfeld .group-146 {
    grid-column: 15 / 16; }
  #spielfeld .group-147 {
    grid-column: 16 / 17; }
  #spielfeld .group-148 {
    grid-column: 17 / 18; }
  #spielfeld .group-149 {
    grid-column: 18 / 19; }
  #spielfeld .group-150 {
    grid-column: 19 / 20; }
  #spielfeld .group-151 {
    grid-column: 20 / 21; }
  #spielfeld .group-152 {
    grid-column: 2 / 3; }
  #spielfeld .group-153 {
    grid-column: 3 / 4; }
  #spielfeld .group-154 {
    grid-column: 4 / 5; }
  #spielfeld .group-155 {
    grid-column: 5 / 6; }
  #spielfeld .group-156 {
    grid-column: 6 / 7; }
  #spielfeld .group-157 {
    grid-column: 7 / 8; }
  #spielfeld .group-158 {
    grid-column: 8 / 9; }
  #spielfeld .group-159 {
    grid-column: 9 / 10; }
  #spielfeld .group-160 {
    grid-column: 10 / 11; }
  #spielfeld .group-161 {
    grid-column: 11 / 12; }
  #spielfeld .group-162 {
    grid-column: 12 / 13; }
  #spielfeld .group-163 {
    grid-column: 13 / 14; }
  #spielfeld .group-164 {
    grid-column: 14 / 15; }
  #spielfeld .group-165 {
    grid-column: 15 / 16; }
  #spielfeld .group-166 {
    grid-column: 16 / 17; }
  #spielfeld .group-167 {
    grid-column: 17 / 18; }
  #spielfeld .group-168 {
    grid-column: 18 / 19; }
  #spielfeld .group-169 {
    grid-column: 19 / 20; }
  #spielfeld .group-170 {
    grid-column: 20 / 21; }
  #spielfeld .group-171 {
    grid-column: 2 / 3; }
  #spielfeld .group-172 {
    grid-column: 3 / 4; }
  #spielfeld .group-173 {
    grid-column: 4 / 5; }
  #spielfeld .group-174 {
    grid-column: 5 / 6; }
  #spielfeld .group-175 {
    grid-column: 6 / 7; }
  #spielfeld .group-176 {
    grid-column: 7 / 8; }
  #spielfeld .group-177 {
    grid-column: 8 / 9; }
  #spielfeld .group-178 {
    grid-column: 9 / 10; }
  #spielfeld .group-179 {
    grid-column: 10 / 11; }
  #spielfeld .group-180 {
    grid-column: 11 / 12; }
  #spielfeld .group-181 {
    grid-column: 12 / 13; }
  #spielfeld .group-182 {
    grid-column: 13 / 14; }
  #spielfeld .group-183 {
    grid-column: 14 / 15; }
  #spielfeld .group-184 {
    grid-column: 15 / 16; }
  #spielfeld .group-185 {
    grid-column: 16 / 17; }
  #spielfeld .group-186 {
    grid-column: 17 / 18; }
  #spielfeld .group-187 {
    grid-column: 18 / 19; }
  #spielfeld .group-188 {
    grid-column: 19 / 20; }
  #spielfeld .group-189 {
    grid-column: 20 / 21; }
  #spielfeld .group-190 {
    grid-column: 2 / 3; }
  #spielfeld .group-191 {
    grid-column: 3 / 4; }
  #spielfeld .group-192 {
    grid-column: 4 / 5; }
  #spielfeld .group-193 {
    grid-column: 5 / 6; }
  #spielfeld .group-194 {
    grid-column: 6 / 7; }
  #spielfeld .group-195 {
    grid-column: 7 / 8; }
  #spielfeld .group-196 {
    grid-column: 8 / 9; }
  #spielfeld .group-197 {
    grid-column: 9 / 10; }
  #spielfeld .group-198 {
    grid-column: 10 / 11; }
  #spielfeld .group-199 {
    grid-column: 11 / 12; }
  #spielfeld .group-200 {
    grid-column: 12 / 13; }
  #spielfeld .group-201 {
    grid-column: 13 / 14; }
  #spielfeld .group-202 {
    grid-column: 14 / 15; }
  #spielfeld .group-203 {
    grid-column: 15 / 16; }
  #spielfeld .group-204 {
    grid-column: 16 / 17; }
  #spielfeld .group-205 {
    grid-column: 17 / 18; }
  #spielfeld .group-206 {
    grid-column: 18 / 19; }
  #spielfeld .group-207 {
    grid-column: 19 / 20; }
  #spielfeld .group-208 {
    grid-column: 20 / 21; }
  #spielfeld .group-209 {
    grid-column: 2 / 3; }
  #spielfeld .group-210 {
    grid-column: 3 / 4; }
  #spielfeld .group-211 {
    grid-column: 4 / 5; }
  #spielfeld .group-212 {
    grid-column: 5 / 6; }
  #spielfeld .group-213 {
    grid-column: 6 / 7; }
  #spielfeld .group-214 {
    grid-column: 7 / 8; }
  #spielfeld .group-215 {
    grid-column: 8 / 9; }
  #spielfeld .group-216 {
    grid-column: 9 / 10; }
  #spielfeld .group-217 {
    grid-column: 10 / 11; }
  #spielfeld .group-218 {
    grid-column: 11 / 12; }
  #spielfeld .group-219 {
    grid-column: 12 / 13; }
  #spielfeld .group-220 {
    grid-column: 13 / 14; }
  #spielfeld .group-221 {
    grid-column: 14 / 15; }
  #spielfeld .group-222 {
    grid-column: 15 / 16; }
  #spielfeld .group-223 {
    grid-column: 16 / 17; }
  #spielfeld .group-224 {
    grid-column: 17 / 18; }
  #spielfeld .group-225 {
    grid-column: 18 / 19; }
  #spielfeld .group-226 {
    grid-column: 19 / 20; }
  #spielfeld .group-227 {
    grid-column: 20 / 21; }
  #spielfeld .group-228 {
    grid-column: 2 / 3; }
  #spielfeld .group-229 {
    grid-column: 3 / 4; }
  #spielfeld .group-230 {
    grid-column: 4 / 5; }
  #spielfeld .group-231 {
    grid-column: 5 / 6; }
  #spielfeld .group-232 {
    grid-column: 6 / 7; }
  #spielfeld .group-233 {
    grid-column: 7 / 8; }
  #spielfeld .group-234 {
    grid-column: 8 / 9; }
  #spielfeld .group-235 {
    grid-column: 9 / 10; }
  #spielfeld .group-236 {
    grid-column: 10 / 11; }
  #spielfeld .group-237 {
    grid-column: 11 / 12; }
  #spielfeld .group-238 {
    grid-column: 12 / 13; }
  #spielfeld .group-239 {
    grid-column: 13 / 14; }
  #spielfeld .group-240 {
    grid-column: 14 / 15; }
  #spielfeld .group-241 {
    grid-column: 15 / 16; }
  #spielfeld .group-242 {
    grid-column: 16 / 17; }
  #spielfeld .group-243 {
    grid-column: 17 / 18; }
  #spielfeld .group-244 {
    grid-column: 18 / 19; }
  #spielfeld .group-245 {
    grid-column: 19 / 20; }
  #spielfeld .group-246 {
    grid-column: 20 / 21; }
  #spielfeld .group-247 {
    grid-column: 2 / 3; }
  #spielfeld .group-248 {
    grid-column: 3 / 4; }
  #spielfeld .group-249 {
    grid-column: 4 / 5; }
  #spielfeld .group-250 {
    grid-column: 5 / 6; }
  #spielfeld .group-251 {
    grid-column: 6 / 7; }
  #spielfeld .group-252 {
    grid-column: 7 / 8; }
  #spielfeld .group-253 {
    grid-column: 8 / 9; }
  #spielfeld .group-254 {
    grid-column: 9 / 10; }
  #spielfeld .group-255 {
    grid-column: 10 / 11; }
  #spielfeld .group-256 {
    grid-column: 11 / 12; }
  #spielfeld .group-257 {
    grid-column: 12 / 13; }
  #spielfeld .group-258 {
    grid-column: 13 / 14; }
  #spielfeld .group-259 {
    grid-column: 14 / 15; }
  #spielfeld .group-260 {
    grid-column: 15 / 16; }
  #spielfeld .group-261 {
    grid-column: 16 / 17; }
  #spielfeld .group-262 {
    grid-column: 17 / 18; }
  #spielfeld .group-263 {
    grid-column: 18 / 19; }
  #spielfeld .group-264 {
    grid-column: 19 / 20; }
  #spielfeld .group-265 {
    grid-column: 20 / 21; }
  #spielfeld .group-266 {
    grid-column: 2 / 3; }
  #spielfeld .group-267 {
    grid-column: 3 / 4; }
  #spielfeld .group-268 {
    grid-column: 4 / 5; }
  #spielfeld .group-269 {
    grid-column: 5 / 6; }
  #spielfeld .group-270 {
    grid-column: 6 / 7; }
  #spielfeld .group-271 {
    grid-column: 7 / 8; }
  #spielfeld .group-272 {
    grid-column: 8 / 9; }
  #spielfeld .group-273 {
    grid-column: 9 / 10; }
  #spielfeld .group-274 {
    grid-column: 10 / 11; }
  #spielfeld .group-275 {
    grid-column: 11 / 12; }
  #spielfeld .group-276 {
    grid-column: 12 / 13; }
  #spielfeld .group-277 {
    grid-column: 13 / 14; }
  #spielfeld .group-278 {
    grid-column: 14 / 15; }
  #spielfeld .group-279 {
    grid-column: 15 / 16; }
  #spielfeld .group-280 {
    grid-column: 16 / 17; }
  #spielfeld .group-281 {
    grid-column: 17 / 18; }
  #spielfeld .group-282 {
    grid-column: 18 / 19; }
  #spielfeld .group-283 {
    grid-column: 19 / 20; }
  #spielfeld .group-284 {
    grid-column: 20 / 21; }
  #spielfeld .group-285 {
    grid-column: 2 / 3; }
  #spielfeld .group-286 {
    grid-column: 3 / 4; }
  #spielfeld .group-287 {
    grid-column: 4 / 5; }
  #spielfeld .group-288 {
    grid-column: 5 / 6; }
  #spielfeld .group-289 {
    grid-column: 6 / 7; }
  #spielfeld .group-290 {
    grid-column: 7 / 8; }
  #spielfeld .group-291 {
    grid-column: 8 / 9; }
  #spielfeld .group-292 {
    grid-column: 9 / 10; }
  #spielfeld .group-293 {
    grid-column: 10 / 11; }
  #spielfeld .group-294 {
    grid-column: 11 / 12; }
  #spielfeld .group-295 {
    grid-column: 12 / 13; }
  #spielfeld .group-296 {
    grid-column: 13 / 14; }
  #spielfeld .group-297 {
    grid-column: 14 / 15; }
  #spielfeld .group-298 {
    grid-column: 15 / 16; }
  #spielfeld .group-299 {
    grid-column: 16 / 17; }
  #spielfeld .group-300 {
    grid-column: 17 / 18; }
  #spielfeld .group-301 {
    grid-column: 18 / 19; }
  #spielfeld .group-302 {
    grid-column: 19 / 20; }
  #spielfeld .group-303 {
    grid-column: 20 / 21; }
  #spielfeld .group-304 {
    grid-column: 2 / 3; }
  #spielfeld .group-305 {
    grid-column: 3 / 4; }
  #spielfeld .group-306 {
    grid-column: 4 / 5; }
  #spielfeld .group-307 {
    grid-column: 5 / 6; }
  #spielfeld .group-308 {
    grid-column: 6 / 7; }
  #spielfeld .group-309 {
    grid-column: 7 / 8; }
  #spielfeld .group-310 {
    grid-column: 8 / 9; }
  #spielfeld .group-311 {
    grid-column: 9 / 10; }
  #spielfeld .group-312 {
    grid-column: 10 / 11; }
  #spielfeld .group-313 {
    grid-column: 11 / 12; }
  #spielfeld .group-314 {
    grid-column: 12 / 13; }
  #spielfeld .group-315 {
    grid-column: 13 / 14; }
  #spielfeld .group-316 {
    grid-column: 14 / 15; }
  #spielfeld .group-317 {
    grid-column: 15 / 16; }
  #spielfeld .group-318 {
    grid-column: 16 / 17; }
  #spielfeld .group-319 {
    grid-column: 17 / 18; }
  #spielfeld .group-320 {
    grid-column: 18 / 19; }
  #spielfeld .group-321 {
    grid-column: 19 / 20; }
  #spielfeld .group-322 {
    grid-column: 20 / 21; }
  #spielfeld .group-323 {
    grid-column: 2 / 3; }
  #spielfeld .group-324 {
    grid-column: 3 / 4; }
  #spielfeld .group-325 {
    grid-column: 4 / 5; }
  #spielfeld .group-326 {
    grid-column: 5 / 6; }
  #spielfeld .group-327 {
    grid-column: 6 / 7; }
  #spielfeld .group-328 {
    grid-column: 7 / 8; }
  #spielfeld .group-329 {
    grid-column: 8 / 9; }
  #spielfeld .group-330 {
    grid-column: 9 / 10; }
  #spielfeld .group-331 {
    grid-column: 10 / 11; }
  #spielfeld .group-332 {
    grid-column: 11 / 12; }
  #spielfeld .group-333 {
    grid-column: 12 / 13; }
  #spielfeld .group-334 {
    grid-column: 13 / 14; }
  #spielfeld .group-335 {
    grid-column: 14 / 15; }
  #spielfeld .group-336 {
    grid-column: 15 / 16; }
  #spielfeld .group-337 {
    grid-column: 16 / 17; }
  #spielfeld .group-338 {
    grid-column: 17 / 18; }
  #spielfeld .group-339 {
    grid-column: 18 / 19; }
  #spielfeld .group-340 {
    grid-column: 19 / 20; }
  #spielfeld .group-341 {
    grid-column: 20 / 21; }
  #spielfeld .group-342 {
    grid-column: 2 / 3; }
  #spielfeld .group-343 {
    grid-column: 3 / 4; }
  #spielfeld .group-344 {
    grid-column: 4 / 5; }
  #spielfeld .group-345 {
    grid-column: 5 / 6; }
  #spielfeld .group-346 {
    grid-column: 6 / 7; }
  #spielfeld .group-347 {
    grid-column: 7 / 8; }
  #spielfeld .group-348 {
    grid-column: 8 / 9; }
  #spielfeld .group-349 {
    grid-column: 9 / 10; }
  #spielfeld .group-350 {
    grid-column: 10 / 11; }
  #spielfeld .group-351 {
    grid-column: 11 / 12; }
  #spielfeld .group-352 {
    grid-column: 12 / 13; }
  #spielfeld .group-353 {
    grid-column: 13 / 14; }
  #spielfeld .group-354 {
    grid-column: 14 / 15; }
  #spielfeld .group-355 {
    grid-column: 15 / 16; }
  #spielfeld .group-356 {
    grid-column: 16 / 17; }
  #spielfeld .group-357 {
    grid-column: 17 / 18; }
  #spielfeld .group-358 {
    grid-column: 18 / 19; }
  #spielfeld .group-359 {
    grid-column: 19 / 20; }
  #spielfeld .group-360 {
    grid-column: 20 / 21; }
  #spielfeld .group-361 {
    grid-column: 2 / 3; }
  #spielfeld .group-362 {
    grid-column: 3 / 4; }
  #spielfeld .group-363 {
    grid-column: 4 / 5; }
  #spielfeld .group-364 {
    grid-column: 5 / 6; }
  #spielfeld .group-365 {
    grid-column: 6 / 7; }
  #spielfeld .group-366 {
    grid-column: 7 / 8; }
  #spielfeld .group-367 {
    grid-column: 8 / 9; }
  #spielfeld .group-368 {
    grid-column: 9 / 10; }
  #spielfeld .group-369 {
    grid-column: 10 / 11; }
  #spielfeld .group-370 {
    grid-column: 11 / 12; }
  #spielfeld .group-371 {
    grid-column: 12 / 13; }
  #spielfeld .group-372 {
    grid-column: 13 / 14; }
  #spielfeld .group-373 {
    grid-column: 14 / 15; }
  #spielfeld .group-374 {
    grid-column: 15 / 16; }
  #spielfeld .group-375 {
    grid-column: 16 / 17; }
  #spielfeld .group-376 {
    grid-column: 17 / 18; }
  #spielfeld .group-377 {
    grid-column: 18 / 19; }
  #spielfeld .group-378 {
    grid-column: 19 / 20; }
  #spielfeld .group-379 {
    grid-column: 20 / 21; }
  #spielfeld .group-380 {
    grid-column: 2 / 3; }
  #spielfeld .group-381 {
    grid-column: 3 / 4; }
  #spielfeld .group-382 {
    grid-column: 4 / 5; }
  #spielfeld .group-383 {
    grid-column: 5 / 6; }
  #spielfeld .group-384 {
    grid-column: 6 / 7; }
  #spielfeld .group-385 {
    grid-column: 7 / 8; }
  #spielfeld .group-386 {
    grid-column: 8 / 9; }
  #spielfeld .group-387 {
    grid-column: 9 / 10; }
  #spielfeld .group-388 {
    grid-column: 10 / 11; }
  #spielfeld .group-389 {
    grid-column: 11 / 12; }
  #spielfeld .group-390 {
    grid-column: 12 / 13; }
  #spielfeld .group-391 {
    grid-column: 13 / 14; }
  #spielfeld .group-392 {
    grid-column: 14 / 15; }
  #spielfeld .group-393 {
    grid-column: 15 / 16; }
  #spielfeld .group-394 {
    grid-column: 16 / 17; }
  #spielfeld .group-395 {
    grid-column: 17 / 18; }
  #spielfeld .group-396 {
    grid-column: 18 / 19; }
  #spielfeld .group-397 {
    grid-column: 19 / 20; }
  #spielfeld .group-398 {
    grid-column: 20 / 21; }
  #spielfeld .group-399 {
    grid-column: 2 / 3; }
  #spielfeld .group-400 {
    grid-column: 3 / 4; }
  #spielfeld .group-401 {
    grid-column: 4 / 5; }
  #spielfeld .group-402 {
    grid-column: 5 / 6; }
  #spielfeld .group-403 {
    grid-column: 6 / 7; }
  #spielfeld .group-404 {
    grid-column: 7 / 8; }
  #spielfeld .group-405 {
    grid-column: 8 / 9; }
  #spielfeld .group-406 {
    grid-column: 9 / 10; }
  #spielfeld .group-407 {
    grid-column: 10 / 11; }
  #spielfeld .group-408 {
    grid-column: 11 / 12; }
  #spielfeld .group-409 {
    grid-column: 12 / 13; }
  #spielfeld .group-410 {
    grid-column: 13 / 14; }
  #spielfeld .group-411 {
    grid-column: 14 / 15; }
  #spielfeld .group-412 {
    grid-column: 15 / 16; }
  #spielfeld .group-413 {
    grid-column: 16 / 17; }
  #spielfeld .group-414 {
    grid-column: 17 / 18; }
  #spielfeld .group-415 {
    grid-column: 18 / 19; }
  #spielfeld .group-416 {
    grid-column: 19 / 20; }
  #spielfeld .group-417 {
    grid-column: 20 / 21; }
  #spielfeld .group-418 {
    grid-column: 2 / 3; }
  #spielfeld .group-419 {
    grid-column: 3 / 4; }
  #spielfeld .group-420 {
    grid-column: 4 / 5; }
  #spielfeld .group-421 {
    grid-column: 5 / 6; }
  #spielfeld .group-422 {
    grid-column: 6 / 7; }
  #spielfeld .group-423 {
    grid-column: 7 / 8; }
  #spielfeld .group-424 {
    grid-column: 8 / 9; }
  #spielfeld .group-425 {
    grid-column: 9 / 10; }
  #spielfeld .group-426 {
    grid-column: 10 / 11; }
  #spielfeld .group-427 {
    grid-column: 11 / 12; }
  #spielfeld .group-428 {
    grid-column: 12 / 13; }
  #spielfeld .group-429 {
    grid-column: 13 / 14; }
  #spielfeld .group-430 {
    grid-column: 14 / 15; }
  #spielfeld .group-431 {
    grid-column: 15 / 16; }
  #spielfeld .group-432 {
    grid-column: 16 / 17; }
  #spielfeld .group-433 {
    grid-column: 17 / 18; }
  #spielfeld .group-434 {
    grid-column: 18 / 19; }
  #spielfeld .group-435 {
    grid-column: 19 / 20; }
  #spielfeld .group-436 {
    grid-column: 20 / 21; }
  #spielfeld .group-437 {
    grid-column: 2 / 3; }
  #spielfeld .group-438 {
    grid-column: 3 / 4; }
  #spielfeld .group-439 {
    grid-column: 4 / 5; }
  #spielfeld .group-440 {
    grid-column: 5 / 6; }
  #spielfeld .group-441 {
    grid-column: 6 / 7; }
  #spielfeld .group-442 {
    grid-column: 7 / 8; }
  #spielfeld .group-443 {
    grid-column: 8 / 9; }
  #spielfeld .group-444 {
    grid-column: 9 / 10; }
  #spielfeld .group-445 {
    grid-column: 10 / 11; }
  #spielfeld .group-446 {
    grid-column: 11 / 12; }
  #spielfeld .group-447 {
    grid-column: 12 / 13; }
  #spielfeld .group-448 {
    grid-column: 13 / 14; }
  #spielfeld .group-449 {
    grid-column: 14 / 15; }
  #spielfeld .group-450 {
    grid-column: 15 / 16; }
  #spielfeld .group-451 {
    grid-column: 16 / 17; }
  #spielfeld .group-452 {
    grid-column: 17 / 18; }
  #spielfeld .group-453 {
    grid-column: 18 / 19; }
  #spielfeld .group-454 {
    grid-column: 19 / 20; }
  #spielfeld .group-455 {
    grid-column: 20 / 21; }
  #spielfeld .group-456 {
    grid-column: 2 / 3; }
  #spielfeld .group-457 {
    grid-column: 3 / 4; }
  #spielfeld .group-458 {
    grid-column: 4 / 5; }
  #spielfeld .group-459 {
    grid-column: 5 / 6; }
  #spielfeld .group-460 {
    grid-column: 6 / 7; }
  #spielfeld .group-461 {
    grid-column: 7 / 8; }
  #spielfeld .group-462 {
    grid-column: 8 / 9; }
  #spielfeld .group-463 {
    grid-column: 9 / 10; }
  #spielfeld .group-464 {
    grid-column: 10 / 11; }
  #spielfeld .group-465 {
    grid-column: 11 / 12; }
  #spielfeld .group-466 {
    grid-column: 12 / 13; }
  #spielfeld .group-467 {
    grid-column: 13 / 14; }
  #spielfeld .group-468 {
    grid-column: 14 / 15; }
  #spielfeld .group-469 {
    grid-column: 15 / 16; }
  #spielfeld .group-470 {
    grid-column: 16 / 17; }
  #spielfeld .group-471 {
    grid-column: 17 / 18; }
  #spielfeld .group-472 {
    grid-column: 18 / 19; }
  #spielfeld .group-473 {
    grid-column: 19 / 20; }
  #spielfeld .group-474 {
    grid-column: 20 / 21; }
  #spielfeld .group-475 {
    grid-column: 2 / 3; }
  #spielfeld .group-476 {
    grid-column: 3 / 4; }
  #spielfeld .group-477 {
    grid-column: 4 / 5; }
  #spielfeld .group-478 {
    grid-column: 5 / 6; }
  #spielfeld .group-479 {
    grid-column: 6 / 7; }
  #spielfeld .group-480 {
    grid-column: 7 / 8; }
  #spielfeld .group-481 {
    grid-column: 8 / 9; }
  #spielfeld .group-482 {
    grid-column: 9 / 10; }
  #spielfeld .group-483 {
    grid-column: 10 / 11; }
  #spielfeld .group-484 {
    grid-column: 11 / 12; }
  #spielfeld .group-485 {
    grid-column: 12 / 13; }
  #spielfeld .group-486 {
    grid-column: 13 / 14; }
  #spielfeld .group-487 {
    grid-column: 14 / 15; }
  #spielfeld .group-488 {
    grid-column: 15 / 16; }
  #spielfeld .group-489 {
    grid-column: 16 / 17; }
  #spielfeld .group-490 {
    grid-column: 17 / 18; }
  #spielfeld .group-491 {
    grid-column: 18 / 19; }
  #spielfeld .group-492 {
    grid-column: 19 / 20; }
  #spielfeld .group-493 {
    grid-column: 20 / 21; }
  #spielfeld .group-494 {
    grid-column: 2 / 3; }
  #spielfeld .group-495 {
    grid-column: 3 / 4; }
  #spielfeld .group-496 {
    grid-column: 4 / 5; }
  #spielfeld .group-497 {
    grid-column: 5 / 6; }
  #spielfeld .group-498 {
    grid-column: 6 / 7; }
  #spielfeld .group-499 {
    grid-column: 7 / 8; }
  #spielfeld .group-500 {
    grid-column: 8 / 9; }
  #spielfeld .group-501 {
    grid-column: 9 / 10; }
  #spielfeld .group-502 {
    grid-column: 10 / 11; }
  #spielfeld .group-503 {
    grid-column: 11 / 12; }
  #spielfeld .group-504 {
    grid-column: 12 / 13; }
  #spielfeld .group-505 {
    grid-column: 13 / 14; }
  #spielfeld .group-506 {
    grid-column: 14 / 15; }
  #spielfeld .group-507 {
    grid-column: 15 / 16; }
  #spielfeld .group-508 {
    grid-column: 16 / 17; }
  #spielfeld .group-509 {
    grid-column: 17 / 18; }
  #spielfeld .group-510 {
    grid-column: 18 / 19; }
  #spielfeld .group-511 {
    grid-column: 19 / 20; }
  #spielfeld .group-512 {
    grid-column: 20 / 21; }
  #spielfeld .group-513 {
    grid-column: 2 / 3; }
  #spielfeld .group-514 {
    grid-column: 3 / 4; }
  #spielfeld .group-515 {
    grid-column: 4 / 5; }
  #spielfeld .group-516 {
    grid-column: 5 / 6; }
  #spielfeld .group-517 {
    grid-column: 6 / 7; }
  #spielfeld .group-518 {
    grid-column: 7 / 8; }
  #spielfeld .group-519 {
    grid-column: 8 / 9; }
  #spielfeld .group-520 {
    grid-column: 9 / 10; }
  #spielfeld .group-521 {
    grid-column: 10 / 11; }
  #spielfeld .group-522 {
    grid-column: 11 / 12; }
  #spielfeld .group-523 {
    grid-column: 12 / 13; }
  #spielfeld .group-524 {
    grid-column: 13 / 14; }
  #spielfeld .group-525 {
    grid-column: 14 / 15; }
  #spielfeld .group-526 {
    grid-column: 15 / 16; }
  #spielfeld .group-527 {
    grid-column: 16 / 17; }
  #spielfeld .group-528 {
    grid-column: 17 / 18; }
  #spielfeld .group-529 {
    grid-column: 18 / 19; }
  #spielfeld .group-530 {
    grid-column: 19 / 20; }
  #spielfeld .group-531 {
    grid-column: 20 / 21; }
  #spielfeld .group-532 {
    grid-column: 2 / 3; }
  #spielfeld .group-533 {
    grid-column: 3 / 4; }
  #spielfeld .group-534 {
    grid-column: 4 / 5; }
  #spielfeld .group-535 {
    grid-column: 5 / 6; }
  #spielfeld .group-536 {
    grid-column: 6 / 7; }
  #spielfeld .group-537 {
    grid-column: 7 / 8; }
  #spielfeld .group-538 {
    grid-column: 8 / 9; }
  #spielfeld .group-539 {
    grid-column: 9 / 10; }
  #spielfeld .group-540 {
    grid-column: 10 / 11; }
  #spielfeld .group-541 {
    grid-column: 11 / 12; }
  #spielfeld .group-542 {
    grid-column: 12 / 13; }
  #spielfeld .group-543 {
    grid-column: 13 / 14; }
  #spielfeld .group-544 {
    grid-column: 14 / 15; }
  #spielfeld .group-545 {
    grid-column: 15 / 16; }
  #spielfeld .group-546 {
    grid-column: 16 / 17; }
  #spielfeld .group-547 {
    grid-column: 17 / 18; }
  #spielfeld .group-548 {
    grid-column: 18 / 19; }
  #spielfeld .group-549 {
    grid-column: 19 / 20; }
  #spielfeld .group-550 {
    grid-column: 20 / 21; }
  #spielfeld .group-551 {
    grid-column: 2 / 3; }
  #spielfeld .group-552 {
    grid-column: 3 / 4; }
  #spielfeld .group-553 {
    grid-column: 4 / 5; }
  #spielfeld .group-554 {
    grid-column: 5 / 6; }
  #spielfeld .group-555 {
    grid-column: 6 / 7; }
  #spielfeld .group-556 {
    grid-column: 7 / 8; }
  #spielfeld .group-557 {
    grid-column: 8 / 9; }
  #spielfeld .group-558 {
    grid-column: 9 / 10; }
  #spielfeld .group-559 {
    grid-column: 10 / 11; }
  #spielfeld .group-560 {
    grid-column: 11 / 12; }
  #spielfeld .group-561 {
    grid-column: 12 / 13; }
  #spielfeld .group-562 {
    grid-column: 13 / 14; }
  #spielfeld .group-563 {
    grid-column: 14 / 15; }
  #spielfeld .group-564 {
    grid-column: 15 / 16; }
  #spielfeld .group-565 {
    grid-column: 16 / 17; }
  #spielfeld .group-566 {
    grid-column: 17 / 18; }
  #spielfeld .group-567 {
    grid-column: 18 / 19; }
  #spielfeld .group-568 {
    grid-column: 19 / 20; }
  #spielfeld .group-569 {
    grid-column: 20 / 21; }
  #spielfeld .group-570 {
    grid-column: 2 / 3; }
  #spielfeld .group-571 {
    grid-column: 3 / 4; }
  #spielfeld .group-572 {
    grid-column: 4 / 5; }
  #spielfeld .group-573 {
    grid-column: 5 / 6; }
  #spielfeld .group-574 {
    grid-column: 6 / 7; }
  #spielfeld .group-575 {
    grid-column: 7 / 8; }
  #spielfeld .group-576 {
    grid-column: 8 / 9; }
  #spielfeld .group-577 {
    grid-column: 9 / 10; }
  #spielfeld .group-578 {
    grid-column: 10 / 11; }
  #spielfeld .group-579 {
    grid-column: 11 / 12; }
  #spielfeld .group-580 {
    grid-column: 12 / 13; }
  #spielfeld .group-581 {
    grid-column: 13 / 14; }
  #spielfeld .group-582 {
    grid-column: 14 / 15; }
  #spielfeld .group-583 {
    grid-column: 15 / 16; }
  #spielfeld .group-584 {
    grid-column: 16 / 17; }
  #spielfeld .group-585 {
    grid-column: 17 / 18; }
  #spielfeld .group-586 {
    grid-column: 18 / 19; }
  #spielfeld .group-587 {
    grid-column: 19 / 20; }
  #spielfeld .group-588 {
    grid-column: 20 / 21; }
  #spielfeld .group-589 {
    grid-column: 2 / 3; }
  #spielfeld .group-590 {
    grid-column: 3 / 4; }
  #spielfeld .group-591 {
    grid-column: 4 / 5; }
  #spielfeld .group-592 {
    grid-column: 5 / 6; }
  #spielfeld .group-593 {
    grid-column: 6 / 7; }
  #spielfeld .group-594 {
    grid-column: 7 / 8; }
  #spielfeld .group-595 {
    grid-column: 8 / 9; }
  #spielfeld .group-596 {
    grid-column: 9 / 10; }
  #spielfeld .group-597 {
    grid-column: 10 / 11; }
  #spielfeld .group-598 {
    grid-column: 11 / 12; }
  #spielfeld .group-599 {
    grid-column: 12 / 13; }
  #spielfeld .group-600 {
    grid-column: 13 / 14; }
  #spielfeld .group-601 {
    grid-column: 14 / 15; }
  #spielfeld .group-602 {
    grid-column: 15 / 16; }
  #spielfeld .group-603 {
    grid-column: 16 / 17; }
  #spielfeld .group-604 {
    grid-column: 17 / 18; }
  #spielfeld .group-605 {
    grid-column: 18 / 19; }
  #spielfeld .group-606 {
    grid-column: 19 / 20; }
  #spielfeld .group-607 {
    grid-column: 20 / 21; }
  #spielfeld .group-608 {
    grid-column: 2 / 3; }
  #spielfeld .group-609 {
    grid-column: 3 / 4; }
  #spielfeld .group-610 {
    grid-column: 4 / 5; }
  #spielfeld .group-611 {
    grid-column: 5 / 6; }
  #spielfeld .group-612 {
    grid-column: 6 / 7; }
  #spielfeld .group-613 {
    grid-column: 7 / 8; }
  #spielfeld .group-614 {
    grid-column: 8 / 9; }
  #spielfeld .group-615 {
    grid-column: 9 / 10; }
  #spielfeld .group-616 {
    grid-column: 10 / 11; }
  #spielfeld .group-617 {
    grid-column: 11 / 12; }
  #spielfeld .group-618 {
    grid-column: 12 / 13; }
  #spielfeld .group-619 {
    grid-column: 13 / 14; }
  #spielfeld .group-620 {
    grid-column: 14 / 15; }
  #spielfeld .group-621 {
    grid-column: 15 / 16; }
  #spielfeld .group-622 {
    grid-column: 16 / 17; }
  #spielfeld .group-623 {
    grid-column: 17 / 18; }
  #spielfeld .group-624 {
    grid-column: 18 / 19; }
  #spielfeld .group-625 {
    grid-column: 19 / 20; }
  #spielfeld .group-626 {
    grid-column: 20 / 21; }
  #spielfeld .group-627 {
    grid-column: 2 / 3; }
  #spielfeld .group-628 {
    grid-column: 3 / 4; }
  #spielfeld .group-629 {
    grid-column: 4 / 5; }
  #spielfeld .group-630 {
    grid-column: 5 / 6; }
  #spielfeld .group-631 {
    grid-column: 6 / 7; }
  #spielfeld .group-632 {
    grid-column: 7 / 8; }
  #spielfeld .group-633 {
    grid-column: 8 / 9; }
  #spielfeld .group-634 {
    grid-column: 9 / 10; }
  #spielfeld .group-635 {
    grid-column: 10 / 11; }
  #spielfeld .group-636 {
    grid-column: 11 / 12; }
  #spielfeld .group-637 {
    grid-column: 12 / 13; }
  #spielfeld .group-638 {
    grid-column: 13 / 14; }
  #spielfeld .group-639 {
    grid-column: 14 / 15; }
  #spielfeld .group-640 {
    grid-column: 15 / 16; }
  #spielfeld .group-641 {
    grid-column: 16 / 17; }
  #spielfeld .group-642 {
    grid-column: 17 / 18; }
  #spielfeld .group-643 {
    grid-column: 18 / 19; }
  #spielfeld .group-644 {
    grid-column: 19 / 20; }
  #spielfeld .group-645 {
    grid-column: 20 / 21; }
  #spielfeld .group-646 {
    grid-column: 2 / 3; }
  #spielfeld .group-647 {
    grid-column: 3 / 4; }
  #spielfeld .group-648 {
    grid-column: 4 / 5; }
  #spielfeld .group-649 {
    grid-column: 5 / 6; }
  #spielfeld .group-650 {
    grid-column: 6 / 7; }
  #spielfeld .group-651 {
    grid-column: 7 / 8; }
  #spielfeld .group-652 {
    grid-column: 8 / 9; }
  #spielfeld .group-653 {
    grid-column: 9 / 10; }
  #spielfeld .group-654 {
    grid-column: 10 / 11; }
  #spielfeld .group-655 {
    grid-column: 11 / 12; }
  #spielfeld .group-656 {
    grid-column: 12 / 13; }
  #spielfeld .group-657 {
    grid-column: 13 / 14; }
  #spielfeld .group-658 {
    grid-column: 14 / 15; }
  #spielfeld .group-659 {
    grid-column: 15 / 16; }
  #spielfeld .group-660 {
    grid-column: 16 / 17; }
  #spielfeld .group-661 {
    grid-column: 17 / 18; }
  #spielfeld .group-662 {
    grid-column: 18 / 19; }
  #spielfeld .group-663 {
    grid-column: 19 / 20; }
  #spielfeld .group-664 {
    grid-column: 20 / 21; }
  #spielfeld .group-665 {
    grid-column: 2 / 3; }
  #spielfeld .group-666 {
    grid-column: 3 / 4; }
  #spielfeld .group-667 {
    grid-column: 4 / 5; }
  #spielfeld .group-668 {
    grid-column: 5 / 6; }
  #spielfeld .group-669 {
    grid-column: 6 / 7; }
  #spielfeld .group-670 {
    grid-column: 7 / 8; }
  #spielfeld .group-671 {
    grid-column: 8 / 9; }
  #spielfeld .group-672 {
    grid-column: 9 / 10; }
  #spielfeld .group-673 {
    grid-column: 10 / 11; }
  #spielfeld .group-674 {
    grid-column: 11 / 12; }
  #spielfeld .group-675 {
    grid-column: 12 / 13; }
  #spielfeld .group-676 {
    grid-column: 13 / 14; }
  #spielfeld .group-677 {
    grid-column: 14 / 15; }
  #spielfeld .group-678 {
    grid-column: 15 / 16; }
  #spielfeld .group-679 {
    grid-column: 16 / 17; }
  #spielfeld .group-680 {
    grid-column: 17 / 18; }
  #spielfeld .group-681 {
    grid-column: 18 / 19; }
  #spielfeld .group-682 {
    grid-column: 19 / 20; }
  #spielfeld .group-683 {
    grid-column: 20 / 21; }
  #spielfeld .group-684 {
    grid-column: 2 / 3; }
  #spielfeld .group-685 {
    grid-column: 3 / 4; }
  #spielfeld .group-686 {
    grid-column: 4 / 5; }
  #spielfeld .group-687 {
    grid-column: 5 / 6; }
  #spielfeld .group-688 {
    grid-column: 6 / 7; }
  #spielfeld .group-689 {
    grid-column: 7 / 8; }
  #spielfeld .group-690 {
    grid-column: 8 / 9; }
  #spielfeld .group-691 {
    grid-column: 9 / 10; }
  #spielfeld .group-692 {
    grid-column: 10 / 11; }
  #spielfeld .group-693 {
    grid-column: 11 / 12; }
  #spielfeld .group-694 {
    grid-column: 12 / 13; }
  #spielfeld .group-695 {
    grid-column: 13 / 14; }
  #spielfeld .group-696 {
    grid-column: 14 / 15; }
  #spielfeld .group-697 {
    grid-column: 15 / 16; }
  #spielfeld .group-698 {
    grid-column: 16 / 17; }
  #spielfeld .group-699 {
    grid-column: 17 / 18; }
  #spielfeld .group-700 {
    grid-column: 18 / 19; }
  #spielfeld .group-701 {
    grid-column: 19 / 20; }
  #spielfeld .group-702 {
    grid-column: 20 / 21; }
  #spielfeld .group-703 {
    grid-column: 2 / 3; }
  #spielfeld .group-704 {
    grid-column: 3 / 4; }
  #spielfeld .group-705 {
    grid-column: 4 / 5; }
  #spielfeld .group-706 {
    grid-column: 5 / 6; }
  #spielfeld .group-707 {
    grid-column: 6 / 7; }
  #spielfeld .group-708 {
    grid-column: 7 / 8; }
  #spielfeld .group-709 {
    grid-column: 8 / 9; }
  #spielfeld .group-710 {
    grid-column: 9 / 10; }
  #spielfeld .group-711 {
    grid-column: 10 / 11; }
  #spielfeld .group-712 {
    grid-column: 11 / 12; }
  #spielfeld .group-713 {
    grid-column: 12 / 13; }
  #spielfeld .group-714 {
    grid-column: 13 / 14; }
  #spielfeld .group-715 {
    grid-column: 14 / 15; }
  #spielfeld .group-716 {
    grid-column: 15 / 16; }
  #spielfeld .group-717 {
    grid-column: 16 / 17; }
  #spielfeld .group-718 {
    grid-column: 17 / 18; }
  #spielfeld .group-719 {
    grid-column: 18 / 19; }
  #spielfeld .group-720 {
    grid-column: 19 / 20; }
  #spielfeld .group-721 {
    grid-column: 20 / 21; }
  #spielfeld .group-722 {
    grid-column: 2 / 3; }
  #spielfeld .group-723 {
    grid-column: 3 / 4; }
  #spielfeld .group-724 {
    grid-column: 4 / 5; }
  #spielfeld .group-725 {
    grid-column: 5 / 6; }
  #spielfeld .group-726 {
    grid-column: 6 / 7; }
  #spielfeld .group-727 {
    grid-column: 7 / 8; }
  #spielfeld .group-728 {
    grid-column: 8 / 9; }
  #spielfeld .group-729 {
    grid-column: 9 / 10; }
  #spielfeld .group-730 {
    grid-column: 10 / 11; }
  #spielfeld .group-731 {
    grid-column: 11 / 12; }
  #spielfeld .group-732 {
    grid-column: 12 / 13; }
  #spielfeld .group-733 {
    grid-column: 13 / 14; }
  #spielfeld .group-734 {
    grid-column: 14 / 15; }
  #spielfeld .group-735 {
    grid-column: 15 / 16; }
  #spielfeld .group-736 {
    grid-column: 16 / 17; }
  #spielfeld .group-737 {
    grid-column: 17 / 18; }
  #spielfeld .group-738 {
    grid-column: 18 / 19; }
  #spielfeld .group-739 {
    grid-column: 19 / 20; }
  #spielfeld .group-740 {
    grid-column: 20 / 21; }
  #spielfeld .group-741 {
    grid-column: 2 / 3; }
  #spielfeld .group-742 {
    grid-column: 3 / 4; }
  #spielfeld .group-743 {
    grid-column: 4 / 5; }
  #spielfeld .group-744 {
    grid-column: 5 / 6; }
  #spielfeld .group-745 {
    grid-column: 6 / 7; }
  #spielfeld .group-746 {
    grid-column: 7 / 8; }
  #spielfeld .group-747 {
    grid-column: 8 / 9; }
  #spielfeld .group-748 {
    grid-column: 9 / 10; }
  #spielfeld .group-749 {
    grid-column: 10 / 11; }
  #spielfeld .group-750 {
    grid-column: 11 / 12; }
  #spielfeld .group-751 {
    grid-column: 12 / 13; }
  #spielfeld .group-752 {
    grid-column: 13 / 14; }
  #spielfeld .group-753 {
    grid-column: 14 / 15; }
  #spielfeld .group-754 {
    grid-column: 15 / 16; }
  #spielfeld .group-755 {
    grid-column: 16 / 17; }
  #spielfeld .group-756 {
    grid-column: 17 / 18; }
  #spielfeld .group-757 {
    grid-column: 18 / 19; }
  #spielfeld .group-758 {
    grid-column: 19 / 20; }
  #spielfeld .group-759 {
    grid-column: 20 / 21; }
  #spielfeld .group-760 {
    grid-column: 2 / 3; }
  #spielfeld .group-761 {
    grid-column: 3 / 4; }
  #spielfeld .group-762 {
    grid-column: 4 / 5; }
  #spielfeld .group-763 {
    grid-column: 5 / 6; }
  #spielfeld .group-764 {
    grid-column: 6 / 7; }
  #spielfeld .group-765 {
    grid-column: 7 / 8; }
  #spielfeld .group-766 {
    grid-column: 8 / 9; }
  #spielfeld .group-767 {
    grid-column: 9 / 10; }
  #spielfeld .group-768 {
    grid-column: 10 / 11; }
  #spielfeld .group-769 {
    grid-column: 11 / 12; }
  #spielfeld .group-770 {
    grid-column: 12 / 13; }
  #spielfeld .group-771 {
    grid-column: 13 / 14; }
  #spielfeld .group-772 {
    grid-column: 14 / 15; }
  #spielfeld .group-773 {
    grid-column: 15 / 16; }
  #spielfeld .group-774 {
    grid-column: 16 / 17; }
  #spielfeld .group-775 {
    grid-column: 17 / 18; }
  #spielfeld .group-776 {
    grid-column: 18 / 19; }
  #spielfeld .group-777 {
    grid-column: 19 / 20; }
  #spielfeld .group-778 {
    grid-column: 20 / 21; }
  #spielfeld .group-779 {
    grid-column: 2 / 3; }
  #spielfeld .group-780 {
    grid-column: 3 / 4; }
  #spielfeld .group-781 {
    grid-column: 4 / 5; }
  #spielfeld .group-782 {
    grid-column: 5 / 6; }
  #spielfeld .group-783 {
    grid-column: 6 / 7; }
  #spielfeld .group-784 {
    grid-column: 7 / 8; }
  #spielfeld .group-785 {
    grid-column: 8 / 9; }
  #spielfeld .group-786 {
    grid-column: 9 / 10; }
  #spielfeld .group-787 {
    grid-column: 10 / 11; }
  #spielfeld .group-788 {
    grid-column: 11 / 12; }
  #spielfeld .group-789 {
    grid-column: 12 / 13; }
  #spielfeld .group-790 {
    grid-column: 13 / 14; }
  #spielfeld .group-791 {
    grid-column: 14 / 15; }
  #spielfeld .group-792 {
    grid-column: 15 / 16; }
  #spielfeld .group-793 {
    grid-column: 16 / 17; }
  #spielfeld .group-794 {
    grid-column: 17 / 18; }
  #spielfeld .group-795 {
    grid-column: 18 / 19; }
  #spielfeld .group-796 {
    grid-column: 19 / 20; }
  #spielfeld .group-797 {
    grid-column: 20 / 21; }
  #spielfeld .group-798 {
    grid-column: 2 / 3; }
  #spielfeld .group-799 {
    grid-column: 3 / 4; }
  #spielfeld .group-800 {
    grid-column: 4 / 5; }
  #spielfeld .group-801 {
    grid-column: 5 / 6; }
  #spielfeld .group-802 {
    grid-column: 6 / 7; }
  #spielfeld .group-803 {
    grid-column: 7 / 8; }
  #spielfeld .group-804 {
    grid-column: 8 / 9; }
  #spielfeld .group-805 {
    grid-column: 9 / 10; }
  #spielfeld .group-806 {
    grid-column: 10 / 11; }
  #spielfeld .group-807 {
    grid-column: 11 / 12; }
  #spielfeld .group-808 {
    grid-column: 12 / 13; }
  #spielfeld .group-809 {
    grid-column: 13 / 14; }
  #spielfeld .group-810 {
    grid-column: 14 / 15; }
  #spielfeld .group-811 {
    grid-column: 15 / 16; }
  #spielfeld .group-812 {
    grid-column: 16 / 17; }
  #spielfeld .group-813 {
    grid-column: 17 / 18; }
  #spielfeld .group-814 {
    grid-column: 18 / 19; }
  #spielfeld .group-815 {
    grid-column: 19 / 20; }
  #spielfeld .group-816 {
    grid-column: 20 / 21; }
  #spielfeld .group-817 {
    grid-column: 2 / 3; }
  #spielfeld .group-818 {
    grid-column: 3 / 4; }
  #spielfeld .group-819 {
    grid-column: 4 / 5; }
  #spielfeld .group-820 {
    grid-column: 5 / 6; }
  #spielfeld .group-821 {
    grid-column: 6 / 7; }
  #spielfeld .group-822 {
    grid-column: 7 / 8; }
  #spielfeld .group-823 {
    grid-column: 8 / 9; }
  #spielfeld .group-824 {
    grid-column: 9 / 10; }
  #spielfeld .group-825 {
    grid-column: 10 / 11; }
  #spielfeld .group-826 {
    grid-column: 11 / 12; }
  #spielfeld .group-827 {
    grid-column: 12 / 13; }
  #spielfeld .group-828 {
    grid-column: 13 / 14; }
  #spielfeld .group-829 {
    grid-column: 14 / 15; }
  #spielfeld .group-830 {
    grid-column: 15 / 16; }
  #spielfeld .group-831 {
    grid-column: 16 / 17; }
  #spielfeld .group-832 {
    grid-column: 17 / 18; }
  #spielfeld .group-833 {
    grid-column: 18 / 19; }
  #spielfeld .group-834 {
    grid-column: 19 / 20; }
  #spielfeld .group-835 {
    grid-column: 20 / 21; }
  #spielfeld .group-836 {
    grid-column: 2 / 3; }
  #spielfeld .group-837 {
    grid-column: 3 / 4; }
  #spielfeld .group-838 {
    grid-column: 4 / 5; }
  #spielfeld .group-839 {
    grid-column: 5 / 6; }
  #spielfeld .group-840 {
    grid-column: 6 / 7; }
  #spielfeld .group-841 {
    grid-column: 7 / 8; }
  #spielfeld .group-842 {
    grid-column: 8 / 9; }
  #spielfeld .group-843 {
    grid-column: 9 / 10; }
  #spielfeld .group-844 {
    grid-column: 10 / 11; }
  #spielfeld .group-845 {
    grid-column: 11 / 12; }
  #spielfeld .group-846 {
    grid-column: 12 / 13; }
  #spielfeld .group-847 {
    grid-column: 13 / 14; }
  #spielfeld .group-848 {
    grid-column: 14 / 15; }
  #spielfeld .group-849 {
    grid-column: 15 / 16; }
  #spielfeld .group-850 {
    grid-column: 16 / 17; }
  #spielfeld .group-851 {
    grid-column: 17 / 18; }
  #spielfeld .group-852 {
    grid-column: 18 / 19; }
  #spielfeld .group-853 {
    grid-column: 19 / 20; }
  #spielfeld .group-854 {
    grid-column: 20 / 21; }
  #spielfeld .group-855 {
    grid-column: 2 / 3; }
  #spielfeld .group-856 {
    grid-column: 3 / 4; }
  #spielfeld .group-857 {
    grid-column: 4 / 5; }
  #spielfeld .group-858 {
    grid-column: 5 / 6; }
  #spielfeld .group-859 {
    grid-column: 6 / 7; }
  #spielfeld .group-860 {
    grid-column: 7 / 8; }
  #spielfeld .group-861 {
    grid-column: 8 / 9; }
  #spielfeld .group-862 {
    grid-column: 9 / 10; }
  #spielfeld .group-863 {
    grid-column: 10 / 11; }
  #spielfeld .group-864 {
    grid-column: 11 / 12; }
  #spielfeld .group-865 {
    grid-column: 12 / 13; }
  #spielfeld .group-866 {
    grid-column: 13 / 14; }
  #spielfeld .group-867 {
    grid-column: 14 / 15; }
  #spielfeld .group-868 {
    grid-column: 15 / 16; }
  #spielfeld .group-869 {
    grid-column: 16 / 17; }
  #spielfeld .group-870 {
    grid-column: 17 / 18; }
  #spielfeld .group-871 {
    grid-column: 18 / 19; }
  #spielfeld .group-872 {
    grid-column: 19 / 20; }
  #spielfeld .group-873 {
    grid-column: 20 / 21; }
  #spielfeld .group-874 {
    grid-column: 2 / 3; }
  #spielfeld .group-875 {
    grid-column: 3 / 4; }
  #spielfeld .group-876 {
    grid-column: 4 / 5; }
  #spielfeld .group-877 {
    grid-column: 5 / 6; }
  #spielfeld .group-878 {
    grid-column: 6 / 7; }
  #spielfeld .group-879 {
    grid-column: 7 / 8; }
  #spielfeld .group-880 {
    grid-column: 8 / 9; }
  #spielfeld .group-881 {
    grid-column: 9 / 10; }
  #spielfeld .group-882 {
    grid-column: 10 / 11; }
  #spielfeld .group-883 {
    grid-column: 11 / 12; }
  #spielfeld .group-884 {
    grid-column: 12 / 13; }
  #spielfeld .group-885 {
    grid-column: 13 / 14; }
  #spielfeld .group-886 {
    grid-column: 14 / 15; }
  #spielfeld .group-887 {
    grid-column: 15 / 16; }
  #spielfeld .group-888 {
    grid-column: 16 / 17; }
  #spielfeld .group-889 {
    grid-column: 17 / 18; }
  #spielfeld .group-890 {
    grid-column: 18 / 19; }
  #spielfeld .group-891 {
    grid-column: 19 / 20; }
  #spielfeld .group-892 {
    grid-column: 20 / 21; }
  #spielfeld .group-893 {
    grid-column: 2 / 3; }
  #spielfeld .group-894 {
    grid-column: 3 / 4; }
  #spielfeld .group-895 {
    grid-column: 4 / 5; }
  #spielfeld .group-896 {
    grid-column: 5 / 6; }
  #spielfeld .group-897 {
    grid-column: 6 / 7; }
  #spielfeld .group-898 {
    grid-column: 7 / 8; }
  #spielfeld .group-899 {
    grid-column: 8 / 9; }
  #spielfeld .group-900 {
    grid-column: 9 / 10; }
  #spielfeld .group-901 {
    grid-column: 10 / 11; }
  #spielfeld .group-902 {
    grid-column: 11 / 12; }
  #spielfeld .group-903 {
    grid-column: 12 / 13; }
  #spielfeld .group-904 {
    grid-column: 13 / 14; }
  #spielfeld .group-905 {
    grid-column: 14 / 15; }
  #spielfeld .group-906 {
    grid-column: 15 / 16; }
  #spielfeld .group-907 {
    grid-column: 16 / 17; }
  #spielfeld .group-908 {
    grid-column: 17 / 18; }
  #spielfeld .group-909 {
    grid-column: 18 / 19; }
  #spielfeld .group-910 {
    grid-column: 19 / 20; }
  #spielfeld .group-911 {
    grid-column: 20 / 21; }
  #spielfeld .group-912 {
    grid-column: 2 / 3; }
  #spielfeld .group-913 {
    grid-column: 3 / 4; }
  #spielfeld .group-914 {
    grid-column: 4 / 5; }
  #spielfeld .group-915 {
    grid-column: 5 / 6; }
  #spielfeld .group-916 {
    grid-column: 6 / 7; }
  #spielfeld .group-917 {
    grid-column: 7 / 8; }
  #spielfeld .group-918 {
    grid-column: 8 / 9; }
  #spielfeld .group-919 {
    grid-column: 9 / 10; }
  #spielfeld .group-920 {
    grid-column: 10 / 11; }
  #spielfeld .group-921 {
    grid-column: 11 / 12; }
  #spielfeld .group-922 {
    grid-column: 12 / 13; }
  #spielfeld .group-923 {
    grid-column: 13 / 14; }
  #spielfeld .group-924 {
    grid-column: 14 / 15; }
  #spielfeld .group-925 {
    grid-column: 15 / 16; }
  #spielfeld .group-926 {
    grid-column: 16 / 17; }
  #spielfeld .group-927 {
    grid-column: 17 / 18; }
  #spielfeld .group-928 {
    grid-column: 18 / 19; }
  #spielfeld .group-929 {
    grid-column: 19 / 20; }
  #spielfeld .group-930 {
    grid-column: 20 / 21; }
  #spielfeld .group-931 {
    grid-column: 2 / 3; }
  #spielfeld .group-932 {
    grid-column: 3 / 4; }
  #spielfeld .group-933 {
    grid-column: 4 / 5; }
  #spielfeld .group-934 {
    grid-column: 5 / 6; }
  #spielfeld .group-935 {
    grid-column: 6 / 7; }
  #spielfeld .group-936 {
    grid-column: 7 / 8; }
  #spielfeld .group-937 {
    grid-column: 8 / 9; }
  #spielfeld .group-938 {
    grid-column: 9 / 10; }
  #spielfeld .group-939 {
    grid-column: 10 / 11; }
  #spielfeld .group-940 {
    grid-column: 11 / 12; }
  #spielfeld .group-941 {
    grid-column: 12 / 13; }
  #spielfeld .group-942 {
    grid-column: 13 / 14; }
  #spielfeld .group-943 {
    grid-column: 14 / 15; }
  #spielfeld .group-944 {
    grid-column: 15 / 16; }
  #spielfeld .group-945 {
    grid-column: 16 / 17; }
  #spielfeld .group-946 {
    grid-column: 17 / 18; }
  #spielfeld .group-947 {
    grid-column: 18 / 19; }
  #spielfeld .group-948 {
    grid-column: 19 / 20; }
  #spielfeld .group-949 {
    grid-column: 20 / 21; }
  #spielfeld .group-950 {
    grid-column: 2 / 3; }
  #spielfeld .group-951 {
    grid-column: 3 / 4; }
  #spielfeld .group-952 {
    grid-column: 4 / 5; }
  #spielfeld .group-953 {
    grid-column: 5 / 6; }
  #spielfeld .group-954 {
    grid-column: 6 / 7; }
  #spielfeld .group-955 {
    grid-column: 7 / 8; }
  #spielfeld .group-956 {
    grid-column: 8 / 9; }
  #spielfeld .group-957 {
    grid-column: 9 / 10; }
  #spielfeld .group-958 {
    grid-column: 10 / 11; }
  #spielfeld .group-959 {
    grid-column: 11 / 12; }
  #spielfeld .group-960 {
    grid-column: 12 / 13; }
  #spielfeld .group-961 {
    grid-column: 13 / 14; }
  #spielfeld .group-962 {
    grid-column: 14 / 15; }
  #spielfeld .group-963 {
    grid-column: 15 / 16; }
  #spielfeld .group-964 {
    grid-column: 16 / 17; }
  #spielfeld .group-965 {
    grid-column: 17 / 18; }
  #spielfeld .group-966 {
    grid-column: 18 / 19; }
  #spielfeld .group-967 {
    grid-column: 19 / 20; }
  #spielfeld .group-968 {
    grid-column: 20 / 21; }
  #spielfeld .group-969 {
    grid-column: 2 / 3; }
  #spielfeld .group-970 {
    grid-column: 3 / 4; }
  #spielfeld .group-971 {
    grid-column: 4 / 5; }
  #spielfeld .group-972 {
    grid-column: 5 / 6; }
  #spielfeld .group-973 {
    grid-column: 6 / 7; }
  #spielfeld .group-974 {
    grid-column: 7 / 8; }
  #spielfeld .group-975 {
    grid-column: 8 / 9; }
  #spielfeld .group-976 {
    grid-column: 9 / 10; }
  #spielfeld .group-977 {
    grid-column: 10 / 11; }
  #spielfeld .group-978 {
    grid-column: 11 / 12; }
  #spielfeld .group-979 {
    grid-column: 12 / 13; }
  #spielfeld .group-980 {
    grid-column: 13 / 14; }
  #spielfeld .group-981 {
    grid-column: 14 / 15; }
  #spielfeld .group-982 {
    grid-column: 15 / 16; }
  #spielfeld .group-983 {
    grid-column: 16 / 17; }
  #spielfeld .group-984 {
    grid-column: 17 / 18; }
  #spielfeld .group-985 {
    grid-column: 18 / 19; }
  #spielfeld .group-986 {
    grid-column: 19 / 20; }
  #spielfeld .group-987 {
    grid-column: 20 / 21; }
  #spielfeld .group-988 {
    grid-column: 2 / 3; }
  #spielfeld .group-989 {
    grid-column: 3 / 4; }
  #spielfeld .group-990 {
    grid-column: 4 / 5; }
  #spielfeld .group-991 {
    grid-column: 5 / 6; }
  #spielfeld .group-992 {
    grid-column: 6 / 7; }
  #spielfeld .group-993 {
    grid-column: 7 / 8; }
  #spielfeld .group-994 {
    grid-column: 8 / 9; }
  #spielfeld .group-995 {
    grid-column: 9 / 10; }
  #spielfeld .group-996 {
    grid-column: 10 / 11; }
  #spielfeld .group-997 {
    grid-column: 11 / 12; }
  #spielfeld .group-998 {
    grid-column: 12 / 13; }
  #spielfeld .group-999 {
    grid-column: 13 / 14; }
  #spielfeld .group-1000 {
    grid-column: 14 / 15; }
  #spielfeld .group-1001 {
    grid-column: 15 / 16; }
  #spielfeld .group-1002 {
    grid-column: 16 / 17; }
  #spielfeld .group-1003 {
    grid-column: 17 / 18; }
  #spielfeld .group-1004 {
    grid-column: 18 / 19; }
  #spielfeld .group-1005 {
    grid-column: 19 / 20; }
  #spielfeld .group-1006 {
    grid-column: 20 / 21; }
  #spielfeld .group-1007 {
    grid-column: 2 / 3; }
  #spielfeld .group-1008 {
    grid-column: 3 / 4; }
  #spielfeld .group-1009 {
    grid-column: 4 / 5; }
  #spielfeld .group-1010 {
    grid-column: 5 / 6; }
  #spielfeld .group-1011 {
    grid-column: 6 / 7; }
  #spielfeld .group-1012 {
    grid-column: 7 / 8; }
  #spielfeld .group-1013 {
    grid-column: 8 / 9; }
  #spielfeld .group-1014 {
    grid-column: 9 / 10; }
  #spielfeld .group-1015 {
    grid-column: 10 / 11; }
  #spielfeld .group-1016 {
    grid-column: 11 / 12; }
  #spielfeld .group-1017 {
    grid-column: 12 / 13; }
  #spielfeld .group-1018 {
    grid-column: 13 / 14; }
  #spielfeld .group-1019 {
    grid-column: 14 / 15; }
  #spielfeld .group-1020 {
    grid-column: 15 / 16; }
  #spielfeld .group-1021 {
    grid-column: 16 / 17; }
  #spielfeld .group-1022 {
    grid-column: 17 / 18; }
  #spielfeld .group-1023 {
    grid-column: 18 / 19; }
  #spielfeld .group-1024 {
    grid-column: 19 / 20; }
  #spielfeld .group-1025 {
    grid-column: 20 / 21; }
  #spielfeld .group-1026 {
    grid-column: 2 / 3; }
  #spielfeld .group-1027 {
    grid-column: 3 / 4; }
  #spielfeld .group-1028 {
    grid-column: 4 / 5; }
  #spielfeld .group-1029 {
    grid-column: 5 / 6; }
  #spielfeld .group-1030 {
    grid-column: 6 / 7; }
  #spielfeld .group-1031 {
    grid-column: 7 / 8; }
  #spielfeld .group-1032 {
    grid-column: 8 / 9; }
  #spielfeld .group-1033 {
    grid-column: 9 / 10; }
  #spielfeld .group-1034 {
    grid-column: 10 / 11; }
  #spielfeld .group-1035 {
    grid-column: 11 / 12; }
  #spielfeld .group-1036 {
    grid-column: 12 / 13; }
  #spielfeld .group-1037 {
    grid-column: 13 / 14; }
  #spielfeld .group-1038 {
    grid-column: 14 / 15; }
  #spielfeld .group-1039 {
    grid-column: 15 / 16; }
  #spielfeld .group-1040 {
    grid-column: 16 / 17; }
  #spielfeld .group-1041 {
    grid-column: 17 / 18; }
  #spielfeld .group-1042 {
    grid-column: 18 / 19; }
  #spielfeld .group-1043 {
    grid-column: 19 / 20; }
  #spielfeld .group-1044 {
    grid-column: 20 / 21; }
  #spielfeld .group-1045 {
    grid-column: 2 / 3; }
  #spielfeld .group-1046 {
    grid-column: 3 / 4; }
  #spielfeld .group-1047 {
    grid-column: 4 / 5; }
  #spielfeld .group-1048 {
    grid-column: 5 / 6; }
  #spielfeld .group-1049 {
    grid-column: 6 / 7; }
  #spielfeld .group-1050 {
    grid-column: 7 / 8; }
  #spielfeld .group-1051 {
    grid-column: 8 / 9; }
  #spielfeld .group-1052 {
    grid-column: 9 / 10; }
  #spielfeld .group-1053 {
    grid-column: 10 / 11; }
  #spielfeld .group-1054 {
    grid-column: 11 / 12; }
  #spielfeld .group-1055 {
    grid-column: 12 / 13; }
  #spielfeld .group-1056 {
    grid-column: 13 / 14; }
  #spielfeld .group-1057 {
    grid-column: 14 / 15; }
  #spielfeld .group-1058 {
    grid-column: 15 / 16; }
  #spielfeld .group-1059 {
    grid-column: 16 / 17; }
  #spielfeld .group-1060 {
    grid-column: 17 / 18; }
  #spielfeld .group-1061 {
    grid-column: 18 / 19; }
  #spielfeld .group-1062 {
    grid-column: 19 / 20; }
  #spielfeld .group-1063 {
    grid-column: 20 / 21; }
  #spielfeld .group-1064 {
    grid-column: 2 / 3; }
  #spielfeld .group-1065 {
    grid-column: 3 / 4; }
  #spielfeld .group-1066 {
    grid-column: 4 / 5; }
  #spielfeld .group-1067 {
    grid-column: 5 / 6; }
  #spielfeld .group-1068 {
    grid-column: 6 / 7; }
  #spielfeld .group-1069 {
    grid-column: 7 / 8; }
  #spielfeld .group-1070 {
    grid-column: 8 / 9; }
  #spielfeld .group-1071 {
    grid-column: 9 / 10; }
  #spielfeld .group-1072 {
    grid-column: 10 / 11; }
  #spielfeld .group-1073 {
    grid-column: 11 / 12; }
  #spielfeld .group-1074 {
    grid-column: 12 / 13; }
  #spielfeld .group-1075 {
    grid-column: 13 / 14; }
  #spielfeld .group-1076 {
    grid-column: 14 / 15; }
  #spielfeld .group-1077 {
    grid-column: 15 / 16; }
  #spielfeld .group-1078 {
    grid-column: 16 / 17; }
  #spielfeld .group-1079 {
    grid-column: 17 / 18; }
  #spielfeld .group-1080 {
    grid-column: 18 / 19; }
  #spielfeld .group-1081 {
    grid-column: 19 / 20; }
  #spielfeld .group-1082 {
    grid-column: 20 / 21; }
  #spielfeld .group-1083 {
    grid-column: 2 / 3; }
  #spielfeld .group-1084 {
    grid-column: 3 / 4; }
  #spielfeld .group-1085 {
    grid-column: 4 / 5; }
  #spielfeld .group-1086 {
    grid-column: 5 / 6; }
  #spielfeld .group-1087 {
    grid-column: 6 / 7; }
  #spielfeld .group-1088 {
    grid-column: 7 / 8; }
  #spielfeld .group-1089 {
    grid-column: 8 / 9; }
  #spielfeld .group-1090 {
    grid-column: 9 / 10; }
  #spielfeld .group-1091 {
    grid-column: 10 / 11; }
  #spielfeld .group-1092 {
    grid-column: 11 / 12; }
  #spielfeld .group-1093 {
    grid-column: 12 / 13; }
  #spielfeld .group-1094 {
    grid-column: 13 / 14; }
  #spielfeld .group-1095 {
    grid-column: 14 / 15; }
  #spielfeld .group-1096 {
    grid-column: 15 / 16; }
  #spielfeld .group-1097 {
    grid-column: 16 / 17; }
  #spielfeld .group-1098 {
    grid-column: 17 / 18; }
  #spielfeld .group-1099 {
    grid-column: 18 / 19; }
  #spielfeld .group-1100 {
    grid-column: 19 / 20; }
  #spielfeld .group-1101 {
    grid-column: 20 / 21; }
  #spielfeld .group-1102 {
    grid-column: 2 / 3; }
  #spielfeld .group-1103 {
    grid-column: 3 / 4; }
  #spielfeld .group-1104 {
    grid-column: 4 / 5; }
  #spielfeld .group-1105 {
    grid-column: 5 / 6; }
  #spielfeld .group-1106 {
    grid-column: 6 / 7; }
  #spielfeld .group-1107 {
    grid-column: 7 / 8; }
  #spielfeld .group-1108 {
    grid-column: 8 / 9; }
  #spielfeld .group-1109 {
    grid-column: 9 / 10; }
  #spielfeld .group-1110 {
    grid-column: 10 / 11; }
  #spielfeld .group-1111 {
    grid-column: 11 / 12; }
  #spielfeld .group-1112 {
    grid-column: 12 / 13; }
  #spielfeld .group-1113 {
    grid-column: 13 / 14; }
  #spielfeld .group-1114 {
    grid-column: 14 / 15; }
  #spielfeld .group-1115 {
    grid-column: 15 / 16; }
  #spielfeld .group-1116 {
    grid-column: 16 / 17; }
  #spielfeld .group-1117 {
    grid-column: 17 / 18; }
  #spielfeld .group-1118 {
    grid-column: 18 / 19; }
  #spielfeld .group-1119 {
    grid-column: 19 / 20; }
  #spielfeld .group-1120 {
    grid-column: 20 / 21; }
  #spielfeld .group-1121 {
    grid-column: 2 / 3; }
  #spielfeld .group-1122 {
    grid-column: 3 / 4; }
  #spielfeld .group-1123 {
    grid-column: 4 / 5; }
  #spielfeld .group-1124 {
    grid-column: 5 / 6; }
  #spielfeld .group-1125 {
    grid-column: 6 / 7; }
  #spielfeld .group-1126 {
    grid-column: 7 / 8; }
  #spielfeld .group-1127 {
    grid-column: 8 / 9; }
  #spielfeld .group-1128 {
    grid-column: 9 / 10; }
  #spielfeld .group-1129 {
    grid-column: 10 / 11; }
  #spielfeld .group-1130 {
    grid-column: 11 / 12; }
  #spielfeld .group-1131 {
    grid-column: 12 / 13; }
  #spielfeld .group-1132 {
    grid-column: 13 / 14; }
  #spielfeld .group-1133 {
    grid-column: 14 / 15; }
  #spielfeld .group-1134 {
    grid-column: 15 / 16; }
  #spielfeld .group-1135 {
    grid-column: 16 / 17; }
  #spielfeld .group-1136 {
    grid-column: 17 / 18; }
  #spielfeld .group-1137 {
    grid-column: 18 / 19; }
  #spielfeld .group-1138 {
    grid-column: 19 / 20; }
  #spielfeld .group-1139 {
    grid-column: 20 / 21; }
  #spielfeld .group-1140 {
    grid-column: 2 / 3; }
  #spielfeld .group-1141 {
    grid-column: 3 / 4; }
  #spielfeld .group-1142 {
    grid-column: 4 / 5; }
  #spielfeld .group-1143 {
    grid-column: 5 / 6; }
  #spielfeld .group-1144 {
    grid-column: 6 / 7; }
  #spielfeld .group-1145 {
    grid-column: 7 / 8; }
  #spielfeld .group-1146 {
    grid-column: 8 / 9; }
  #spielfeld .group-1147 {
    grid-column: 9 / 10; }
  #spielfeld .group-1148 {
    grid-column: 10 / 11; }
  #spielfeld .group-1149 {
    grid-column: 11 / 12; }
  #spielfeld .group-1150 {
    grid-column: 12 / 13; }
  #spielfeld .group-1151 {
    grid-column: 13 / 14; }
  #spielfeld .group-1152 {
    grid-column: 14 / 15; }
  #spielfeld .group-1153 {
    grid-column: 15 / 16; }
  #spielfeld .group-1154 {
    grid-column: 16 / 17; }
  #spielfeld .group-1155 {
    grid-column: 17 / 18; }
  #spielfeld .group-1156 {
    grid-column: 18 / 19; }
  #spielfeld .group-1157 {
    grid-column: 19 / 20; }
  #spielfeld .group-1158 {
    grid-column: 20 / 21; }
  #spielfeld .group-1159 {
    grid-column: 2 / 3; }
  #spielfeld .group-1160 {
    grid-column: 3 / 4; }
  #spielfeld .group-1161 {
    grid-column: 4 / 5; }
  #spielfeld .group-1162 {
    grid-column: 5 / 6; }
  #spielfeld .group-1163 {
    grid-column: 6 / 7; }
  #spielfeld .group-1164 {
    grid-column: 7 / 8; }
  #spielfeld .group-1165 {
    grid-column: 8 / 9; }
  #spielfeld .group-1166 {
    grid-column: 9 / 10; }
  #spielfeld .group-1167 {
    grid-column: 10 / 11; }
  #spielfeld .group-1168 {
    grid-column: 11 / 12; }
  #spielfeld .group-1169 {
    grid-column: 12 / 13; }
  #spielfeld .group-1170 {
    grid-column: 13 / 14; }
  #spielfeld .group-1171 {
    grid-column: 14 / 15; }
  #spielfeld .group-1172 {
    grid-column: 15 / 16; }
  #spielfeld .group-1173 {
    grid-column: 16 / 17; }
  #spielfeld .group-1174 {
    grid-column: 17 / 18; }
  #spielfeld .group-1175 {
    grid-column: 18 / 19; }
  #spielfeld .group-1176 {
    grid-column: 19 / 20; }
  #spielfeld .group-1177 {
    grid-column: 20 / 21; }
  #spielfeld .group-1178 {
    grid-column: 2 / 3; }
  #spielfeld .group-1179 {
    grid-column: 3 / 4; }
  #spielfeld .group-1180 {
    grid-column: 4 / 5; }
  #spielfeld .group-1181 {
    grid-column: 5 / 6; }
  #spielfeld .group-1182 {
    grid-column: 6 / 7; }
  #spielfeld .group-1183 {
    grid-column: 7 / 8; }
  #spielfeld .group-1184 {
    grid-column: 8 / 9; }
  #spielfeld .group-1185 {
    grid-column: 9 / 10; }
  #spielfeld .group-1186 {
    grid-column: 10 / 11; }
  #spielfeld .group-1187 {
    grid-column: 11 / 12; }
  #spielfeld .group-1188 {
    grid-column: 12 / 13; }
  #spielfeld .group-1189 {
    grid-column: 13 / 14; }
  #spielfeld .group-1190 {
    grid-column: 14 / 15; }
  #spielfeld .group-1191 {
    grid-column: 15 / 16; }
  #spielfeld .group-1192 {
    grid-column: 16 / 17; }
  #spielfeld .group-1193 {
    grid-column: 17 / 18; }
  #spielfeld .group-1194 {
    grid-column: 18 / 19; }
  #spielfeld .group-1195 {
    grid-column: 19 / 20; }
  #spielfeld .group-1196 {
    grid-column: 20 / 21; }
  #spielfeld .group-1197 {
    grid-column: 2 / 3; }
  #spielfeld .group-1198 {
    grid-column: 3 / 4; }
  #spielfeld .group-1199 {
    grid-column: 4 / 5; }
  #spielfeld .group-1200 {
    grid-column: 5 / 6; }
  #spielfeld .group-1201 {
    grid-column: 6 / 7; }
  #spielfeld .group-1202 {
    grid-column: 7 / 8; }
  #spielfeld .group-1203 {
    grid-column: 8 / 9; }
  #spielfeld .group-1204 {
    grid-column: 9 / 10; }
  #spielfeld .group-1205 {
    grid-column: 10 / 11; }
  #spielfeld .group-1206 {
    grid-column: 11 / 12; }
  #spielfeld .group-1207 {
    grid-column: 12 / 13; }
  #spielfeld .group-1208 {
    grid-column: 13 / 14; }
  #spielfeld .group-1209 {
    grid-column: 14 / 15; }
  #spielfeld .group-1210 {
    grid-column: 15 / 16; }
  #spielfeld .group-1211 {
    grid-column: 16 / 17; }
  #spielfeld .group-1212 {
    grid-column: 17 / 18; }
  #spielfeld .group-1213 {
    grid-column: 18 / 19; }
  #spielfeld .group-1214 {
    grid-column: 19 / 20; }
  #spielfeld .group-1215 {
    grid-column: 20 / 21; }
  #spielfeld .group-1216 {
    grid-column: 2 / 3; }
  #spielfeld .group-1217 {
    grid-column: 3 / 4; }
  #spielfeld .group-1218 {
    grid-column: 4 / 5; }
  #spielfeld .group-1219 {
    grid-column: 5 / 6; }
  #spielfeld .group-1220 {
    grid-column: 6 / 7; }
  #spielfeld .group-1221 {
    grid-column: 7 / 8; }
  #spielfeld .group-1222 {
    grid-column: 8 / 9; }
  #spielfeld .group-1223 {
    grid-column: 9 / 10; }
  #spielfeld .group-1224 {
    grid-column: 10 / 11; }
  #spielfeld .group-1225 {
    grid-column: 11 / 12; }
  #spielfeld .group-1226 {
    grid-column: 12 / 13; }
  #spielfeld .group-1227 {
    grid-column: 13 / 14; }
  #spielfeld .group-1228 {
    grid-column: 14 / 15; }
  #spielfeld .group-1229 {
    grid-column: 15 / 16; }
  #spielfeld .group-1230 {
    grid-column: 16 / 17; }
  #spielfeld .group-1231 {
    grid-column: 17 / 18; }
  #spielfeld .group-1232 {
    grid-column: 18 / 19; }
  #spielfeld .group-1233 {
    grid-column: 19 / 20; }
  #spielfeld .group-1234 {
    grid-column: 20 / 21; }
  #spielfeld .group-1235 {
    grid-column: 2 / 3; }
  #spielfeld .group-1236 {
    grid-column: 3 / 4; }
  #spielfeld .group-1237 {
    grid-column: 4 / 5; }
  #spielfeld .group-1238 {
    grid-column: 5 / 6; }
  #spielfeld .group-1239 {
    grid-column: 6 / 7; }
  #spielfeld .group-1240 {
    grid-column: 7 / 8; }
  #spielfeld .group-1241 {
    grid-column: 8 / 9; }
  #spielfeld .group-1242 {
    grid-column: 9 / 10; }
  #spielfeld .group-1243 {
    grid-column: 10 / 11; }
  #spielfeld .group-1244 {
    grid-column: 11 / 12; }
  #spielfeld .group-1245 {
    grid-column: 12 / 13; }
  #spielfeld .group-1246 {
    grid-column: 13 / 14; }
  #spielfeld .group-1247 {
    grid-column: 14 / 15; }
  #spielfeld .group-1248 {
    grid-column: 15 / 16; }
  #spielfeld .group-1249 {
    grid-column: 16 / 17; }
  #spielfeld .group-1250 {
    grid-column: 17 / 18; }
  #spielfeld .group-1251 {
    grid-column: 18 / 19; }
  #spielfeld .group-1252 {
    grid-column: 19 / 20; }
  #spielfeld .group-1253 {
    grid-column: 20 / 21; }
  #spielfeld .group-1254 {
    grid-column: 2 / 3; }
  #spielfeld .group-1255 {
    grid-column: 3 / 4; }
  #spielfeld .group-1256 {
    grid-column: 4 / 5; }
  #spielfeld .group-1257 {
    grid-column: 5 / 6; }
  #spielfeld .group-1258 {
    grid-column: 6 / 7; }
  #spielfeld .group-1259 {
    grid-column: 7 / 8; }
  #spielfeld .group-1260 {
    grid-column: 8 / 9; }
  #spielfeld .group-1261 {
    grid-column: 9 / 10; }
  #spielfeld .group-1262 {
    grid-column: 10 / 11; }
  #spielfeld .group-1263 {
    grid-column: 11 / 12; }
  #spielfeld .group-1264 {
    grid-column: 12 / 13; }
  #spielfeld .group-1265 {
    grid-column: 13 / 14; }
  #spielfeld .group-1266 {
    grid-column: 14 / 15; }
  #spielfeld .group-1267 {
    grid-column: 15 / 16; }
  #spielfeld .group-1268 {
    grid-column: 16 / 17; }
  #spielfeld .group-1269 {
    grid-column: 17 / 18; }
  #spielfeld .group-1270 {
    grid-column: 18 / 19; }
  #spielfeld .group-1271 {
    grid-column: 19 / 20; }
  #spielfeld .group-1272 {
    grid-column: 20 / 21; }
  #spielfeld .group-1273 {
    grid-column: 2 / 3; }
  #spielfeld .group-1274 {
    grid-column: 3 / 4; }
  #spielfeld .group-1275 {
    grid-column: 4 / 5; }
  #spielfeld .group-1276 {
    grid-column: 5 / 6; }
  #spielfeld .group-1277 {
    grid-column: 6 / 7; }
  #spielfeld .group-1278 {
    grid-column: 7 / 8; }
  #spielfeld .group-1279 {
    grid-column: 8 / 9; }
  #spielfeld .group-1280 {
    grid-column: 9 / 10; }
  #spielfeld .group-1281 {
    grid-column: 10 / 11; }
  #spielfeld .group-1282 {
    grid-column: 11 / 12; }
  #spielfeld .group-1283 {
    grid-column: 12 / 13; }
  #spielfeld .group-1284 {
    grid-column: 13 / 14; }
  #spielfeld .group-1285 {
    grid-column: 14 / 15; }
  #spielfeld .group-1286 {
    grid-column: 15 / 16; }
  #spielfeld .group-1287 {
    grid-column: 16 / 17; }
  #spielfeld .group-1288 {
    grid-column: 17 / 18; }
  #spielfeld .group-1289 {
    grid-column: 18 / 19; }
  #spielfeld .group-1290 {
    grid-column: 19 / 20; }
  #spielfeld .group-1291 {
    grid-column: 20 / 21; }
  #spielfeld .group-1292 {
    grid-column: 2 / 3; }
  #spielfeld .group-1293 {
    grid-column: 3 / 4; }
  #spielfeld .group-1294 {
    grid-column: 4 / 5; }
  #spielfeld .group-1295 {
    grid-column: 5 / 6; }
  #spielfeld .group-1296 {
    grid-column: 6 / 7; }
  #spielfeld .group-1297 {
    grid-column: 7 / 8; }
  #spielfeld .group-1298 {
    grid-column: 8 / 9; }
  #spielfeld .group-1299 {
    grid-column: 9 / 10; }
  #spielfeld .group-1300 {
    grid-column: 10 / 11; }
  #spielfeld .group-1301 {
    grid-column: 11 / 12; }
  #spielfeld .group-1302 {
    grid-column: 12 / 13; }
  #spielfeld .group-1303 {
    grid-column: 13 / 14; }
  #spielfeld .group-1304 {
    grid-column: 14 / 15; }
  #spielfeld .group-1305 {
    grid-column: 15 / 16; }
  #spielfeld .group-1306 {
    grid-column: 16 / 17; }
  #spielfeld .group-1307 {
    grid-column: 17 / 18; }
  #spielfeld .group-1308 {
    grid-column: 18 / 19; }
  #spielfeld .group-1309 {
    grid-column: 19 / 20; }
  #spielfeld .group-1310 {
    grid-column: 20 / 21; }
  #spielfeld .group-1311 {
    grid-column: 2 / 3; }
  #spielfeld .group-1312 {
    grid-column: 3 / 4; }
  #spielfeld .group-1313 {
    grid-column: 4 / 5; }
  #spielfeld .group-1314 {
    grid-column: 5 / 6; }
  #spielfeld .group-1315 {
    grid-column: 6 / 7; }
  #spielfeld .group-1316 {
    grid-column: 7 / 8; }
  #spielfeld .group-1317 {
    grid-column: 8 / 9; }
  #spielfeld .group-1318 {
    grid-column: 9 / 10; }
  #spielfeld .group-1319 {
    grid-column: 10 / 11; }
  #spielfeld .group-1320 {
    grid-column: 11 / 12; }
  #spielfeld .group-1321 {
    grid-column: 12 / 13; }
  #spielfeld .group-1322 {
    grid-column: 13 / 14; }
  #spielfeld .group-1323 {
    grid-column: 14 / 15; }
  #spielfeld .group-1324 {
    grid-column: 15 / 16; }
  #spielfeld .group-1325 {
    grid-column: 16 / 17; }
  #spielfeld .group-1326 {
    grid-column: 17 / 18; }
  #spielfeld .group-1327 {
    grid-column: 18 / 19; }
  #spielfeld .group-1328 {
    grid-column: 19 / 20; }
  #spielfeld .group-1329 {
    grid-column: 20 / 21; }
  #spielfeld .group-1330 {
    grid-column: 2 / 3; }
  #spielfeld .group-1331 {
    grid-column: 3 / 4; }
  #spielfeld .group-1332 {
    grid-column: 4 / 5; }
  #spielfeld .group-1333 {
    grid-column: 5 / 6; }
  #spielfeld .group-1334 {
    grid-column: 6 / 7; }
  #spielfeld .group-1335 {
    grid-column: 7 / 8; }
  #spielfeld .group-1336 {
    grid-column: 8 / 9; }
  #spielfeld .group-1337 {
    grid-column: 9 / 10; }
  #spielfeld .group-1338 {
    grid-column: 10 / 11; }
  #spielfeld .group-1339 {
    grid-column: 11 / 12; }
  #spielfeld .group-1340 {
    grid-column: 12 / 13; }
  #spielfeld .group-1341 {
    grid-column: 13 / 14; }
  #spielfeld .group-1342 {
    grid-column: 14 / 15; }
  #spielfeld .group-1343 {
    grid-column: 15 / 16; }
  #spielfeld .group-1344 {
    grid-column: 16 / 17; }
  #spielfeld .group-1345 {
    grid-column: 17 / 18; }
  #spielfeld .group-1346 {
    grid-column: 18 / 19; }
  #spielfeld .group-1347 {
    grid-column: 19 / 20; }
  #spielfeld .group-1348 {
    grid-column: 20 / 21; }
  #spielfeld .group-1349 {
    grid-column: 2 / 3; }
  #spielfeld .group-1350 {
    grid-column: 3 / 4; }
  #spielfeld .group-1351 {
    grid-column: 4 / 5; }
  #spielfeld .group-1352 {
    grid-column: 5 / 6; }
  #spielfeld .group-1353 {
    grid-column: 6 / 7; }
  #spielfeld .group-1354 {
    grid-column: 7 / 8; }
  #spielfeld .group-1355 {
    grid-column: 8 / 9; }
  #spielfeld .group-1356 {
    grid-column: 9 / 10; }
  #spielfeld .group-1357 {
    grid-column: 10 / 11; }
  #spielfeld .group-1358 {
    grid-column: 11 / 12; }
  #spielfeld .group-1359 {
    grid-column: 12 / 13; }
  #spielfeld .group-1360 {
    grid-column: 13 / 14; }
  #spielfeld .group-1361 {
    grid-column: 14 / 15; }
  #spielfeld .group-1362 {
    grid-column: 15 / 16; }
  #spielfeld .group-1363 {
    grid-column: 16 / 17; }
  #spielfeld .group-1364 {
    grid-column: 17 / 18; }
  #spielfeld .group-1365 {
    grid-column: 18 / 19; }
  #spielfeld .group-1366 {
    grid-column: 19 / 20; }
  #spielfeld .group-1367 {
    grid-column: 20 / 21; }
  #spielfeld .group-1368 {
    grid-column: 2 / 3; }
  #spielfeld .group-1369 {
    grid-column: 3 / 4; }
  #spielfeld .group-1370 {
    grid-column: 4 / 5; }
  #spielfeld .group-1371 {
    grid-column: 5 / 6; }
  #spielfeld .group-1372 {
    grid-column: 6 / 7; }
  #spielfeld .group-1373 {
    grid-column: 7 / 8; }
  #spielfeld .group-1374 {
    grid-column: 8 / 9; }
  #spielfeld .group-1375 {
    grid-column: 9 / 10; }
  #spielfeld .group-1376 {
    grid-column: 10 / 11; }
  #spielfeld .group-1377 {
    grid-column: 11 / 12; }
  #spielfeld .group-1378 {
    grid-column: 12 / 13; }
  #spielfeld .group-1379 {
    grid-column: 13 / 14; }
  #spielfeld .group-1380 {
    grid-column: 14 / 15; }
  #spielfeld .group-1381 {
    grid-column: 15 / 16; }
  #spielfeld .group-1382 {
    grid-column: 16 / 17; }
  #spielfeld .group-1383 {
    grid-column: 17 / 18; }
  #spielfeld .group-1384 {
    grid-column: 18 / 19; }
  #spielfeld .group-1385 {
    grid-column: 19 / 20; }
  #spielfeld .group-1386 {
    grid-column: 20 / 21; }
  #spielfeld .group-1387 {
    grid-column: 2 / 3; }
  #spielfeld .group-1388 {
    grid-column: 3 / 4; }
  #spielfeld .group-1389 {
    grid-column: 4 / 5; }
  #spielfeld .group-1390 {
    grid-column: 5 / 6; }
  #spielfeld .group-1391 {
    grid-column: 6 / 7; }
  #spielfeld .group-1392 {
    grid-column: 7 / 8; }
  #spielfeld .group-1393 {
    grid-column: 8 / 9; }
  #spielfeld .group-1394 {
    grid-column: 9 / 10; }
  #spielfeld .group-1395 {
    grid-column: 10 / 11; }
  #spielfeld .group-1396 {
    grid-column: 11 / 12; }
  #spielfeld .group-1397 {
    grid-column: 12 / 13; }
  #spielfeld .group-1398 {
    grid-column: 13 / 14; }
  #spielfeld .group-1399 {
    grid-column: 14 / 15; }
  #spielfeld .group-1400 {
    grid-column: 15 / 16; }
  #spielfeld .group-1401 {
    grid-column: 16 / 17; }
  #spielfeld .group-1402 {
    grid-column: 17 / 18; }
  #spielfeld .group-1403 {
    grid-column: 18 / 19; }
  #spielfeld .group-1404 {
    grid-column: 19 / 20; }
  #spielfeld .group-1405 {
    grid-column: 20 / 21; }
  #spielfeld .group-1406 {
    grid-column: 2 / 3; }
  #spielfeld .group-1407 {
    grid-column: 3 / 4; }
  #spielfeld .group-1408 {
    grid-column: 4 / 5; }
  #spielfeld .group-1409 {
    grid-column: 5 / 6; }
  #spielfeld .group-1410 {
    grid-column: 6 / 7; }
  #spielfeld .group-1411 {
    grid-column: 7 / 8; }
  #spielfeld .group-1412 {
    grid-column: 8 / 9; }
  #spielfeld .group-1413 {
    grid-column: 9 / 10; }
  #spielfeld .group-1414 {
    grid-column: 10 / 11; }
  #spielfeld .group-1415 {
    grid-column: 11 / 12; }
  #spielfeld .group-1416 {
    grid-column: 12 / 13; }
  #spielfeld .group-1417 {
    grid-column: 13 / 14; }
  #spielfeld .group-1418 {
    grid-column: 14 / 15; }
  #spielfeld .group-1419 {
    grid-column: 15 / 16; }
  #spielfeld .group-1420 {
    grid-column: 16 / 17; }
  #spielfeld .group-1421 {
    grid-column: 17 / 18; }
  #spielfeld .group-1422 {
    grid-column: 18 / 19; }
  #spielfeld .group-1423 {
    grid-column: 19 / 20; }
  #spielfeld .group-1424 {
    grid-column: 20 / 21; }
  #spielfeld .group-1425 {
    grid-column: 2 / 3; }
  #spielfeld .group-1426 {
    grid-column: 3 / 4; }
  #spielfeld .group-1427 {
    grid-column: 4 / 5; }
  #spielfeld .group-1428 {
    grid-column: 5 / 6; }
  #spielfeld .group-1429 {
    grid-column: 6 / 7; }
  #spielfeld .group-1430 {
    grid-column: 7 / 8; }
  #spielfeld .group-1431 {
    grid-column: 8 / 9; }
  #spielfeld .group-1432 {
    grid-column: 9 / 10; }
  #spielfeld .group-1433 {
    grid-column: 10 / 11; }
  #spielfeld .group-1434 {
    grid-column: 11 / 12; }
  #spielfeld .group-1435 {
    grid-column: 12 / 13; }
  #spielfeld .group-1436 {
    grid-column: 13 / 14; }
  #spielfeld .group-1437 {
    grid-column: 14 / 15; }
  #spielfeld .group-1438 {
    grid-column: 15 / 16; }
  #spielfeld .group-1439 {
    grid-column: 16 / 17; }
  #spielfeld .group-1440 {
    grid-column: 17 / 18; }
  #spielfeld .group-1441 {
    grid-column: 18 / 19; }
  #spielfeld .group-1442 {
    grid-column: 19 / 20; }
  #spielfeld .group-1443 {
    grid-column: 20 / 21; }
  #spielfeld .group-1444 {
    grid-column: 2 / 3; }
  #spielfeld .group-1445 {
    grid-column: 3 / 4; }
  #spielfeld .group-1446 {
    grid-column: 4 / 5; }
  #spielfeld .group-1447 {
    grid-column: 5 / 6; }
  #spielfeld .group-1448 {
    grid-column: 6 / 7; }
  #spielfeld .group-1449 {
    grid-column: 7 / 8; }
  #spielfeld .group-1450 {
    grid-column: 8 / 9; }
  #spielfeld .group-1451 {
    grid-column: 9 / 10; }
  #spielfeld .group-1452 {
    grid-column: 10 / 11; }
  #spielfeld .group-1453 {
    grid-column: 11 / 12; }
  #spielfeld .group-1454 {
    grid-column: 12 / 13; }
  #spielfeld .group-1455 {
    grid-column: 13 / 14; }
  #spielfeld .group-1456 {
    grid-column: 14 / 15; }
  #spielfeld .group-1457 {
    grid-column: 15 / 16; }
  #spielfeld .group-1458 {
    grid-column: 16 / 17; }
  #spielfeld .group-1459 {
    grid-column: 17 / 18; }
  #spielfeld .group-1460 {
    grid-column: 18 / 19; }
  #spielfeld .group-1461 {
    grid-column: 19 / 20; }
  #spielfeld .group-1462 {
    grid-column: 20 / 21; }
  #spielfeld .group-1463 {
    grid-column: 2 / 3; }
  #spielfeld .group-1464 {
    grid-column: 3 / 4; }
  #spielfeld .group-1465 {
    grid-column: 4 / 5; }
  #spielfeld .group-1466 {
    grid-column: 5 / 6; }
  #spielfeld .group-1467 {
    grid-column: 6 / 7; }
  #spielfeld .group-1468 {
    grid-column: 7 / 8; }
  #spielfeld .group-1469 {
    grid-column: 8 / 9; }
  #spielfeld .group-1470 {
    grid-column: 9 / 10; }
  #spielfeld .group-1471 {
    grid-column: 10 / 11; }
  #spielfeld .group-1472 {
    grid-column: 11 / 12; }
  #spielfeld .group-1473 {
    grid-column: 12 / 13; }
  #spielfeld .group-1474 {
    grid-column: 13 / 14; }
  #spielfeld .group-1475 {
    grid-column: 14 / 15; }
  #spielfeld .group-1476 {
    grid-column: 15 / 16; }
  #spielfeld .group-1477 {
    grid-column: 16 / 17; }
  #spielfeld .group-1478 {
    grid-column: 17 / 18; }
  #spielfeld .group-1479 {
    grid-column: 18 / 19; }
  #spielfeld .group-1480 {
    grid-column: 19 / 20; }
  #spielfeld .group-1481 {
    grid-column: 20 / 21; }
  #spielfeld .group-1482 {
    grid-column: 2 / 3; }
  #spielfeld .group-1483 {
    grid-column: 3 / 4; }
  #spielfeld .group-1484 {
    grid-column: 4 / 5; }
  #spielfeld .group-1485 {
    grid-column: 5 / 6; }
  #spielfeld .group-1486 {
    grid-column: 6 / 7; }
  #spielfeld .group-1487 {
    grid-column: 7 / 8; }
  #spielfeld .group-1488 {
    grid-column: 8 / 9; }
  #spielfeld .group-1489 {
    grid-column: 9 / 10; }
  #spielfeld .group-1490 {
    grid-column: 10 / 11; }
  #spielfeld .group-1491 {
    grid-column: 11 / 12; }
  #spielfeld .group-1492 {
    grid-column: 12 / 13; }
  #spielfeld .group-1493 {
    grid-column: 13 / 14; }
  #spielfeld .group-1494 {
    grid-column: 14 / 15; }
  #spielfeld .group-1495 {
    grid-column: 15 / 16; }
  #spielfeld .group-1496 {
    grid-column: 16 / 17; }
  #spielfeld .group-1497 {
    grid-column: 17 / 18; }
  #spielfeld .group-1498 {
    grid-column: 18 / 19; }
  #spielfeld .group-1499 {
    grid-column: 19 / 20; }
  #spielfeld .group-1500 {
    grid-column: 20 / 21; }
  #spielfeld .group-1501 {
    grid-column: 2 / 3; }
  #spielfeld .group-1502 {
    grid-column: 3 / 4; }
  #spielfeld .group-1503 {
    grid-column: 4 / 5; }
  #spielfeld .group-1504 {
    grid-column: 5 / 6; }
  #spielfeld .group-1505 {
    grid-column: 6 / 7; }
  #spielfeld .group-1506 {
    grid-column: 7 / 8; }
  #spielfeld .group-1507 {
    grid-column: 8 / 9; }
  #spielfeld .group-1508 {
    grid-column: 9 / 10; }
  #spielfeld .group-1509 {
    grid-column: 10 / 11; }
  #spielfeld .group-1510 {
    grid-column: 11 / 12; }
  #spielfeld .group-1511 {
    grid-column: 12 / 13; }
  #spielfeld .group-1512 {
    grid-column: 13 / 14; }
  #spielfeld .group-1513 {
    grid-column: 14 / 15; }
  #spielfeld .group-1514 {
    grid-column: 15 / 16; }
  #spielfeld .group-1515 {
    grid-column: 16 / 17; }
  #spielfeld .group-1516 {
    grid-column: 17 / 18; }
  #spielfeld .group-1517 {
    grid-column: 18 / 19; }
  #spielfeld .group-1518 {
    grid-column: 19 / 20; }
  #spielfeld .group-1519 {
    grid-column: 20 / 21; }
  #spielfeld .group-1520 {
    grid-column: 2 / 3; }
  #spielfeld .group-1521 {
    grid-column: 3 / 4; }
  #spielfeld .group-1522 {
    grid-column: 4 / 5; }
  #spielfeld .group-1523 {
    grid-column: 5 / 6; }
  #spielfeld .group-1524 {
    grid-column: 6 / 7; }
  #spielfeld .group-1525 {
    grid-column: 7 / 8; }
  #spielfeld .group-1526 {
    grid-column: 8 / 9; }
  #spielfeld .group-1527 {
    grid-column: 9 / 10; }
  #spielfeld .group-1528 {
    grid-column: 10 / 11; }
  #spielfeld .group-1529 {
    grid-column: 11 / 12; }
  #spielfeld .group-1530 {
    grid-column: 12 / 13; }
  #spielfeld .group-1531 {
    grid-column: 13 / 14; }
  #spielfeld .group-1532 {
    grid-column: 14 / 15; }
  #spielfeld .group-1533 {
    grid-column: 15 / 16; }
  #spielfeld .group-1534 {
    grid-column: 16 / 17; }
  #spielfeld .group-1535 {
    grid-column: 17 / 18; }
  #spielfeld .group-1536 {
    grid-column: 18 / 19; }
  #spielfeld .group-1537 {
    grid-column: 19 / 20; }
  #spielfeld .group-1538 {
    grid-column: 20 / 21; }
  #spielfeld .group-1539 {
    grid-column: 2 / 3; }
  #spielfeld .group-1540 {
    grid-column: 3 / 4; }
  #spielfeld .group-1541 {
    grid-column: 4 / 5; }
  #spielfeld .group-1542 {
    grid-column: 5 / 6; }
  #spielfeld .group-1543 {
    grid-column: 6 / 7; }
  #spielfeld .group-1544 {
    grid-column: 7 / 8; }
  #spielfeld .group-1545 {
    grid-column: 8 / 9; }
  #spielfeld .group-1546 {
    grid-column: 9 / 10; }
  #spielfeld .group-1547 {
    grid-column: 10 / 11; }
  #spielfeld .group-1548 {
    grid-column: 11 / 12; }
  #spielfeld .group-1549 {
    grid-column: 12 / 13; }
  #spielfeld .group-1550 {
    grid-column: 13 / 14; }
  #spielfeld .group-1551 {
    grid-column: 14 / 15; }
  #spielfeld .group-1552 {
    grid-column: 15 / 16; }
  #spielfeld .group-1553 {
    grid-column: 16 / 17; }
  #spielfeld .group-1554 {
    grid-column: 17 / 18; }
  #spielfeld .group-1555 {
    grid-column: 18 / 19; }
  #spielfeld .group-1556 {
    grid-column: 19 / 20; }
  #spielfeld .group-1557 {
    grid-column: 20 / 21; }
  #spielfeld .group-1558 {
    grid-column: 2 / 3; }
  #spielfeld .group-1559 {
    grid-column: 3 / 4; }
  #spielfeld .group-1560 {
    grid-column: 4 / 5; }
  #spielfeld .group-1561 {
    grid-column: 5 / 6; }
  #spielfeld .group-1562 {
    grid-column: 6 / 7; }
  #spielfeld .group-1563 {
    grid-column: 7 / 8; }
  #spielfeld .group-1564 {
    grid-column: 8 / 9; }
  #spielfeld .group-1565 {
    grid-column: 9 / 10; }
  #spielfeld .group-1566 {
    grid-column: 10 / 11; }
  #spielfeld .group-1567 {
    grid-column: 11 / 12; }
  #spielfeld .group-1568 {
    grid-column: 12 / 13; }
  #spielfeld .group-1569 {
    grid-column: 13 / 14; }
  #spielfeld .group-1570 {
    grid-column: 14 / 15; }
  #spielfeld .group-1571 {
    grid-column: 15 / 16; }
  #spielfeld .group-1572 {
    grid-column: 16 / 17; }
  #spielfeld .group-1573 {
    grid-column: 17 / 18; }
  #spielfeld .group-1574 {
    grid-column: 18 / 19; }
  #spielfeld .group-1575 {
    grid-column: 19 / 20; }
  #spielfeld .group-1576 {
    grid-column: 20 / 21; }
  #spielfeld .group-1577 {
    grid-column: 2 / 3; }
  #spielfeld .group-1578 {
    grid-column: 3 / 4; }
  #spielfeld .group-1579 {
    grid-column: 4 / 5; }
  #spielfeld .group-1580 {
    grid-column: 5 / 6; }
  #spielfeld .group-1581 {
    grid-column: 6 / 7; }
  #spielfeld .group-1582 {
    grid-column: 7 / 8; }
  #spielfeld .group-1583 {
    grid-column: 8 / 9; }
  #spielfeld .group-1584 {
    grid-column: 9 / 10; }
  #spielfeld .group-1585 {
    grid-column: 10 / 11; }
  #spielfeld .group-1586 {
    grid-column: 11 / 12; }
  #spielfeld .group-1587 {
    grid-column: 12 / 13; }
  #spielfeld .group-1588 {
    grid-column: 13 / 14; }
  #spielfeld .group-1589 {
    grid-column: 14 / 15; }
  #spielfeld .group-1590 {
    grid-column: 15 / 16; }
  #spielfeld .group-1591 {
    grid-column: 16 / 17; }
  #spielfeld .group-1592 {
    grid-column: 17 / 18; }
  #spielfeld .group-1593 {
    grid-column: 18 / 19; }
  #spielfeld .group-1594 {
    grid-column: 19 / 20; }
  #spielfeld .group-1595 {
    grid-column: 20 / 21; }
  #spielfeld .group-1596 {
    grid-column: 2 / 3; }
  #spielfeld .group-1597 {
    grid-column: 3 / 4; }
  #spielfeld .group-1598 {
    grid-column: 4 / 5; }
  #spielfeld .group-1599 {
    grid-column: 5 / 6; }
  #spielfeld .group-1600 {
    grid-column: 6 / 7; }
  #spielfeld .group-1601 {
    grid-column: 7 / 8; }
  #spielfeld .group-1602 {
    grid-column: 8 / 9; }
  #spielfeld .group-1603 {
    grid-column: 9 / 10; }
  #spielfeld .group-1604 {
    grid-column: 10 / 11; }
  #spielfeld .group-1605 {
    grid-column: 11 / 12; }
  #spielfeld .group-1606 {
    grid-column: 12 / 13; }
  #spielfeld .group-1607 {
    grid-column: 13 / 14; }
  #spielfeld .group-1608 {
    grid-column: 14 / 15; }
  #spielfeld .group-1609 {
    grid-column: 15 / 16; }
  #spielfeld .group-1610 {
    grid-column: 16 / 17; }
  #spielfeld .group-1611 {
    grid-column: 17 / 18; }
  #spielfeld .group-1612 {
    grid-column: 18 / 19; }
  #spielfeld .group-1613 {
    grid-column: 19 / 20; }
  #spielfeld .group-1614 {
    grid-column: 20 / 21; }
  #spielfeld .group-1615 {
    grid-column: 2 / 3; }
  #spielfeld .group-1616 {
    grid-column: 3 / 4; }
  #spielfeld .group-1617 {
    grid-column: 4 / 5; }
  #spielfeld .group-1618 {
    grid-column: 5 / 6; }
  #spielfeld .group-1619 {
    grid-column: 6 / 7; }
  #spielfeld .group-1620 {
    grid-column: 7 / 8; }
  #spielfeld .group-1621 {
    grid-column: 8 / 9; }
  #spielfeld .group-1622 {
    grid-column: 9 / 10; }
  #spielfeld .group-1623 {
    grid-column: 10 / 11; }
  #spielfeld .group-1624 {
    grid-column: 11 / 12; }
  #spielfeld .group-1625 {
    grid-column: 12 / 13; }
  #spielfeld .group-1626 {
    grid-column: 13 / 14; }
  #spielfeld .group-1627 {
    grid-column: 14 / 15; }
  #spielfeld .group-1628 {
    grid-column: 15 / 16; }
  #spielfeld .group-1629 {
    grid-column: 16 / 17; }
  #spielfeld .group-1630 {
    grid-column: 17 / 18; }
  #spielfeld .group-1631 {
    grid-column: 18 / 19; }
  #spielfeld .group-1632 {
    grid-column: 19 / 20; }
  #spielfeld .group-1633 {
    grid-column: 20 / 21; }
  #spielfeld .group-1634 {
    grid-column: 2 / 3; }
  #spielfeld .group-1635 {
    grid-column: 3 / 4; }
  #spielfeld .group-1636 {
    grid-column: 4 / 5; }
  #spielfeld .group-1637 {
    grid-column: 5 / 6; }
  #spielfeld .group-1638 {
    grid-column: 6 / 7; }
  #spielfeld .group-1639 {
    grid-column: 7 / 8; }
  #spielfeld .group-1640 {
    grid-column: 8 / 9; }
  #spielfeld .group-1641 {
    grid-column: 9 / 10; }
  #spielfeld .group-1642 {
    grid-column: 10 / 11; }
  #spielfeld .group-1643 {
    grid-column: 11 / 12; }
  #spielfeld .group-1644 {
    grid-column: 12 / 13; }
  #spielfeld .group-1645 {
    grid-column: 13 / 14; }
  #spielfeld .group-1646 {
    grid-column: 14 / 15; }
  #spielfeld .group-1647 {
    grid-column: 15 / 16; }
  #spielfeld .group-1648 {
    grid-column: 16 / 17; }
  #spielfeld .group-1649 {
    grid-column: 17 / 18; }
  #spielfeld .group-1650 {
    grid-column: 18 / 19; }
  #spielfeld .group-1651 {
    grid-column: 19 / 20; }
  #spielfeld .group-1652 {
    grid-column: 20 / 21; }
  #spielfeld .group-1653 {
    grid-column: 2 / 3; }
  #spielfeld .group-1654 {
    grid-column: 3 / 4; }
  #spielfeld .group-1655 {
    grid-column: 4 / 5; }
  #spielfeld .group-1656 {
    grid-column: 5 / 6; }
  #spielfeld .group-1657 {
    grid-column: 6 / 7; }
  #spielfeld .group-1658 {
    grid-column: 7 / 8; }
  #spielfeld .group-1659 {
    grid-column: 8 / 9; }
  #spielfeld .group-1660 {
    grid-column: 9 / 10; }
  #spielfeld .group-1661 {
    grid-column: 10 / 11; }
  #spielfeld .group-1662 {
    grid-column: 11 / 12; }
  #spielfeld .group-1663 {
    grid-column: 12 / 13; }
  #spielfeld .group-1664 {
    grid-column: 13 / 14; }
  #spielfeld .group-1665 {
    grid-column: 14 / 15; }
  #spielfeld .group-1666 {
    grid-column: 15 / 16; }
  #spielfeld .group-1667 {
    grid-column: 16 / 17; }
  #spielfeld .group-1668 {
    grid-column: 17 / 18; }
  #spielfeld .group-1669 {
    grid-column: 18 / 19; }
  #spielfeld .group-1670 {
    grid-column: 19 / 20; }
  #spielfeld .group-1671 {
    grid-column: 20 / 21; }
  #spielfeld .group-1672 {
    grid-column: 2 / 3; }
  #spielfeld .group-1673 {
    grid-column: 3 / 4; }
  #spielfeld .group-1674 {
    grid-column: 4 / 5; }
  #spielfeld .group-1675 {
    grid-column: 5 / 6; }
  #spielfeld .group-1676 {
    grid-column: 6 / 7; }
  #spielfeld .group-1677 {
    grid-column: 7 / 8; }
  #spielfeld .group-1678 {
    grid-column: 8 / 9; }
  #spielfeld .group-1679 {
    grid-column: 9 / 10; }
  #spielfeld .group-1680 {
    grid-column: 10 / 11; }
  #spielfeld .group-1681 {
    grid-column: 11 / 12; }
  #spielfeld .group-1682 {
    grid-column: 12 / 13; }
  #spielfeld .group-1683 {
    grid-column: 13 / 14; }
  #spielfeld .group-1684 {
    grid-column: 14 / 15; }
  #spielfeld .group-1685 {
    grid-column: 15 / 16; }
  #spielfeld .group-1686 {
    grid-column: 16 / 17; }
  #spielfeld .group-1687 {
    grid-column: 17 / 18; }
  #spielfeld .group-1688 {
    grid-column: 18 / 19; }
  #spielfeld .group-1689 {
    grid-column: 19 / 20; }
  #spielfeld .group-1690 {
    grid-column: 20 / 21; }
  #spielfeld .group-1691 {
    grid-column: 2 / 3; }
  #spielfeld .group-1692 {
    grid-column: 3 / 4; }
  #spielfeld .group-1693 {
    grid-column: 4 / 5; }
  #spielfeld .group-1694 {
    grid-column: 5 / 6; }
  #spielfeld .group-1695 {
    grid-column: 6 / 7; }
  #spielfeld .group-1696 {
    grid-column: 7 / 8; }
  #spielfeld .group-1697 {
    grid-column: 8 / 9; }
  #spielfeld .group-1698 {
    grid-column: 9 / 10; }
  #spielfeld .group-1699 {
    grid-column: 10 / 11; }
  #spielfeld .group-1700 {
    grid-column: 11 / 12; }
  #spielfeld .group-1701 {
    grid-column: 12 / 13; }
  #spielfeld .group-1702 {
    grid-column: 13 / 14; }
  #spielfeld .group-1703 {
    grid-column: 14 / 15; }
  #spielfeld .group-1704 {
    grid-column: 15 / 16; }
  #spielfeld .group-1705 {
    grid-column: 16 / 17; }
  #spielfeld .group-1706 {
    grid-column: 17 / 18; }
  #spielfeld .group-1707 {
    grid-column: 18 / 19; }
  #spielfeld .group-1708 {
    grid-column: 19 / 20; }
  #spielfeld .group-1709 {
    grid-column: 20 / 21; }
  #spielfeld .group-1710 {
    grid-column: 2 / 3; }
  #spielfeld .group-1711 {
    grid-column: 3 / 4; }
  #spielfeld .group-1712 {
    grid-column: 4 / 5; }
  #spielfeld .group-1713 {
    grid-column: 5 / 6; }
  #spielfeld .group-1714 {
    grid-column: 6 / 7; }
  #spielfeld .group-1715 {
    grid-column: 7 / 8; }
  #spielfeld .group-1716 {
    grid-column: 8 / 9; }
  #spielfeld .group-1717 {
    grid-column: 9 / 10; }
  #spielfeld .group-1718 {
    grid-column: 10 / 11; }
  #spielfeld .group-1719 {
    grid-column: 11 / 12; }
  #spielfeld .group-1720 {
    grid-column: 12 / 13; }
  #spielfeld .group-1721 {
    grid-column: 13 / 14; }
  #spielfeld .group-1722 {
    grid-column: 14 / 15; }
  #spielfeld .group-1723 {
    grid-column: 15 / 16; }
  #spielfeld .group-1724 {
    grid-column: 16 / 17; }
  #spielfeld .group-1725 {
    grid-column: 17 / 18; }
  #spielfeld .group-1726 {
    grid-column: 18 / 19; }
  #spielfeld .group-1727 {
    grid-column: 19 / 20; }
  #spielfeld .group-1728 {
    grid-column: 20 / 21; }
  #spielfeld .group-1729 {
    grid-column: 2 / 3; }
  #spielfeld .group-1730 {
    grid-column: 3 / 4; }
  #spielfeld .group-1731 {
    grid-column: 4 / 5; }
  #spielfeld .group-1732 {
    grid-column: 5 / 6; }
  #spielfeld .group-1733 {
    grid-column: 6 / 7; }
  #spielfeld .group-1734 {
    grid-column: 7 / 8; }
  #spielfeld .group-1735 {
    grid-column: 8 / 9; }
  #spielfeld .group-1736 {
    grid-column: 9 / 10; }
  #spielfeld .group-1737 {
    grid-column: 10 / 11; }
  #spielfeld .group-1738 {
    grid-column: 11 / 12; }
  #spielfeld .group-1739 {
    grid-column: 12 / 13; }
  #spielfeld .group-1740 {
    grid-column: 13 / 14; }
  #spielfeld .group-1741 {
    grid-column: 14 / 15; }
  #spielfeld .group-1742 {
    grid-column: 15 / 16; }
  #spielfeld .group-1743 {
    grid-column: 16 / 17; }
  #spielfeld .group-1744 {
    grid-column: 17 / 18; }
  #spielfeld .group-1745 {
    grid-column: 18 / 19; }
  #spielfeld .group-1746 {
    grid-column: 19 / 20; }
  #spielfeld .group-1747 {
    grid-column: 20 / 21; }
  #spielfeld .group-1748 {
    grid-column: 2 / 3; }
  #spielfeld .group-1749 {
    grid-column: 3 / 4; }
  #spielfeld .group-1750 {
    grid-column: 4 / 5; }
  #spielfeld .group-1751 {
    grid-column: 5 / 6; }
  #spielfeld .group-1752 {
    grid-column: 6 / 7; }
  #spielfeld .group-1753 {
    grid-column: 7 / 8; }
  #spielfeld .group-1754 {
    grid-column: 8 / 9; }
  #spielfeld .group-1755 {
    grid-column: 9 / 10; }
  #spielfeld .group-1756 {
    grid-column: 10 / 11; }
  #spielfeld .group-1757 {
    grid-column: 11 / 12; }
  #spielfeld .group-1758 {
    grid-column: 12 / 13; }
  #spielfeld .group-1759 {
    grid-column: 13 / 14; }
  #spielfeld .group-1760 {
    grid-column: 14 / 15; }
  #spielfeld .group-1761 {
    grid-column: 15 / 16; }
  #spielfeld .group-1762 {
    grid-column: 16 / 17; }
  #spielfeld .group-1763 {
    grid-column: 17 / 18; }
  #spielfeld .group-1764 {
    grid-column: 18 / 19; }
  #spielfeld .group-1765 {
    grid-column: 19 / 20; }
  #spielfeld .group-1766 {
    grid-column: 20 / 21; }
  #spielfeld .group-1767 {
    grid-column: 2 / 3; }
  #spielfeld .group-1768 {
    grid-column: 3 / 4; }
  #spielfeld .group-1769 {
    grid-column: 4 / 5; }
  #spielfeld .group-1770 {
    grid-column: 5 / 6; }
  #spielfeld .group-1771 {
    grid-column: 6 / 7; }
  #spielfeld .group-1772 {
    grid-column: 7 / 8; }
  #spielfeld .group-1773 {
    grid-column: 8 / 9; }
  #spielfeld .group-1774 {
    grid-column: 9 / 10; }
  #spielfeld .group-1775 {
    grid-column: 10 / 11; }
  #spielfeld .group-1776 {
    grid-column: 11 / 12; }
  #spielfeld .group-1777 {
    grid-column: 12 / 13; }
  #spielfeld .group-1778 {
    grid-column: 13 / 14; }
  #spielfeld .group-1779 {
    grid-column: 14 / 15; }
  #spielfeld .group-1780 {
    grid-column: 15 / 16; }
  #spielfeld .group-1781 {
    grid-column: 16 / 17; }
  #spielfeld .group-1782 {
    grid-column: 17 / 18; }
  #spielfeld .group-1783 {
    grid-column: 18 / 19; }
  #spielfeld .group-1784 {
    grid-column: 19 / 20; }
  #spielfeld .group-1785 {
    grid-column: 20 / 21; }
  #spielfeld .group-1786 {
    grid-column: 2 / 3; }
  #spielfeld .group-1787 {
    grid-column: 3 / 4; }
  #spielfeld .group-1788 {
    grid-column: 4 / 5; }
  #spielfeld .group-1789 {
    grid-column: 5 / 6; }
  #spielfeld .group-1790 {
    grid-column: 6 / 7; }
  #spielfeld .group-1791 {
    grid-column: 7 / 8; }
  #spielfeld .group-1792 {
    grid-column: 8 / 9; }
  #spielfeld .group-1793 {
    grid-column: 9 / 10; }
  #spielfeld .group-1794 {
    grid-column: 10 / 11; }
  #spielfeld .group-1795 {
    grid-column: 11 / 12; }
  #spielfeld .group-1796 {
    grid-column: 12 / 13; }
  #spielfeld .group-1797 {
    grid-column: 13 / 14; }
  #spielfeld .group-1798 {
    grid-column: 14 / 15; }
  #spielfeld .group-1799 {
    grid-column: 15 / 16; }
  #spielfeld .group-1800 {
    grid-column: 16 / 17; }
  #spielfeld .group-1801 {
    grid-column: 17 / 18; }
  #spielfeld .group-1802 {
    grid-column: 18 / 19; }
  #spielfeld .group-1803 {
    grid-column: 19 / 20; }
  #spielfeld .group-1804 {
    grid-column: 20 / 21; }
  #spielfeld .group-1805 {
    grid-column: 2 / 3; }
  #spielfeld .group-1806 {
    grid-column: 3 / 4; }
  #spielfeld .group-1807 {
    grid-column: 4 / 5; }
  #spielfeld .group-1808 {
    grid-column: 5 / 6; }
  #spielfeld .group-1809 {
    grid-column: 6 / 7; }
  #spielfeld .group-1810 {
    grid-column: 7 / 8; }
  #spielfeld .group-1811 {
    grid-column: 8 / 9; }
  #spielfeld .group-1812 {
    grid-column: 9 / 10; }
  #spielfeld .group-1813 {
    grid-column: 10 / 11; }
  #spielfeld .group-1814 {
    grid-column: 11 / 12; }
  #spielfeld .group-1815 {
    grid-column: 12 / 13; }
  #spielfeld .group-1816 {
    grid-column: 13 / 14; }
  #spielfeld .group-1817 {
    grid-column: 14 / 15; }
  #spielfeld .group-1818 {
    grid-column: 15 / 16; }
  #spielfeld .group-1819 {
    grid-column: 16 / 17; }
  #spielfeld .group-1820 {
    grid-column: 17 / 18; }
  #spielfeld .group-1821 {
    grid-column: 18 / 19; }
  #spielfeld .group-1822 {
    grid-column: 19 / 20; }
  #spielfeld .group-1823 {
    grid-column: 20 / 21; }
  #spielfeld .group-1824 {
    grid-column: 2 / 3; }
  #spielfeld .group-1825 {
    grid-column: 3 / 4; }
  #spielfeld .group-1826 {
    grid-column: 4 / 5; }
  #spielfeld .group-1827 {
    grid-column: 5 / 6; }
  #spielfeld .group-1828 {
    grid-column: 6 / 7; }
  #spielfeld .group-1829 {
    grid-column: 7 / 8; }
  #spielfeld .group-1830 {
    grid-column: 8 / 9; }
  #spielfeld .group-1831 {
    grid-column: 9 / 10; }
  #spielfeld .group-1832 {
    grid-column: 10 / 11; }
  #spielfeld .group-1833 {
    grid-column: 11 / 12; }
  #spielfeld .group-1834 {
    grid-column: 12 / 13; }
  #spielfeld .group-1835 {
    grid-column: 13 / 14; }
  #spielfeld .group-1836 {
    grid-column: 14 / 15; }
  #spielfeld .group-1837 {
    grid-column: 15 / 16; }
  #spielfeld .group-1838 {
    grid-column: 16 / 17; }
  #spielfeld .group-1839 {
    grid-column: 17 / 18; }
  #spielfeld .group-1840 {
    grid-column: 18 / 19; }
  #spielfeld .group-1841 {
    grid-column: 19 / 20; }
  #spielfeld .group-1842 {
    grid-column: 20 / 21; }
  #spielfeld .group-1843 {
    grid-column: 2 / 3; }
  #spielfeld .group-1844 {
    grid-column: 3 / 4; }
  #spielfeld .group-1845 {
    grid-column: 4 / 5; }
  #spielfeld .group-1846 {
    grid-column: 5 / 6; }
  #spielfeld .group-1847 {
    grid-column: 6 / 7; }
  #spielfeld .group-1848 {
    grid-column: 7 / 8; }
  #spielfeld .group-1849 {
    grid-column: 8 / 9; }
  #spielfeld .group-1850 {
    grid-column: 9 / 10; }
  #spielfeld .group-1851 {
    grid-column: 10 / 11; }
  #spielfeld .group-1852 {
    grid-column: 11 / 12; }
  #spielfeld .group-1853 {
    grid-column: 12 / 13; }
  #spielfeld .group-1854 {
    grid-column: 13 / 14; }
  #spielfeld .group-1855 {
    grid-column: 14 / 15; }
  #spielfeld .group-1856 {
    grid-column: 15 / 16; }
  #spielfeld .group-1857 {
    grid-column: 16 / 17; }
  #spielfeld .group-1858 {
    grid-column: 17 / 18; }
  #spielfeld .group-1859 {
    grid-column: 18 / 19; }
  #spielfeld .group-1860 {
    grid-column: 19 / 20; }
  #spielfeld .group-1861 {
    grid-column: 20 / 21; }
  #spielfeld .group-1862 {
    grid-column: 2 / 3; }
  #spielfeld .group-1863 {
    grid-column: 3 / 4; }
  #spielfeld .group-1864 {
    grid-column: 4 / 5; }
  #spielfeld .group-1865 {
    grid-column: 5 / 6; }
  #spielfeld .group-1866 {
    grid-column: 6 / 7; }
  #spielfeld .group-1867 {
    grid-column: 7 / 8; }
  #spielfeld .group-1868 {
    grid-column: 8 / 9; }
  #spielfeld .group-1869 {
    grid-column: 9 / 10; }
  #spielfeld .group-1870 {
    grid-column: 10 / 11; }
  #spielfeld .group-1871 {
    grid-column: 11 / 12; }
  #spielfeld .group-1872 {
    grid-column: 12 / 13; }
  #spielfeld .group-1873 {
    grid-column: 13 / 14; }
  #spielfeld .group-1874 {
    grid-column: 14 / 15; }
  #spielfeld .group-1875 {
    grid-column: 15 / 16; }
  #spielfeld .group-1876 {
    grid-column: 16 / 17; }
  #spielfeld .group-1877 {
    grid-column: 17 / 18; }
  #spielfeld .group-1878 {
    grid-column: 18 / 19; }
  #spielfeld .group-1879 {
    grid-column: 19 / 20; }
  #spielfeld .group-1880 {
    grid-column: 20 / 21; }
  #spielfeld .group-1881 {
    grid-column: 2 / 3; }
  #spielfeld .group-1882 {
    grid-column: 3 / 4; }
  #spielfeld .group-1883 {
    grid-column: 4 / 5; }
  #spielfeld .group-1884 {
    grid-column: 5 / 6; }
  #spielfeld .group-1885 {
    grid-column: 6 / 7; }
  #spielfeld .group-1886 {
    grid-column: 7 / 8; }
  #spielfeld .group-1887 {
    grid-column: 8 / 9; }
  #spielfeld .group-1888 {
    grid-column: 9 / 10; }
  #spielfeld .group-1889 {
    grid-column: 10 / 11; }
  #spielfeld .group-1890 {
    grid-column: 11 / 12; }
  #spielfeld .group-1891 {
    grid-column: 12 / 13; }
  #spielfeld .group-1892 {
    grid-column: 13 / 14; }
  #spielfeld .group-1893 {
    grid-column: 14 / 15; }
  #spielfeld .group-1894 {
    grid-column: 15 / 16; }
  #spielfeld .group-1895 {
    grid-column: 16 / 17; }
  #spielfeld .group-1896 {
    grid-column: 17 / 18; }
  #spielfeld .group-1897 {
    grid-column: 18 / 19; }
  #spielfeld .group-1898 {
    grid-column: 19 / 20; }
  #spielfeld .group-1899 {
    grid-column: 20 / 21; }
  #spielfeld .group-1900 {
    grid-column: 2 / 3; }
  #spielfeld .group-1901 {
    grid-column: 3 / 4; }
  #spielfeld .group-1902 {
    grid-column: 4 / 5; }
  #spielfeld .group-1903 {
    grid-column: 5 / 6; }
  #spielfeld .group-1904 {
    grid-column: 6 / 7; }
  #spielfeld .group-1905 {
    grid-column: 7 / 8; }
  #spielfeld .group-1906 {
    grid-column: 8 / 9; }
  #spielfeld .group-1907 {
    grid-column: 9 / 10; }
  #spielfeld .group-1908 {
    grid-column: 10 / 11; }
  #spielfeld .group-1909 {
    grid-column: 11 / 12; }
  #spielfeld .group-1910 {
    grid-column: 12 / 13; }
  #spielfeld .group-1911 {
    grid-column: 13 / 14; }
  #spielfeld .group-1912 {
    grid-column: 14 / 15; }
  #spielfeld .group-1913 {
    grid-column: 15 / 16; }
  #spielfeld .group-1914 {
    grid-column: 16 / 17; }
  #spielfeld .group-1915 {
    grid-column: 17 / 18; }
  #spielfeld .group-1916 {
    grid-column: 18 / 19; }
  #spielfeld .group-1917 {
    grid-column: 19 / 20; }
  #spielfeld .group-1918 {
    grid-column: 20 / 21; }
  #spielfeld .group-1919 {
    grid-column: 2 / 3; }
  #spielfeld .group-1920 {
    grid-column: 3 / 4; }
  #spielfeld .group-1921 {
    grid-column: 4 / 5; }
  #spielfeld .group-1922 {
    grid-column: 5 / 6; }
  #spielfeld .group-1923 {
    grid-column: 6 / 7; }
  #spielfeld .group-1924 {
    grid-column: 7 / 8; }
  #spielfeld .group-1925 {
    grid-column: 8 / 9; }
  #spielfeld .group-1926 {
    grid-column: 9 / 10; }
  #spielfeld .group-1927 {
    grid-column: 10 / 11; }
  #spielfeld .group-1928 {
    grid-column: 11 / 12; }
  #spielfeld .group-1929 {
    grid-column: 12 / 13; }
  #spielfeld .group-1930 {
    grid-column: 13 / 14; }
  #spielfeld .group-1931 {
    grid-column: 14 / 15; }
  #spielfeld .group-1932 {
    grid-column: 15 / 16; }
  #spielfeld .group-1933 {
    grid-column: 16 / 17; }
  #spielfeld .group-1934 {
    grid-column: 17 / 18; }
  #spielfeld .group-1935 {
    grid-column: 18 / 19; }
  #spielfeld .group-1936 {
    grid-column: 19 / 20; }
  #spielfeld .group-1937 {
    grid-column: 20 / 21; }
  #spielfeld .group-1938 {
    grid-column: 2 / 3; }
  #spielfeld .group-1939 {
    grid-column: 3 / 4; }
  #spielfeld .group-1940 {
    grid-column: 4 / 5; }
  #spielfeld .group-1941 {
    grid-column: 5 / 6; }
  #spielfeld .group-1942 {
    grid-column: 6 / 7; }
  #spielfeld .group-1943 {
    grid-column: 7 / 8; }
  #spielfeld .group-1944 {
    grid-column: 8 / 9; }
  #spielfeld .group-1945 {
    grid-column: 9 / 10; }
  #spielfeld .group-1946 {
    grid-column: 10 / 11; }
  #spielfeld .group-1947 {
    grid-column: 11 / 12; }
  #spielfeld .group-1948 {
    grid-column: 12 / 13; }
  #spielfeld .group-1949 {
    grid-column: 13 / 14; }
  #spielfeld .group-1950 {
    grid-column: 14 / 15; }
  #spielfeld .group-1951 {
    grid-column: 15 / 16; }
  #spielfeld .group-1952 {
    grid-column: 16 / 17; }
  #spielfeld .group-1953 {
    grid-column: 17 / 18; }
  #spielfeld .group-1954 {
    grid-column: 18 / 19; }
  #spielfeld .group-1955 {
    grid-column: 19 / 20; }
  #spielfeld .group-1956 {
    grid-column: 20 / 21; }
  #spielfeld .group-1957 {
    grid-column: 2 / 3; }
  #spielfeld .group-1958 {
    grid-column: 3 / 4; }
  #spielfeld .group-1959 {
    grid-column: 4 / 5; }
  #spielfeld .group-1960 {
    grid-column: 5 / 6; }
  #spielfeld .group-1961 {
    grid-column: 6 / 7; }
  #spielfeld .group-1962 {
    grid-column: 7 / 8; }
  #spielfeld .group-1963 {
    grid-column: 8 / 9; }
  #spielfeld .group-1964 {
    grid-column: 9 / 10; }
  #spielfeld .group-1965 {
    grid-column: 10 / 11; }
  #spielfeld .group-1966 {
    grid-column: 11 / 12; }
  #spielfeld .group-1967 {
    grid-column: 12 / 13; }
  #spielfeld .group-1968 {
    grid-column: 13 / 14; }
  #spielfeld .group-1969 {
    grid-column: 14 / 15; }
  #spielfeld .group-1970 {
    grid-column: 15 / 16; }
  #spielfeld .group-1971 {
    grid-column: 16 / 17; }
  #spielfeld .group-1972 {
    grid-column: 17 / 18; }
  #spielfeld .group-1973 {
    grid-column: 18 / 19; }
  #spielfeld .group-1974 {
    grid-column: 19 / 20; }
  #spielfeld .group-1975 {
    grid-column: 20 / 21; }
  #spielfeld .group-1976 {
    grid-column: 2 / 3; }
  #spielfeld .group-1977 {
    grid-column: 3 / 4; }
  #spielfeld .group-1978 {
    grid-column: 4 / 5; }
  #spielfeld .group-1979 {
    grid-column: 5 / 6; }
  #spielfeld .group-1980 {
    grid-column: 6 / 7; }
  #spielfeld .group-1981 {
    grid-column: 7 / 8; }
  #spielfeld .group-1982 {
    grid-column: 8 / 9; }
  #spielfeld .group-1983 {
    grid-column: 9 / 10; }
  #spielfeld .group-1984 {
    grid-column: 10 / 11; }
  #spielfeld .group-1985 {
    grid-column: 11 / 12; }
  #spielfeld .group-1986 {
    grid-column: 12 / 13; }
  #spielfeld .group-1987 {
    grid-column: 13 / 14; }
  #spielfeld .group-1988 {
    grid-column: 14 / 15; }
  #spielfeld .group-1989 {
    grid-column: 15 / 16; }
  #spielfeld .group-1990 {
    grid-column: 16 / 17; }
  #spielfeld .group-1991 {
    grid-column: 17 / 18; }
  #spielfeld .group-1992 {
    grid-column: 18 / 19; }
  #spielfeld .group-1993 {
    grid-column: 19 / 20; }
  #spielfeld .group-1994 {
    grid-column: 20 / 21; }
  #spielfeld .group-1995 {
    grid-column: 2 / 3; }
  #spielfeld .group-1996 {
    grid-column: 3 / 4; }
  #spielfeld .group-1997 {
    grid-column: 4 / 5; }
  #spielfeld .group-1998 {
    grid-column: 5 / 6; }
  #spielfeld .group-1999 {
    grid-column: 6 / 7; }
  #spielfeld .group-2000 {
    grid-column: 7 / 8; }
  #spielfeld .group-2001 {
    grid-column: 8 / 9; }
  #spielfeld .group-2002 {
    grid-column: 9 / 10; }
  #spielfeld .group-2003 {
    grid-column: 10 / 11; }
  #spielfeld .group-2004 {
    grid-column: 11 / 12; }
  #spielfeld .group-2005 {
    grid-column: 12 / 13; }
  #spielfeld .group-2006 {
    grid-column: 13 / 14; }
  #spielfeld .group-2007 {
    grid-column: 14 / 15; }
  #spielfeld .group-2008 {
    grid-column: 15 / 16; }
  #spielfeld .group-2009 {
    grid-column: 16 / 17; }
  #spielfeld .group-2010 {
    grid-column: 17 / 18; }
  #spielfeld .group-2011 {
    grid-column: 18 / 19; }
  #spielfeld .group-2012 {
    grid-column: 19 / 20; }
  #spielfeld .group-2013 {
    grid-column: 20 / 21; }
  #spielfeld .group-2014 {
    grid-column: 2 / 3; }
  #spielfeld .group-2015 {
    grid-column: 3 / 4; }
  #spielfeld .group-2016 {
    grid-column: 4 / 5; }
  #spielfeld .group-2017 {
    grid-column: 5 / 6; }
  #spielfeld .group-2018 {
    grid-column: 6 / 7; }
  #spielfeld .group-2019 {
    grid-column: 7 / 8; }
  #spielfeld .group-2020 {
    grid-column: 8 / 9; }
  #spielfeld .group-2021 {
    grid-column: 9 / 10; }
  #spielfeld .group-2022 {
    grid-column: 10 / 11; }
  #spielfeld .group-2023 {
    grid-column: 11 / 12; }
  #spielfeld .group-2024 {
    grid-column: 12 / 13; }
  #spielfeld .group-2025 {
    grid-column: 13 / 14; }
  #spielfeld .group-2026 {
    grid-column: 14 / 15; }
  #spielfeld .group-2027 {
    grid-column: 15 / 16; }
  #spielfeld .group-2028 {
    grid-column: 16 / 17; }
  #spielfeld .group-2029 {
    grid-column: 17 / 18; }
  #spielfeld .group-2030 {
    grid-column: 18 / 19; }
  #spielfeld .group-2031 {
    grid-column: 19 / 20; }
  #spielfeld .group-2032 {
    grid-column: 20 / 21; }
  #spielfeld .group-2033 {
    grid-column: 2 / 3; }
  #spielfeld .group-2034 {
    grid-column: 3 / 4; }
  #spielfeld .group-2035 {
    grid-column: 4 / 5; }
  #spielfeld .group-2036 {
    grid-column: 5 / 6; }
  #spielfeld .group-2037 {
    grid-column: 6 / 7; }
  #spielfeld .group-2038 {
    grid-column: 7 / 8; }
  #spielfeld .group-2039 {
    grid-column: 8 / 9; }
  #spielfeld .group-2040 {
    grid-column: 9 / 10; }
  #spielfeld .group-2041 {
    grid-column: 10 / 11; }
  #spielfeld .group-2042 {
    grid-column: 11 / 12; }
  #spielfeld .group-2043 {
    grid-column: 12 / 13; }
  #spielfeld .group-2044 {
    grid-column: 13 / 14; }
  #spielfeld .group-2045 {
    grid-column: 14 / 15; }
  #spielfeld .group-2046 {
    grid-column: 15 / 16; }
  #spielfeld .group-2047 {
    grid-column: 16 / 17; }
  #spielfeld .group-2048 {
    grid-column: 17 / 18; }
  #spielfeld .group-2049 {
    grid-column: 18 / 19; }
  #spielfeld .group-2050 {
    grid-column: 19 / 20; }
  #spielfeld .group-2051 {
    grid-column: 20 / 21; }
  #spielfeld .group-2052 {
    grid-column: 2 / 3; }
  #spielfeld .group-2053 {
    grid-column: 3 / 4; }
  #spielfeld .group-2054 {
    grid-column: 4 / 5; }
  #spielfeld .group-2055 {
    grid-column: 5 / 6; }
  #spielfeld .group-2056 {
    grid-column: 6 / 7; }
  #spielfeld .group-2057 {
    grid-column: 7 / 8; }
  #spielfeld .group-2058 {
    grid-column: 8 / 9; }
  #spielfeld .group-2059 {
    grid-column: 9 / 10; }
  #spielfeld .group-2060 {
    grid-column: 10 / 11; }
  #spielfeld .group-2061 {
    grid-column: 11 / 12; }
  #spielfeld .group-2062 {
    grid-column: 12 / 13; }
  #spielfeld .group-2063 {
    grid-column: 13 / 14; }
  #spielfeld .group-2064 {
    grid-column: 14 / 15; }
  #spielfeld .group-2065 {
    grid-column: 15 / 16; }
  #spielfeld .group-2066 {
    grid-column: 16 / 17; }
  #spielfeld .group-2067 {
    grid-column: 17 / 18; }
  #spielfeld .group-2068 {
    grid-column: 18 / 19; }
  #spielfeld .group-2069 {
    grid-column: 19 / 20; }
  #spielfeld .group-2070 {
    grid-column: 20 / 21; }
  #spielfeld .group-2071 {
    grid-column: 2 / 3; }
  #spielfeld .group-2072 {
    grid-column: 3 / 4; }
  #spielfeld .group-2073 {
    grid-column: 4 / 5; }
  #spielfeld .group-2074 {
    grid-column: 5 / 6; }
  #spielfeld .group-2075 {
    grid-column: 6 / 7; }
  #spielfeld .group-2076 {
    grid-column: 7 / 8; }
  #spielfeld .group-2077 {
    grid-column: 8 / 9; }
  #spielfeld .group-2078 {
    grid-column: 9 / 10; }
  #spielfeld .group-2079 {
    grid-column: 10 / 11; }
  #spielfeld .group-2080 {
    grid-column: 11 / 12; }
  #spielfeld .group-2081 {
    grid-column: 12 / 13; }
  #spielfeld .group-2082 {
    grid-column: 13 / 14; }
  #spielfeld .group-2083 {
    grid-column: 14 / 15; }
  #spielfeld .group-2084 {
    grid-column: 15 / 16; }
  #spielfeld .group-2085 {
    grid-column: 16 / 17; }
  #spielfeld .group-2086 {
    grid-column: 17 / 18; }
  #spielfeld .group-2087 {
    grid-column: 18 / 19; }
  #spielfeld .group-2088 {
    grid-column: 19 / 20; }
  #spielfeld .group-2089 {
    grid-column: 20 / 21; }
  #spielfeld .group-2090 {
    grid-column: 2 / 3; }
  #spielfeld .group-2091 {
    grid-column: 3 / 4; }
  #spielfeld .group-2092 {
    grid-column: 4 / 5; }
  #spielfeld .group-2093 {
    grid-column: 5 / 6; }
  #spielfeld .group-2094 {
    grid-column: 6 / 7; }
  #spielfeld .group-2095 {
    grid-column: 7 / 8; }
  #spielfeld .group-2096 {
    grid-column: 8 / 9; }
  #spielfeld .group-2097 {
    grid-column: 9 / 10; }
  #spielfeld .group-2098 {
    grid-column: 10 / 11; }
  #spielfeld .group-2099 {
    grid-column: 11 / 12; }
  #spielfeld .group-2100 {
    grid-column: 12 / 13; }
  #spielfeld .group-2101 {
    grid-column: 13 / 14; }
  #spielfeld .group-2102 {
    grid-column: 14 / 15; }
  #spielfeld .group-2103 {
    grid-column: 15 / 16; }
  #spielfeld .group-2104 {
    grid-column: 16 / 17; }
  #spielfeld .group-2105 {
    grid-column: 17 / 18; }
  #spielfeld .group-2106 {
    grid-column: 18 / 19; }
  #spielfeld .group-2107 {
    grid-column: 19 / 20; }
  #spielfeld .group-2108 {
    grid-column: 20 / 21; }
  #spielfeld .group-2109 {
    grid-column: 2 / 3; }
  #spielfeld .group-2110 {
    grid-column: 3 / 4; }
  #spielfeld .group-2111 {
    grid-column: 4 / 5; }
  #spielfeld .group-2112 {
    grid-column: 5 / 6; }
  #spielfeld .group-2113 {
    grid-column: 6 / 7; }
  #spielfeld .group-2114 {
    grid-column: 7 / 8; }
  #spielfeld .group-2115 {
    grid-column: 8 / 9; }
  #spielfeld .group-2116 {
    grid-column: 9 / 10; }
  #spielfeld .group-2117 {
    grid-column: 10 / 11; }
  #spielfeld .group-2118 {
    grid-column: 11 / 12; }
  #spielfeld .group-2119 {
    grid-column: 12 / 13; }
  #spielfeld .group-2120 {
    grid-column: 13 / 14; }
  #spielfeld .group-2121 {
    grid-column: 14 / 15; }
  #spielfeld .group-2122 {
    grid-column: 15 / 16; }
  #spielfeld .group-2123 {
    grid-column: 16 / 17; }
  #spielfeld .group-2124 {
    grid-column: 17 / 18; }
  #spielfeld .group-2125 {
    grid-column: 18 / 19; }
  #spielfeld .group-2126 {
    grid-column: 19 / 20; }
  #spielfeld .group-2127 {
    grid-column: 20 / 21; }
  #spielfeld .group-2128 {
    grid-column: 2 / 3; }
  #spielfeld .group-2129 {
    grid-column: 3 / 4; }
  #spielfeld .group-2130 {
    grid-column: 4 / 5; }
  #spielfeld .group-2131 {
    grid-column: 5 / 6; }
  #spielfeld .group-2132 {
    grid-column: 6 / 7; }
  #spielfeld .group-2133 {
    grid-column: 7 / 8; }
  #spielfeld .group-2134 {
    grid-column: 8 / 9; }
  #spielfeld .group-2135 {
    grid-column: 9 / 10; }
  #spielfeld .group-2136 {
    grid-column: 10 / 11; }
  #spielfeld .group-2137 {
    grid-column: 11 / 12; }
  #spielfeld .group-2138 {
    grid-column: 12 / 13; }
  #spielfeld .group-2139 {
    grid-column: 13 / 14; }
  #spielfeld .group-2140 {
    grid-column: 14 / 15; }
  #spielfeld .group-2141 {
    grid-column: 15 / 16; }
  #spielfeld .group-2142 {
    grid-column: 16 / 17; }
  #spielfeld .group-2143 {
    grid-column: 17 / 18; }
  #spielfeld .group-2144 {
    grid-column: 18 / 19; }
  #spielfeld .group-2145 {
    grid-column: 19 / 20; }
  #spielfeld .group-2146 {
    grid-column: 20 / 21; }
  #spielfeld .group-2147 {
    grid-column: 2 / 3; }
  #spielfeld .group-2148 {
    grid-column: 3 / 4; }
  #spielfeld .group-2149 {
    grid-column: 4 / 5; }
  #spielfeld .group-2150 {
    grid-column: 5 / 6; }
  #spielfeld .group-2151 {
    grid-column: 6 / 7; }
  #spielfeld .group-2152 {
    grid-column: 7 / 8; }
  #spielfeld .group-2153 {
    grid-column: 8 / 9; }
  #spielfeld .group-2154 {
    grid-column: 9 / 10; }
  #spielfeld .group-2155 {
    grid-column: 10 / 11; }
  #spielfeld .group-2156 {
    grid-column: 11 / 12; }
  #spielfeld .group-2157 {
    grid-column: 12 / 13; }
  #spielfeld .group-2158 {
    grid-column: 13 / 14; }
  #spielfeld .group-2159 {
    grid-column: 14 / 15; }
  #spielfeld .group-2160 {
    grid-column: 15 / 16; }
  #spielfeld .group-2161 {
    grid-column: 16 / 17; }
  #spielfeld .group-2162 {
    grid-column: 17 / 18; }
  #spielfeld .group-2163 {
    grid-column: 18 / 19; }
  #spielfeld .group-2164 {
    grid-column: 19 / 20; }
  #spielfeld .group-2165 {
    grid-column: 20 / 21; }
  #spielfeld .group-2166 {
    grid-column: 2 / 3; }
  #spielfeld .group-2167 {
    grid-column: 3 / 4; }
  #spielfeld .group-2168 {
    grid-column: 4 / 5; }
  #spielfeld .group-2169 {
    grid-column: 5 / 6; }
  #spielfeld .group-2170 {
    grid-column: 6 / 7; }
  #spielfeld .group-2171 {
    grid-column: 7 / 8; }
  #spielfeld .group-2172 {
    grid-column: 8 / 9; }
  #spielfeld .group-2173 {
    grid-column: 9 / 10; }
  #spielfeld .group-2174 {
    grid-column: 10 / 11; }
  #spielfeld .group-2175 {
    grid-column: 11 / 12; }
  #spielfeld .group-2176 {
    grid-column: 12 / 13; }
  #spielfeld .group-2177 {
    grid-column: 13 / 14; }
  #spielfeld .group-2178 {
    grid-column: 14 / 15; }
  #spielfeld .group-2179 {
    grid-column: 15 / 16; }
  #spielfeld .group-2180 {
    grid-column: 16 / 17; }
  #spielfeld .group-2181 {
    grid-column: 17 / 18; }
  #spielfeld .group-2182 {
    grid-column: 18 / 19; }
  #spielfeld .group-2183 {
    grid-column: 19 / 20; }
  #spielfeld .group-2184 {
    grid-column: 20 / 21; }
  #spielfeld .group-2185 {
    grid-column: 2 / 3; }
  #spielfeld .group-2186 {
    grid-column: 3 / 4; }
  #spielfeld .group-2187 {
    grid-column: 4 / 5; }
  #spielfeld .group-2188 {
    grid-column: 5 / 6; }
  #spielfeld .group-2189 {
    grid-column: 6 / 7; }
  #spielfeld .group-2190 {
    grid-column: 7 / 8; }
  #spielfeld .group-2191 {
    grid-column: 8 / 9; }
  #spielfeld .group-2192 {
    grid-column: 9 / 10; }
  #spielfeld .group-2193 {
    grid-column: 10 / 11; }
  #spielfeld .group-2194 {
    grid-column: 11 / 12; }
  #spielfeld .group-2195 {
    grid-column: 12 / 13; }
  #spielfeld .group-2196 {
    grid-column: 13 / 14; }
  #spielfeld .group-2197 {
    grid-column: 14 / 15; }
  #spielfeld .group-2198 {
    grid-column: 15 / 16; }
  #spielfeld .group-2199 {
    grid-column: 16 / 17; }
  #spielfeld .group-2200 {
    grid-column: 17 / 18; }
  #spielfeld .group-2201 {
    grid-column: 18 / 19; }
  #spielfeld .group-2202 {
    grid-column: 19 / 20; }
  #spielfeld .group-2203 {
    grid-column: 20 / 21; }
  #spielfeld .group-2204 {
    grid-column: 2 / 3; }
  #spielfeld .group-2205 {
    grid-column: 3 / 4; }
  #spielfeld .group-2206 {
    grid-column: 4 / 5; }
  #spielfeld .group-2207 {
    grid-column: 5 / 6; }
  #spielfeld .group-2208 {
    grid-column: 6 / 7; }
  #spielfeld .group-2209 {
    grid-column: 7 / 8; }
  #spielfeld .group-2210 {
    grid-column: 8 / 9; }
  #spielfeld .group-2211 {
    grid-column: 9 / 10; }
  #spielfeld .group-2212 {
    grid-column: 10 / 11; }
  #spielfeld .group-2213 {
    grid-column: 11 / 12; }
  #spielfeld .group-2214 {
    grid-column: 12 / 13; }
  #spielfeld .group-2215 {
    grid-column: 13 / 14; }
  #spielfeld .group-2216 {
    grid-column: 14 / 15; }
  #spielfeld .group-2217 {
    grid-column: 15 / 16; }
  #spielfeld .group-2218 {
    grid-column: 16 / 17; }
  #spielfeld .group-2219 {
    grid-column: 17 / 18; }
  #spielfeld .group-2220 {
    grid-column: 18 / 19; }
  #spielfeld .group-2221 {
    grid-column: 19 / 20; }
  #spielfeld .group-2222 {
    grid-column: 20 / 21; }
  #spielfeld .group-2223 {
    grid-column: 2 / 3; }
  #spielfeld .group-2224 {
    grid-column: 3 / 4; }
  #spielfeld .group-2225 {
    grid-column: 4 / 5; }
  #spielfeld .group-2226 {
    grid-column: 5 / 6; }
  #spielfeld .group-2227 {
    grid-column: 6 / 7; }
  #spielfeld .group-2228 {
    grid-column: 7 / 8; }
  #spielfeld .group-2229 {
    grid-column: 8 / 9; }
  #spielfeld .group-2230 {
    grid-column: 9 / 10; }
  #spielfeld .group-2231 {
    grid-column: 10 / 11; }
  #spielfeld .group-2232 {
    grid-column: 11 / 12; }
  #spielfeld .group-2233 {
    grid-column: 12 / 13; }
  #spielfeld .group-2234 {
    grid-column: 13 / 14; }
  #spielfeld .group-2235 {
    grid-column: 14 / 15; }
  #spielfeld .group-2236 {
    grid-column: 15 / 16; }
  #spielfeld .group-2237 {
    grid-column: 16 / 17; }
  #spielfeld .group-2238 {
    grid-column: 17 / 18; }
  #spielfeld .group-2239 {
    grid-column: 18 / 19; }
  #spielfeld .group-2240 {
    grid-column: 19 / 20; }
  #spielfeld .group-2241 {
    grid-column: 20 / 21; }
  #spielfeld .group-2242 {
    grid-column: 2 / 3; }
  #spielfeld .group-2243 {
    grid-column: 3 / 4; }
  #spielfeld .group-2244 {
    grid-column: 4 / 5; }
  #spielfeld .group-2245 {
    grid-column: 5 / 6; }
  #spielfeld .group-2246 {
    grid-column: 6 / 7; }
  #spielfeld .group-2247 {
    grid-column: 7 / 8; }
  #spielfeld .group-2248 {
    grid-column: 8 / 9; }
  #spielfeld .group-2249 {
    grid-column: 9 / 10; }
  #spielfeld .group-2250 {
    grid-column: 10 / 11; }
  #spielfeld .group-2251 {
    grid-column: 11 / 12; }
  #spielfeld .group-2252 {
    grid-column: 12 / 13; }
  #spielfeld .group-2253 {
    grid-column: 13 / 14; }
  #spielfeld .group-2254 {
    grid-column: 14 / 15; }
  #spielfeld .group-2255 {
    grid-column: 15 / 16; }
  #spielfeld .group-2256 {
    grid-column: 16 / 17; }
  #spielfeld .group-2257 {
    grid-column: 17 / 18; }
  #spielfeld .group-2258 {
    grid-column: 18 / 19; }
  #spielfeld .group-2259 {
    grid-column: 19 / 20; }
  #spielfeld .group-2260 {
    grid-column: 20 / 21; }
  #spielfeld .group-2261 {
    grid-column: 2 / 3; }
  #spielfeld .group-2262 {
    grid-column: 3 / 4; }
  #spielfeld .group-2263 {
    grid-column: 4 / 5; }
  #spielfeld .group-2264 {
    grid-column: 5 / 6; }
  #spielfeld .group-2265 {
    grid-column: 6 / 7; }
  #spielfeld .group-2266 {
    grid-column: 7 / 8; }
  #spielfeld .group-2267 {
    grid-column: 8 / 9; }
  #spielfeld .group-2268 {
    grid-column: 9 / 10; }
  #spielfeld .group-2269 {
    grid-column: 10 / 11; }
  #spielfeld .group-2270 {
    grid-column: 11 / 12; }
  #spielfeld .group-2271 {
    grid-column: 12 / 13; }
  #spielfeld .group-2272 {
    grid-column: 13 / 14; }
  #spielfeld .group-2273 {
    grid-column: 14 / 15; }
  #spielfeld .group-2274 {
    grid-column: 15 / 16; }
  #spielfeld .group-2275 {
    grid-column: 16 / 17; }
  #spielfeld .group-2276 {
    grid-column: 17 / 18; }
  #spielfeld .group-2277 {
    grid-column: 18 / 19; }
  #spielfeld .group-2278 {
    grid-column: 19 / 20; }
  #spielfeld .group-2279 {
    grid-column: 20 / 21; }
  #spielfeld .group-2280 {
    grid-column: 2 / 3; }
  #spielfeld .group-2281 {
    grid-column: 3 / 4; }
  #spielfeld .group-2282 {
    grid-column: 4 / 5; }
  #spielfeld .group-2283 {
    grid-column: 5 / 6; }
  #spielfeld .group-2284 {
    grid-column: 6 / 7; }
  #spielfeld .group-2285 {
    grid-column: 7 / 8; }
  #spielfeld .group-2286 {
    grid-column: 8 / 9; }
  #spielfeld .group-2287 {
    grid-column: 9 / 10; }
  #spielfeld .group-2288 {
    grid-column: 10 / 11; }
  #spielfeld .group-2289 {
    grid-column: 11 / 12; }
  #spielfeld .group-2290 {
    grid-column: 12 / 13; }
  #spielfeld .group-2291 {
    grid-column: 13 / 14; }
  #spielfeld .group-2292 {
    grid-column: 14 / 15; }
  #spielfeld .group-2293 {
    grid-column: 15 / 16; }
  #spielfeld .group-2294 {
    grid-column: 16 / 17; }
  #spielfeld .group-2295 {
    grid-column: 17 / 18; }
  #spielfeld .group-2296 {
    grid-column: 18 / 19; }
  #spielfeld .group-2297 {
    grid-column: 19 / 20; }
  #spielfeld .group-2298 {
    grid-column: 20 / 21; }
  #spielfeld .group-2299 {
    grid-column: 2 / 3; }
  #spielfeld .group-2300 {
    grid-column: 3 / 4; }
  #spielfeld .group-2301 {
    grid-column: 4 / 5; }
  #spielfeld .group-2302 {
    grid-column: 5 / 6; }
  #spielfeld .group-2303 {
    grid-column: 6 / 7; }
  #spielfeld .group-2304 {
    grid-column: 7 / 8; }
  #spielfeld .group-2305 {
    grid-column: 8 / 9; }
  #spielfeld .group-2306 {
    grid-column: 9 / 10; }
  #spielfeld .group-2307 {
    grid-column: 10 / 11; }
  #spielfeld .group-2308 {
    grid-column: 11 / 12; }
  #spielfeld .group-2309 {
    grid-column: 12 / 13; }
  #spielfeld .group-2310 {
    grid-column: 13 / 14; }
  #spielfeld .group-2311 {
    grid-column: 14 / 15; }
  #spielfeld .group-2312 {
    grid-column: 15 / 16; }
  #spielfeld .group-2313 {
    grid-column: 16 / 17; }
  #spielfeld .group-2314 {
    grid-column: 17 / 18; }
  #spielfeld .group-2315 {
    grid-column: 18 / 19; }
  #spielfeld .group-2316 {
    grid-column: 19 / 20; }
  #spielfeld .group-2317 {
    grid-column: 20 / 21; }
  #spielfeld .group-2318 {
    grid-column: 2 / 3; }
  #spielfeld .group-2319 {
    grid-column: 3 / 4; }
  #spielfeld .group-2320 {
    grid-column: 4 / 5; }
  #spielfeld .group-2321 {
    grid-column: 5 / 6; }
  #spielfeld .group-2322 {
    grid-column: 6 / 7; }
  #spielfeld .group-2323 {
    grid-column: 7 / 8; }
  #spielfeld .group-2324 {
    grid-column: 8 / 9; }
  #spielfeld .group-2325 {
    grid-column: 9 / 10; }
  #spielfeld .group-2326 {
    grid-column: 10 / 11; }
  #spielfeld .group-2327 {
    grid-column: 11 / 12; }
  #spielfeld .group-2328 {
    grid-column: 12 / 13; }
  #spielfeld .group-2329 {
    grid-column: 13 / 14; }
  #spielfeld .group-2330 {
    grid-column: 14 / 15; }
  #spielfeld .group-2331 {
    grid-column: 15 / 16; }
  #spielfeld .group-2332 {
    grid-column: 16 / 17; }
  #spielfeld .group-2333 {
    grid-column: 17 / 18; }
  #spielfeld .group-2334 {
    grid-column: 18 / 19; }
  #spielfeld .group-2335 {
    grid-column: 19 / 20; }
  #spielfeld .group-2336 {
    grid-column: 20 / 21; }
  #spielfeld .group-2337 {
    grid-column: 2 / 3; }
  #spielfeld .group-2338 {
    grid-column: 3 / 4; }
  #spielfeld .group-2339 {
    grid-column: 4 / 5; }
  #spielfeld .group-2340 {
    grid-column: 5 / 6; }
  #spielfeld .group-2341 {
    grid-column: 6 / 7; }
  #spielfeld .group-2342 {
    grid-column: 7 / 8; }
  #spielfeld .group-2343 {
    grid-column: 8 / 9; }
  #spielfeld .group-2344 {
    grid-column: 9 / 10; }
  #spielfeld .group-2345 {
    grid-column: 10 / 11; }
  #spielfeld .group-2346 {
    grid-column: 11 / 12; }
  #spielfeld .group-2347 {
    grid-column: 12 / 13; }
  #spielfeld .group-2348 {
    grid-column: 13 / 14; }
  #spielfeld .group-2349 {
    grid-column: 14 / 15; }
  #spielfeld .group-2350 {
    grid-column: 15 / 16; }
  #spielfeld .group-2351 {
    grid-column: 16 / 17; }
  #spielfeld .group-2352 {
    grid-column: 17 / 18; }
  #spielfeld .group-2353 {
    grid-column: 18 / 19; }
  #spielfeld .group-2354 {
    grid-column: 19 / 20; }
  #spielfeld .group-2355 {
    grid-column: 20 / 21; }
  #spielfeld .group-2356 {
    grid-column: 2 / 3; }
  #spielfeld .group-2357 {
    grid-column: 3 / 4; }
  #spielfeld .group-2358 {
    grid-column: 4 / 5; }
  #spielfeld .group-2359 {
    grid-column: 5 / 6; }
  #spielfeld .group-2360 {
    grid-column: 6 / 7; }
  #spielfeld .group-2361 {
    grid-column: 7 / 8; }
  #spielfeld .group-2362 {
    grid-column: 8 / 9; }
  #spielfeld .group-2363 {
    grid-column: 9 / 10; }
  #spielfeld .group-2364 {
    grid-column: 10 / 11; }
  #spielfeld .group-2365 {
    grid-column: 11 / 12; }
  #spielfeld .group-2366 {
    grid-column: 12 / 13; }
  #spielfeld .group-2367 {
    grid-column: 13 / 14; }
  #spielfeld .group-2368 {
    grid-column: 14 / 15; }
  #spielfeld .group-2369 {
    grid-column: 15 / 16; }
  #spielfeld .group-2370 {
    grid-column: 16 / 17; }
  #spielfeld .group-2371 {
    grid-column: 17 / 18; }
  #spielfeld .group-2372 {
    grid-column: 18 / 19; }
  #spielfeld .group-2373 {
    grid-column: 19 / 20; }
  #spielfeld .group-2374 {
    grid-column: 20 / 21; }
  #spielfeld .group-2375 {
    grid-column: 2 / 3; }
  #spielfeld .group-2376 {
    grid-column: 3 / 4; }
  #spielfeld .group-2377 {
    grid-column: 4 / 5; }
  #spielfeld .group-2378 {
    grid-column: 5 / 6; }
  #spielfeld .group-2379 {
    grid-column: 6 / 7; }
  #spielfeld .group-2380 {
    grid-column: 7 / 8; }
  #spielfeld .group-2381 {
    grid-column: 8 / 9; }
  #spielfeld .group-2382 {
    grid-column: 9 / 10; }
  #spielfeld .group-2383 {
    grid-column: 10 / 11; }
  #spielfeld .group-2384 {
    grid-column: 11 / 12; }
  #spielfeld .group-2385 {
    grid-column: 12 / 13; }
  #spielfeld .group-2386 {
    grid-column: 13 / 14; }
  #spielfeld .group-2387 {
    grid-column: 14 / 15; }
  #spielfeld .group-2388 {
    grid-column: 15 / 16; }
  #spielfeld .group-2389 {
    grid-column: 16 / 17; }
  #spielfeld .group-2390 {
    grid-column: 17 / 18; }
  #spielfeld .group-2391 {
    grid-column: 18 / 19; }
  #spielfeld .group-2392 {
    grid-column: 19 / 20; }
  #spielfeld .group-2393 {
    grid-column: 20 / 21; }
  #spielfeld .group-2394 {
    grid-column: 2 / 3; }
  #spielfeld .group-2395 {
    grid-column: 3 / 4; }
  #spielfeld .group-2396 {
    grid-column: 4 / 5; }
  #spielfeld .group-2397 {
    grid-column: 5 / 6; }
  #spielfeld .group-2398 {
    grid-column: 6 / 7; }
  #spielfeld .group-2399 {
    grid-column: 7 / 8; }
  #spielfeld .group-2400 {
    grid-column: 8 / 9; }
  #spielfeld .group-2401 {
    grid-column: 9 / 10; }
  #spielfeld .group-2402 {
    grid-column: 10 / 11; }
  #spielfeld .group-2403 {
    grid-column: 11 / 12; }
  #spielfeld .group-2404 {
    grid-column: 12 / 13; }
  #spielfeld .group-2405 {
    grid-column: 13 / 14; }
  #spielfeld .group-2406 {
    grid-column: 14 / 15; }
  #spielfeld .group-2407 {
    grid-column: 15 / 16; }
  #spielfeld .group-2408 {
    grid-column: 16 / 17; }
  #spielfeld .group-2409 {
    grid-column: 17 / 18; }
  #spielfeld .group-2410 {
    grid-column: 18 / 19; }
  #spielfeld .group-2411 {
    grid-column: 19 / 20; }
  #spielfeld .group-2412 {
    grid-column: 20 / 21; }
  #spielfeld .group-2413 {
    grid-column: 2 / 3; }
  #spielfeld .group-2414 {
    grid-column: 3 / 4; }
  #spielfeld .group-2415 {
    grid-column: 4 / 5; }
  #spielfeld .group-2416 {
    grid-column: 5 / 6; }
  #spielfeld .group-2417 {
    grid-column: 6 / 7; }
  #spielfeld .group-2418 {
    grid-column: 7 / 8; }
  #spielfeld .group-2419 {
    grid-column: 8 / 9; }
  #spielfeld .group-2420 {
    grid-column: 9 / 10; }
  #spielfeld .group-2421 {
    grid-column: 10 / 11; }
  #spielfeld .group-2422 {
    grid-column: 11 / 12; }
  #spielfeld .group-2423 {
    grid-column: 12 / 13; }
  #spielfeld .group-2424 {
    grid-column: 13 / 14; }
  #spielfeld .group-2425 {
    grid-column: 14 / 15; }
  #spielfeld .group-2426 {
    grid-column: 15 / 16; }
  #spielfeld .group-2427 {
    grid-column: 16 / 17; }
  #spielfeld .group-2428 {
    grid-column: 17 / 18; }
  #spielfeld .group-2429 {
    grid-column: 18 / 19; }
  #spielfeld .group-2430 {
    grid-column: 19 / 20; }
  #spielfeld .group-2431 {
    grid-column: 20 / 21; }
  #spielfeld .group-2432 {
    grid-column: 2 / 3; }
  #spielfeld .group-2433 {
    grid-column: 3 / 4; }
  #spielfeld .group-2434 {
    grid-column: 4 / 5; }
  #spielfeld .group-2435 {
    grid-column: 5 / 6; }
  #spielfeld .group-2436 {
    grid-column: 6 / 7; }
  #spielfeld .group-2437 {
    grid-column: 7 / 8; }
  #spielfeld .group-2438 {
    grid-column: 8 / 9; }
  #spielfeld .group-2439 {
    grid-column: 9 / 10; }
  #spielfeld .group-2440 {
    grid-column: 10 / 11; }
  #spielfeld .group-2441 {
    grid-column: 11 / 12; }
  #spielfeld .group-2442 {
    grid-column: 12 / 13; }
  #spielfeld .group-2443 {
    grid-column: 13 / 14; }
  #spielfeld .group-2444 {
    grid-column: 14 / 15; }
  #spielfeld .group-2445 {
    grid-column: 15 / 16; }
  #spielfeld .group-2446 {
    grid-column: 16 / 17; }
  #spielfeld .group-2447 {
    grid-column: 17 / 18; }
  #spielfeld .group-2448 {
    grid-column: 18 / 19; }
  #spielfeld .group-2449 {
    grid-column: 19 / 20; }
  #spielfeld .group-2450 {
    grid-column: 20 / 21; }
  #spielfeld .group-2451 {
    grid-column: 2 / 3; }
  #spielfeld .group-2452 {
    grid-column: 3 / 4; }
  #spielfeld .group-2453 {
    grid-column: 4 / 5; }
  #spielfeld .group-2454 {
    grid-column: 5 / 6; }
  #spielfeld .group-2455 {
    grid-column: 6 / 7; }
  #spielfeld .group-2456 {
    grid-column: 7 / 8; }
  #spielfeld .group-2457 {
    grid-column: 8 / 9; }
  #spielfeld .group-2458 {
    grid-column: 9 / 10; }
  #spielfeld .group-2459 {
    grid-column: 10 / 11; }
  #spielfeld .group-2460 {
    grid-column: 11 / 12; }
  #spielfeld .group-2461 {
    grid-column: 12 / 13; }
  #spielfeld .group-2462 {
    grid-column: 13 / 14; }
  #spielfeld .group-2463 {
    grid-column: 14 / 15; }
  #spielfeld .group-2464 {
    grid-column: 15 / 16; }
  #spielfeld .group-2465 {
    grid-column: 16 / 17; }
  #spielfeld .group-2466 {
    grid-column: 17 / 18; }
  #spielfeld .group-2467 {
    grid-column: 18 / 19; }
  #spielfeld .group-2468 {
    grid-column: 19 / 20; }
  #spielfeld .group-2469 {
    grid-column: 20 / 21; }
  #spielfeld .group-2470 {
    grid-column: 2 / 3; }
  #spielfeld .group-2471 {
    grid-column: 3 / 4; }
  #spielfeld .group-2472 {
    grid-column: 4 / 5; }
  #spielfeld .group-2473 {
    grid-column: 5 / 6; }
  #spielfeld .group-2474 {
    grid-column: 6 / 7; }
  #spielfeld .group-2475 {
    grid-column: 7 / 8; }
  #spielfeld .group-2476 {
    grid-column: 8 / 9; }
  #spielfeld .group-2477 {
    grid-column: 9 / 10; }
  #spielfeld .group-2478 {
    grid-column: 10 / 11; }
  #spielfeld .group-2479 {
    grid-column: 11 / 12; }
  #spielfeld .group-2480 {
    grid-column: 12 / 13; }
  #spielfeld .group-2481 {
    grid-column: 13 / 14; }
  #spielfeld .group-2482 {
    grid-column: 14 / 15; }
  #spielfeld .group-2483 {
    grid-column: 15 / 16; }
  #spielfeld .group-2484 {
    grid-column: 16 / 17; }
  #spielfeld .group-2485 {
    grid-column: 17 / 18; }
  #spielfeld .group-2486 {
    grid-column: 18 / 19; }
  #spielfeld .group-2487 {
    grid-column: 19 / 20; }
  #spielfeld .group-2488 {
    grid-column: 20 / 21; }
  #spielfeld .group-2489 {
    grid-column: 2 / 3; }
  #spielfeld .group-2490 {
    grid-column: 3 / 4; }
  #spielfeld .group-2491 {
    grid-column: 4 / 5; }
  #spielfeld .group-2492 {
    grid-column: 5 / 6; }
  #spielfeld .group-2493 {
    grid-column: 6 / 7; }
  #spielfeld .group-2494 {
    grid-column: 7 / 8; }
  #spielfeld .group-2495 {
    grid-column: 8 / 9; }
  #spielfeld .group-2496 {
    grid-column: 9 / 10; }
  #spielfeld .group-2497 {
    grid-column: 10 / 11; }
  #spielfeld .group-2498 {
    grid-column: 11 / 12; }
  #spielfeld .group-2499 {
    grid-column: 12 / 13; }
  #spielfeld .group-2500 {
    grid-column: 13 / 14; }
  #spielfeld .group-2501 {
    grid-column: 14 / 15; }
  #spielfeld .group-2502 {
    grid-column: 15 / 16; }
  #spielfeld .group-2503 {
    grid-column: 16 / 17; }
  #spielfeld .group-2504 {
    grid-column: 17 / 18; }
  #spielfeld .group-2505 {
    grid-column: 18 / 19; }
  #spielfeld .group-2506 {
    grid-column: 19 / 20; }
  #spielfeld .group-2507 {
    grid-column: 20 / 21; }
  #spielfeld .group-2508 {
    grid-column: 2 / 3; }
  #spielfeld .group-2509 {
    grid-column: 3 / 4; }
  #spielfeld .group-2510 {
    grid-column: 4 / 5; }
  #spielfeld .group-2511 {
    grid-column: 5 / 6; }
  #spielfeld .group-2512 {
    grid-column: 6 / 7; }
  #spielfeld .group-2513 {
    grid-column: 7 / 8; }
  #spielfeld .group-2514 {
    grid-column: 8 / 9; }
  #spielfeld .group-2515 {
    grid-column: 9 / 10; }
  #spielfeld .group-2516 {
    grid-column: 10 / 11; }
  #spielfeld .group-2517 {
    grid-column: 11 / 12; }
  #spielfeld .group-2518 {
    grid-column: 12 / 13; }
  #spielfeld .group-2519 {
    grid-column: 13 / 14; }
  #spielfeld .group-2520 {
    grid-column: 14 / 15; }
  #spielfeld .group-2521 {
    grid-column: 15 / 16; }
  #spielfeld .group-2522 {
    grid-column: 16 / 17; }
  #spielfeld .group-2523 {
    grid-column: 17 / 18; }
  #spielfeld .group-2524 {
    grid-column: 18 / 19; }
  #spielfeld .group-2525 {
    grid-column: 19 / 20; }
  #spielfeld .group-2526 {
    grid-column: 20 / 21; }
  #spielfeld .group-2527 {
    grid-column: 2 / 3; }
  #spielfeld .group-2528 {
    grid-column: 3 / 4; }
  #spielfeld .group-2529 {
    grid-column: 4 / 5; }
  #spielfeld .group-2530 {
    grid-column: 5 / 6; }
  #spielfeld .group-2531 {
    grid-column: 6 / 7; }
  #spielfeld .group-2532 {
    grid-column: 7 / 8; }
  #spielfeld .group-2533 {
    grid-column: 8 / 9; }
  #spielfeld .group-2534 {
    grid-column: 9 / 10; }
  #spielfeld .group-2535 {
    grid-column: 10 / 11; }
  #spielfeld .group-2536 {
    grid-column: 11 / 12; }
  #spielfeld .group-2537 {
    grid-column: 12 / 13; }
  #spielfeld .group-2538 {
    grid-column: 13 / 14; }
  #spielfeld .group-2539 {
    grid-column: 14 / 15; }
  #spielfeld .group-2540 {
    grid-column: 15 / 16; }
  #spielfeld .group-2541 {
    grid-column: 16 / 17; }
  #spielfeld .group-2542 {
    grid-column: 17 / 18; }
  #spielfeld .group-2543 {
    grid-column: 18 / 19; }
  #spielfeld .group-2544 {
    grid-column: 19 / 20; }
  #spielfeld .group-2545 {
    grid-column: 20 / 21; }
  #spielfeld .group-2546 {
    grid-column: 2 / 3; }
  #spielfeld .group-2547 {
    grid-column: 3 / 4; }
  #spielfeld .group-2548 {
    grid-column: 4 / 5; }
  #spielfeld .group-2549 {
    grid-column: 5 / 6; }
  #spielfeld .group-2550 {
    grid-column: 6 / 7; }
  #spielfeld .group-2551 {
    grid-column: 7 / 8; }
  #spielfeld .group-2552 {
    grid-column: 8 / 9; }
  #spielfeld .group-2553 {
    grid-column: 9 / 10; }
  #spielfeld .group-2554 {
    grid-column: 10 / 11; }
  #spielfeld .group-2555 {
    grid-column: 11 / 12; }
  #spielfeld .group-2556 {
    grid-column: 12 / 13; }
  #spielfeld .group-2557 {
    grid-column: 13 / 14; }
  #spielfeld .group-2558 {
    grid-column: 14 / 15; }
  #spielfeld .group-2559 {
    grid-column: 15 / 16; }
  #spielfeld .group-2560 {
    grid-column: 16 / 17; }
  #spielfeld .group-2561 {
    grid-column: 17 / 18; }
  #spielfeld .group-2562 {
    grid-column: 18 / 19; }
  #spielfeld .group-2563 {
    grid-column: 19 / 20; }
  #spielfeld .group-2564 {
    grid-column: 20 / 21; }
  #spielfeld .group-2565 {
    grid-column: 2 / 3; }
  #spielfeld .group-2566 {
    grid-column: 3 / 4; }
  #spielfeld .group-2567 {
    grid-column: 4 / 5; }
  #spielfeld .group-2568 {
    grid-column: 5 / 6; }
  #spielfeld .group-2569 {
    grid-column: 6 / 7; }
  #spielfeld .group-2570 {
    grid-column: 7 / 8; }
  #spielfeld .group-2571 {
    grid-column: 8 / 9; }
  #spielfeld .group-2572 {
    grid-column: 9 / 10; }
  #spielfeld .group-2573 {
    grid-column: 10 / 11; }
  #spielfeld .group-2574 {
    grid-column: 11 / 12; }
  #spielfeld .group-2575 {
    grid-column: 12 / 13; }
  #spielfeld .group-2576 {
    grid-column: 13 / 14; }
  #spielfeld .group-2577 {
    grid-column: 14 / 15; }
  #spielfeld .group-2578 {
    grid-column: 15 / 16; }
  #spielfeld .group-2579 {
    grid-column: 16 / 17; }
  #spielfeld .group-2580 {
    grid-column: 17 / 18; }
  #spielfeld .group-2581 {
    grid-column: 18 / 19; }
  #spielfeld .group-2582 {
    grid-column: 19 / 20; }
  #spielfeld .group-2583 {
    grid-column: 20 / 21; }
  #spielfeld .group-2584 {
    grid-column: 2 / 3; }
  #spielfeld .group-2585 {
    grid-column: 3 / 4; }
  #spielfeld .group-2586 {
    grid-column: 4 / 5; }
  #spielfeld .group-2587 {
    grid-column: 5 / 6; }
  #spielfeld .group-2588 {
    grid-column: 6 / 7; }
  #spielfeld .group-2589 {
    grid-column: 7 / 8; }
  #spielfeld .group-2590 {
    grid-column: 8 / 9; }
  #spielfeld .group-2591 {
    grid-column: 9 / 10; }
  #spielfeld .group-2592 {
    grid-column: 10 / 11; }
  #spielfeld .group-2593 {
    grid-column: 11 / 12; }
  #spielfeld .group-2594 {
    grid-column: 12 / 13; }
  #spielfeld .group-2595 {
    grid-column: 13 / 14; }
  #spielfeld .group-2596 {
    grid-column: 14 / 15; }
  #spielfeld .group-2597 {
    grid-column: 15 / 16; }
  #spielfeld .group-2598 {
    grid-column: 16 / 17; }
  #spielfeld .group-2599 {
    grid-column: 17 / 18; }
  #spielfeld .group-2600 {
    grid-column: 18 / 19; }
  #spielfeld .group-2601 {
    grid-column: 19 / 20; }
  #spielfeld .group-2602 {
    grid-column: 20 / 21; }
  #spielfeld .group-2603 {
    grid-column: 2 / 3; }
  #spielfeld .group-2604 {
    grid-column: 3 / 4; }
  #spielfeld .group-2605 {
    grid-column: 4 / 5; }
  #spielfeld .group-2606 {
    grid-column: 5 / 6; }
  #spielfeld .group-2607 {
    grid-column: 6 / 7; }
  #spielfeld .group-2608 {
    grid-column: 7 / 8; }
  #spielfeld .group-2609 {
    grid-column: 8 / 9; }
  #spielfeld .group-2610 {
    grid-column: 9 / 10; }
  #spielfeld .group-2611 {
    grid-column: 10 / 11; }
  #spielfeld .group-2612 {
    grid-column: 11 / 12; }
  #spielfeld .group-2613 {
    grid-column: 12 / 13; }
  #spielfeld .group-2614 {
    grid-column: 13 / 14; }
  #spielfeld .group-2615 {
    grid-column: 14 / 15; }
  #spielfeld .group-2616 {
    grid-column: 15 / 16; }
  #spielfeld .group-2617 {
    grid-column: 16 / 17; }
  #spielfeld .group-2618 {
    grid-column: 17 / 18; }
  #spielfeld .group-2619 {
    grid-column: 18 / 19; }
  #spielfeld .group-2620 {
    grid-column: 19 / 20; }
  #spielfeld .group-2621 {
    grid-column: 20 / 21; }
  #spielfeld .group-2622 {
    grid-column: 2 / 3; }
  #spielfeld .group-2623 {
    grid-column: 3 / 4; }
  #spielfeld .group-2624 {
    grid-column: 4 / 5; }
  #spielfeld .group-2625 {
    grid-column: 5 / 6; }
  #spielfeld .group-2626 {
    grid-column: 6 / 7; }
  #spielfeld .group-2627 {
    grid-column: 7 / 8; }
  #spielfeld .group-2628 {
    grid-column: 8 / 9; }
  #spielfeld .group-2629 {
    grid-column: 9 / 10; }
  #spielfeld .group-2630 {
    grid-column: 10 / 11; }
  #spielfeld .group-2631 {
    grid-column: 11 / 12; }
  #spielfeld .group-2632 {
    grid-column: 12 / 13; }
  #spielfeld .group-2633 {
    grid-column: 13 / 14; }
  #spielfeld .group-2634 {
    grid-column: 14 / 15; }
  #spielfeld .group-2635 {
    grid-column: 15 / 16; }
  #spielfeld .group-2636 {
    grid-column: 16 / 17; }
  #spielfeld .group-2637 {
    grid-column: 17 / 18; }
  #spielfeld .group-2638 {
    grid-column: 18 / 19; }
  #spielfeld .group-2639 {
    grid-column: 19 / 20; }
  #spielfeld .group-2640 {
    grid-column: 20 / 21; }
  #spielfeld .group-2641 {
    grid-column: 2 / 3; }
  #spielfeld .group-2642 {
    grid-column: 3 / 4; }
  #spielfeld .group-2643 {
    grid-column: 4 / 5; }
  #spielfeld .group-2644 {
    grid-column: 5 / 6; }
  #spielfeld .group-2645 {
    grid-column: 6 / 7; }
  #spielfeld .group-2646 {
    grid-column: 7 / 8; }
  #spielfeld .group-2647 {
    grid-column: 8 / 9; }
  #spielfeld .group-2648 {
    grid-column: 9 / 10; }
  #spielfeld .group-2649 {
    grid-column: 10 / 11; }
  #spielfeld .group-2650 {
    grid-column: 11 / 12; }
  #spielfeld .group-2651 {
    grid-column: 12 / 13; }
  #spielfeld .group-2652 {
    grid-column: 13 / 14; }
  #spielfeld .group-2653 {
    grid-column: 14 / 15; }
  #spielfeld .group-2654 {
    grid-column: 15 / 16; }
  #spielfeld .group-2655 {
    grid-column: 16 / 17; }
  #spielfeld .group-2656 {
    grid-column: 17 / 18; }
  #spielfeld .group-2657 {
    grid-column: 18 / 19; }
  #spielfeld .group-2658 {
    grid-column: 19 / 20; }
  #spielfeld .group-2659 {
    grid-column: 20 / 21; }
  #spielfeld .group-2660 {
    grid-column: 2 / 3; }
  #spielfeld .group-2661 {
    grid-column: 3 / 4; }
  #spielfeld .group-2662 {
    grid-column: 4 / 5; }
  #spielfeld .group-2663 {
    grid-column: 5 / 6; }
  #spielfeld .group-2664 {
    grid-column: 6 / 7; }
  #spielfeld .group-2665 {
    grid-column: 7 / 8; }
  #spielfeld .group-2666 {
    grid-column: 8 / 9; }
  #spielfeld .group-2667 {
    grid-column: 9 / 10; }
  #spielfeld .group-2668 {
    grid-column: 10 / 11; }
  #spielfeld .group-2669 {
    grid-column: 11 / 12; }
  #spielfeld .group-2670 {
    grid-column: 12 / 13; }
  #spielfeld .group-2671 {
    grid-column: 13 / 14; }
  #spielfeld .group-2672 {
    grid-column: 14 / 15; }
  #spielfeld .group-2673 {
    grid-column: 15 / 16; }
  #spielfeld .group-2674 {
    grid-column: 16 / 17; }
  #spielfeld .group-2675 {
    grid-column: 17 / 18; }
  #spielfeld .group-2676 {
    grid-column: 18 / 19; }
  #spielfeld .group-2677 {
    grid-column: 19 / 20; }
  #spielfeld .group-2678 {
    grid-column: 20 / 21; }
  #spielfeld .group-2679 {
    grid-column: 2 / 3; }
  #spielfeld .group-2680 {
    grid-column: 3 / 4; }
  #spielfeld .group-2681 {
    grid-column: 4 / 5; }
  #spielfeld .group-2682 {
    grid-column: 5 / 6; }
  #spielfeld .group-2683 {
    grid-column: 6 / 7; }
  #spielfeld .group-2684 {
    grid-column: 7 / 8; }
  #spielfeld .group-2685 {
    grid-column: 8 / 9; }
  #spielfeld .group-2686 {
    grid-column: 9 / 10; }
  #spielfeld .group-2687 {
    grid-column: 10 / 11; }
  #spielfeld .group-2688 {
    grid-column: 11 / 12; }
  #spielfeld .group-2689 {
    grid-column: 12 / 13; }
  #spielfeld .group-2690 {
    grid-column: 13 / 14; }
  #spielfeld .group-2691 {
    grid-column: 14 / 15; }
  #spielfeld .group-2692 {
    grid-column: 15 / 16; }
  #spielfeld .group-2693 {
    grid-column: 16 / 17; }
  #spielfeld .group-2694 {
    grid-column: 17 / 18; }
  #spielfeld .group-2695 {
    grid-column: 18 / 19; }
  #spielfeld .group-2696 {
    grid-column: 19 / 20; }
  #spielfeld .group-2697 {
    grid-column: 20 / 21; }
  #spielfeld .group-2698 {
    grid-column: 2 / 3; }
  #spielfeld .group-2699 {
    grid-column: 3 / 4; }
  #spielfeld .group-2700 {
    grid-column: 4 / 5; }
  #spielfeld .group-2701 {
    grid-column: 5 / 6; }
  #spielfeld .group-2702 {
    grid-column: 6 / 7; }
  #spielfeld .group-2703 {
    grid-column: 7 / 8; }
  #spielfeld .group-2704 {
    grid-column: 8 / 9; }
  #spielfeld .group-2705 {
    grid-column: 9 / 10; }
  #spielfeld .group-2706 {
    grid-column: 10 / 11; }
  #spielfeld .group-2707 {
    grid-column: 11 / 12; }
  #spielfeld .group-2708 {
    grid-column: 12 / 13; }
  #spielfeld .group-2709 {
    grid-column: 13 / 14; }
  #spielfeld .group-2710 {
    grid-column: 14 / 15; }
  #spielfeld .group-2711 {
    grid-column: 15 / 16; }
  #spielfeld .group-2712 {
    grid-column: 16 / 17; }
  #spielfeld .group-2713 {
    grid-column: 17 / 18; }
  #spielfeld .group-2714 {
    grid-column: 18 / 19; }
  #spielfeld .group-2715 {
    grid-column: 19 / 20; }
  #spielfeld .group-2716 {
    grid-column: 20 / 21; }
  #spielfeld .group-2717 {
    grid-column: 2 / 3; }
  #spielfeld .group-2718 {
    grid-column: 3 / 4; }
  #spielfeld .group-2719 {
    grid-column: 4 / 5; }
  #spielfeld .group-2720 {
    grid-column: 5 / 6; }
  #spielfeld .group-2721 {
    grid-column: 6 / 7; }
  #spielfeld .group-2722 {
    grid-column: 7 / 8; }
  #spielfeld .group-2723 {
    grid-column: 8 / 9; }
  #spielfeld .group-2724 {
    grid-column: 9 / 10; }
  #spielfeld .group-2725 {
    grid-column: 10 / 11; }
  #spielfeld .group-2726 {
    grid-column: 11 / 12; }
  #spielfeld .group-2727 {
    grid-column: 12 / 13; }
  #spielfeld .group-2728 {
    grid-column: 13 / 14; }
  #spielfeld .group-2729 {
    grid-column: 14 / 15; }
  #spielfeld .group-2730 {
    grid-column: 15 / 16; }
  #spielfeld .group-2731 {
    grid-column: 16 / 17; }
  #spielfeld .group-2732 {
    grid-column: 17 / 18; }
  #spielfeld .group-2733 {
    grid-column: 18 / 19; }
  #spielfeld .group-2734 {
    grid-column: 19 / 20; }
  #spielfeld .group-2735 {
    grid-column: 20 / 21; }
  #spielfeld .group-2736 {
    grid-column: 2 / 3; }
  #spielfeld .group-2737 {
    grid-column: 3 / 4; }
  #spielfeld .group-2738 {
    grid-column: 4 / 5; }
  #spielfeld .group-2739 {
    grid-column: 5 / 6; }
  #spielfeld .group-2740 {
    grid-column: 6 / 7; }
  #spielfeld .group-2741 {
    grid-column: 7 / 8; }
  #spielfeld .group-2742 {
    grid-column: 8 / 9; }
  #spielfeld .group-2743 {
    grid-column: 9 / 10; }
  #spielfeld .group-2744 {
    grid-column: 10 / 11; }
  #spielfeld .group-2745 {
    grid-column: 11 / 12; }
  #spielfeld .group-2746 {
    grid-column: 12 / 13; }
  #spielfeld .group-2747 {
    grid-column: 13 / 14; }
  #spielfeld .group-2748 {
    grid-column: 14 / 15; }
  #spielfeld .group-2749 {
    grid-column: 15 / 16; }
  #spielfeld .group-2750 {
    grid-column: 16 / 17; }
  #spielfeld .group-2751 {
    grid-column: 17 / 18; }
  #spielfeld .group-2752 {
    grid-column: 18 / 19; }
  #spielfeld .group-2753 {
    grid-column: 19 / 20; }
  #spielfeld .group-2754 {
    grid-column: 20 / 21; }
  #spielfeld .group-2755 {
    grid-column: 2 / 3; }
  #spielfeld .group-2756 {
    grid-column: 3 / 4; }
  #spielfeld .group-2757 {
    grid-column: 4 / 5; }
  #spielfeld .group-2758 {
    grid-column: 5 / 6; }
  #spielfeld .group-2759 {
    grid-column: 6 / 7; }
  #spielfeld .group-2760 {
    grid-column: 7 / 8; }
  #spielfeld .group-2761 {
    grid-column: 8 / 9; }
  #spielfeld .group-2762 {
    grid-column: 9 / 10; }
  #spielfeld .group-2763 {
    grid-column: 10 / 11; }
  #spielfeld .group-2764 {
    grid-column: 11 / 12; }
  #spielfeld .group-2765 {
    grid-column: 12 / 13; }
  #spielfeld .group-2766 {
    grid-column: 13 / 14; }
  #spielfeld .group-2767 {
    grid-column: 14 / 15; }
  #spielfeld .group-2768 {
    grid-column: 15 / 16; }
  #spielfeld .group-2769 {
    grid-column: 16 / 17; }
  #spielfeld .group-2770 {
    grid-column: 17 / 18; }
  #spielfeld .group-2771 {
    grid-column: 18 / 19; }
  #spielfeld .group-2772 {
    grid-column: 19 / 20; }
  #spielfeld .group-2773 {
    grid-column: 20 / 21; }
  #spielfeld .group-2774 {
    grid-column: 2 / 3; }
  #spielfeld .group-2775 {
    grid-column: 3 / 4; }
  #spielfeld .group-2776 {
    grid-column: 4 / 5; }
  #spielfeld .group-2777 {
    grid-column: 5 / 6; }
  #spielfeld .group-2778 {
    grid-column: 6 / 7; }
  #spielfeld .group-2779 {
    grid-column: 7 / 8; }
  #spielfeld .group-2780 {
    grid-column: 8 / 9; }
  #spielfeld .group-2781 {
    grid-column: 9 / 10; }
  #spielfeld .group-2782 {
    grid-column: 10 / 11; }
  #spielfeld .group-2783 {
    grid-column: 11 / 12; }
  #spielfeld .group-2784 {
    grid-column: 12 / 13; }
  #spielfeld .group-2785 {
    grid-column: 13 / 14; }
  #spielfeld .group-2786 {
    grid-column: 14 / 15; }
  #spielfeld .group-2787 {
    grid-column: 15 / 16; }
  #spielfeld .group-2788 {
    grid-column: 16 / 17; }
  #spielfeld .group-2789 {
    grid-column: 17 / 18; }
  #spielfeld .group-2790 {
    grid-column: 18 / 19; }
  #spielfeld .group-2791 {
    grid-column: 19 / 20; }
  #spielfeld .group-2792 {
    grid-column: 20 / 21; }
  #spielfeld .group-2793 {
    grid-column: 2 / 3; }
  #spielfeld .group-2794 {
    grid-column: 3 / 4; }
  #spielfeld .group-2795 {
    grid-column: 4 / 5; }
  #spielfeld .group-2796 {
    grid-column: 5 / 6; }
  #spielfeld .group-2797 {
    grid-column: 6 / 7; }
  #spielfeld .group-2798 {
    grid-column: 7 / 8; }
  #spielfeld .group-2799 {
    grid-column: 8 / 9; }
  #spielfeld .group-2800 {
    grid-column: 9 / 10; }
  #spielfeld .group-2801 {
    grid-column: 10 / 11; }
  #spielfeld .group-2802 {
    grid-column: 11 / 12; }
  #spielfeld .group-2803 {
    grid-column: 12 / 13; }
  #spielfeld .group-2804 {
    grid-column: 13 / 14; }
  #spielfeld .group-2805 {
    grid-column: 14 / 15; }
  #spielfeld .group-2806 {
    grid-column: 15 / 16; }
  #spielfeld .group-2807 {
    grid-column: 16 / 17; }
  #spielfeld .group-2808 {
    grid-column: 17 / 18; }
  #spielfeld .group-2809 {
    grid-column: 18 / 19; }
  #spielfeld .group-2810 {
    grid-column: 19 / 20; }
  #spielfeld .group-2811 {
    grid-column: 20 / 21; }
  #spielfeld .group-2812 {
    grid-column: 2 / 3; }
  #spielfeld .group-2813 {
    grid-column: 3 / 4; }
  #spielfeld .group-2814 {
    grid-column: 4 / 5; }
  #spielfeld .group-2815 {
    grid-column: 5 / 6; }
  #spielfeld .group-2816 {
    grid-column: 6 / 7; }
  #spielfeld .group-2817 {
    grid-column: 7 / 8; }
  #spielfeld .group-2818 {
    grid-column: 8 / 9; }
  #spielfeld .group-2819 {
    grid-column: 9 / 10; }
  #spielfeld .group-2820 {
    grid-column: 10 / 11; }
  #spielfeld .group-2821 {
    grid-column: 11 / 12; }
  #spielfeld .group-2822 {
    grid-column: 12 / 13; }
  #spielfeld .group-2823 {
    grid-column: 13 / 14; }
  #spielfeld .group-2824 {
    grid-column: 14 / 15; }
  #spielfeld .group-2825 {
    grid-column: 15 / 16; }
  #spielfeld .group-2826 {
    grid-column: 16 / 17; }
  #spielfeld .group-2827 {
    grid-column: 17 / 18; }
  #spielfeld .group-2828 {
    grid-column: 18 / 19; }
  #spielfeld .group-2829 {
    grid-column: 19 / 20; }
  #spielfeld .group-2830 {
    grid-column: 20 / 21; }
  #spielfeld .group-2831 {
    grid-column: 2 / 3; }
  #spielfeld .group-2832 {
    grid-column: 3 / 4; }
  #spielfeld .group-2833 {
    grid-column: 4 / 5; }
  #spielfeld .group-2834 {
    grid-column: 5 / 6; }
  #spielfeld .group-2835 {
    grid-column: 6 / 7; }
  #spielfeld .group-2836 {
    grid-column: 7 / 8; }
  #spielfeld .group-2837 {
    grid-column: 8 / 9; }
  #spielfeld .group-2838 {
    grid-column: 9 / 10; }
  #spielfeld .group-2839 {
    grid-column: 10 / 11; }
  #spielfeld .group-2840 {
    grid-column: 11 / 12; }
  #spielfeld .group-2841 {
    grid-column: 12 / 13; }
  #spielfeld .group-2842 {
    grid-column: 13 / 14; }
  #spielfeld .group-2843 {
    grid-column: 14 / 15; }
  #spielfeld .group-2844 {
    grid-column: 15 / 16; }
  #spielfeld .group-2845 {
    grid-column: 16 / 17; }
  #spielfeld .group-2846 {
    grid-column: 17 / 18; }
  #spielfeld .group-2847 {
    grid-column: 18 / 19; }
  #spielfeld .group-2848 {
    grid-column: 19 / 20; }
  #spielfeld .group-2849 {
    grid-column: 20 / 21; }
  #spielfeld .group-2850 {
    grid-column: 2 / 3; }
  #spielfeld .group-2851 {
    grid-column: 3 / 4; }
  #spielfeld .group-2852 {
    grid-column: 4 / 5; }
  #spielfeld .group-2853 {
    grid-column: 5 / 6; }
  #spielfeld .group-2854 {
    grid-column: 6 / 7; }
  #spielfeld .group-2855 {
    grid-column: 7 / 8; }
  #spielfeld .group-2856 {
    grid-column: 8 / 9; }
  #spielfeld .group-2857 {
    grid-column: 9 / 10; }
  #spielfeld .group-2858 {
    grid-column: 10 / 11; }
  #spielfeld .group-2859 {
    grid-column: 11 / 12; }
  #spielfeld .group-2860 {
    grid-column: 12 / 13; }
  #spielfeld .group-2861 {
    grid-column: 13 / 14; }
  #spielfeld .group-2862 {
    grid-column: 14 / 15; }
  #spielfeld .group-2863 {
    grid-column: 15 / 16; }
  #spielfeld .group-2864 {
    grid-column: 16 / 17; }
  #spielfeld .group-2865 {
    grid-column: 17 / 18; }
  #spielfeld .group-2866 {
    grid-column: 18 / 19; }
  #spielfeld .group-2867 {
    grid-column: 19 / 20; }
  #spielfeld .group-2868 {
    grid-column: 20 / 21; }
  #spielfeld .group-2869 {
    grid-column: 2 / 3; }
  #spielfeld .group-2870 {
    grid-column: 3 / 4; }
  #spielfeld .group-2871 {
    grid-column: 4 / 5; }
  #spielfeld .group-2872 {
    grid-column: 5 / 6; }
  #spielfeld .group-2873 {
    grid-column: 6 / 7; }
  #spielfeld .group-2874 {
    grid-column: 7 / 8; }
  #spielfeld .group-2875 {
    grid-column: 8 / 9; }
  #spielfeld .group-2876 {
    grid-column: 9 / 10; }
  #spielfeld .group-2877 {
    grid-column: 10 / 11; }
  #spielfeld .group-2878 {
    grid-column: 11 / 12; }
  #spielfeld .group-2879 {
    grid-column: 12 / 13; }
  #spielfeld .group-2880 {
    grid-column: 13 / 14; }
  #spielfeld .group-2881 {
    grid-column: 14 / 15; }
  #spielfeld .group-2882 {
    grid-column: 15 / 16; }
  #spielfeld .group-2883 {
    grid-column: 16 / 17; }
  #spielfeld .group-2884 {
    grid-column: 17 / 18; }
  #spielfeld .group-2885 {
    grid-column: 18 / 19; }
  #spielfeld .group-2886 {
    grid-column: 19 / 20; }
  #spielfeld .group-2887 {
    grid-column: 20 / 21; }
  #spielfeld .group-2888 {
    grid-column: 2 / 3; }
  #spielfeld .group-2889 {
    grid-column: 3 / 4; }
  #spielfeld .group-2890 {
    grid-column: 4 / 5; }
  #spielfeld .group-2891 {
    grid-column: 5 / 6; }
  #spielfeld .group-2892 {
    grid-column: 6 / 7; }
  #spielfeld .group-2893 {
    grid-column: 7 / 8; }
  #spielfeld .group-2894 {
    grid-column: 8 / 9; }
  #spielfeld .group-2895 {
    grid-column: 9 / 10; }
  #spielfeld .group-2896 {
    grid-column: 10 / 11; }
  #spielfeld .group-2897 {
    grid-column: 11 / 12; }
  #spielfeld .group-2898 {
    grid-column: 12 / 13; }
  #spielfeld .group-2899 {
    grid-column: 13 / 14; }
  #spielfeld .group-2900 {
    grid-column: 14 / 15; }
  #spielfeld .group-2901 {
    grid-column: 15 / 16; }
  #spielfeld .group-2902 {
    grid-column: 16 / 17; }
  #spielfeld .group-2903 {
    grid-column: 17 / 18; }
  #spielfeld .group-2904 {
    grid-column: 18 / 19; }
  #spielfeld .group-2905 {
    grid-column: 19 / 20; }
  #spielfeld .group-2906 {
    grid-column: 20 / 21; }
  #spielfeld .group-2907 {
    grid-column: 2 / 3; }
  #spielfeld .group-2908 {
    grid-column: 3 / 4; }
  #spielfeld .group-2909 {
    grid-column: 4 / 5; }
  #spielfeld .group-2910 {
    grid-column: 5 / 6; }
  #spielfeld .group-2911 {
    grid-column: 6 / 7; }
  #spielfeld .group-2912 {
    grid-column: 7 / 8; }
  #spielfeld .group-2913 {
    grid-column: 8 / 9; }
  #spielfeld .group-2914 {
    grid-column: 9 / 10; }
  #spielfeld .group-2915 {
    grid-column: 10 / 11; }
  #spielfeld .group-2916 {
    grid-column: 11 / 12; }
  #spielfeld .group-2917 {
    grid-column: 12 / 13; }
  #spielfeld .group-2918 {
    grid-column: 13 / 14; }
  #spielfeld .group-2919 {
    grid-column: 14 / 15; }
  #spielfeld .group-2920 {
    grid-column: 15 / 16; }
  #spielfeld .group-2921 {
    grid-column: 16 / 17; }
  #spielfeld .group-2922 {
    grid-column: 17 / 18; }
  #spielfeld .group-2923 {
    grid-column: 18 / 19; }
  #spielfeld .group-2924 {
    grid-column: 19 / 20; }
  #spielfeld .group-2925 {
    grid-column: 20 / 21; }
  #spielfeld .group-2926 {
    grid-column: 2 / 3; }
  #spielfeld .group-2927 {
    grid-column: 3 / 4; }
  #spielfeld .group-2928 {
    grid-column: 4 / 5; }
  #spielfeld .group-2929 {
    grid-column: 5 / 6; }
  #spielfeld .group-2930 {
    grid-column: 6 / 7; }
  #spielfeld .group-2931 {
    grid-column: 7 / 8; }
  #spielfeld .group-2932 {
    grid-column: 8 / 9; }
  #spielfeld .group-2933 {
    grid-column: 9 / 10; }
  #spielfeld .group-2934 {
    grid-column: 10 / 11; }
  #spielfeld .group-2935 {
    grid-column: 11 / 12; }
  #spielfeld .group-2936 {
    grid-column: 12 / 13; }
  #spielfeld .group-2937 {
    grid-column: 13 / 14; }
  #spielfeld .group-2938 {
    grid-column: 14 / 15; }
  #spielfeld .group-2939 {
    grid-column: 15 / 16; }
  #spielfeld .group-2940 {
    grid-column: 16 / 17; }
  #spielfeld .group-2941 {
    grid-column: 17 / 18; }
  #spielfeld .group-2942 {
    grid-column: 18 / 19; }
  #spielfeld .group-2943 {
    grid-column: 19 / 20; }
  #spielfeld .group-2944 {
    grid-column: 20 / 21; }
  #spielfeld .group-2945 {
    grid-column: 2 / 3; }
  #spielfeld .group-2946 {
    grid-column: 3 / 4; }
  #spielfeld .group-2947 {
    grid-column: 4 / 5; }
  #spielfeld .group-2948 {
    grid-column: 5 / 6; }
  #spielfeld .group-2949 {
    grid-column: 6 / 7; }
  #spielfeld .group-2950 {
    grid-column: 7 / 8; }
  #spielfeld .group-2951 {
    grid-column: 8 / 9; }
  #spielfeld .group-2952 {
    grid-column: 9 / 10; }
  #spielfeld .group-2953 {
    grid-column: 10 / 11; }
  #spielfeld .group-2954 {
    grid-column: 11 / 12; }
  #spielfeld .group-2955 {
    grid-column: 12 / 13; }
  #spielfeld .group-2956 {
    grid-column: 13 / 14; }
  #spielfeld .group-2957 {
    grid-column: 14 / 15; }
  #spielfeld .group-2958 {
    grid-column: 15 / 16; }
  #spielfeld .group-2959 {
    grid-column: 16 / 17; }
  #spielfeld .group-2960 {
    grid-column: 17 / 18; }
  #spielfeld .group-2961 {
    grid-column: 18 / 19; }
  #spielfeld .group-2962 {
    grid-column: 19 / 20; }
  #spielfeld .group-2963 {
    grid-column: 20 / 21; }
  #spielfeld .group-2964 {
    grid-column: 2 / 3; }
  #spielfeld .group-2965 {
    grid-column: 3 / 4; }
  #spielfeld .group-2966 {
    grid-column: 4 / 5; }
  #spielfeld .group-2967 {
    grid-column: 5 / 6; }
  #spielfeld .group-2968 {
    grid-column: 6 / 7; }
  #spielfeld .group-2969 {
    grid-column: 7 / 8; }
  #spielfeld .group-2970 {
    grid-column: 8 / 9; }
  #spielfeld .group-2971 {
    grid-column: 9 / 10; }
  #spielfeld .group-2972 {
    grid-column: 10 / 11; }
  #spielfeld .group-2973 {
    grid-column: 11 / 12; }
  #spielfeld .group-2974 {
    grid-column: 12 / 13; }
  #spielfeld .group-2975 {
    grid-column: 13 / 14; }
  #spielfeld .group-2976 {
    grid-column: 14 / 15; }
  #spielfeld .group-2977 {
    grid-column: 15 / 16; }
  #spielfeld .group-2978 {
    grid-column: 16 / 17; }
  #spielfeld .group-2979 {
    grid-column: 17 / 18; }
  #spielfeld .group-2980 {
    grid-column: 18 / 19; }
  #spielfeld .group-2981 {
    grid-column: 19 / 20; }
  #spielfeld .group-2982 {
    grid-column: 20 / 21; }
  #spielfeld .group-2983 {
    grid-column: 2 / 3; }
  #spielfeld .group-2984 {
    grid-column: 3 / 4; }
  #spielfeld .group-2985 {
    grid-column: 4 / 5; }
  #spielfeld .group-2986 {
    grid-column: 5 / 6; }
  #spielfeld .group-2987 {
    grid-column: 6 / 7; }
  #spielfeld .group-2988 {
    grid-column: 7 / 8; }
  #spielfeld .group-2989 {
    grid-column: 8 / 9; }
  #spielfeld .group-2990 {
    grid-column: 9 / 10; }
  #spielfeld .group-2991 {
    grid-column: 10 / 11; }
  #spielfeld .group-2992 {
    grid-column: 11 / 12; }
  #spielfeld .group-2993 {
    grid-column: 12 / 13; }
  #spielfeld .group-2994 {
    grid-column: 13 / 14; }
  #spielfeld .group-2995 {
    grid-column: 14 / 15; }
  #spielfeld .group-2996 {
    grid-column: 15 / 16; }
  #spielfeld .group-2997 {
    grid-column: 16 / 17; }
  #spielfeld .group-2998 {
    grid-column: 17 / 18; }
  #spielfeld .group-2999 {
    grid-column: 18 / 19; }
  #spielfeld .group-3000 {
    grid-column: 19 / 20; }
  #spielfeld .group-3001 {
    grid-column: 20 / 21; }
  #spielfeld .group-3002 {
    grid-column: 2 / 3; }
  #spielfeld .group-3003 {
    grid-column: 3 / 4; }
  #spielfeld .group-3004 {
    grid-column: 4 / 5; }
  #spielfeld .group-3005 {
    grid-column: 5 / 6; }
  #spielfeld .group-3006 {
    grid-column: 6 / 7; }
  #spielfeld .group-3007 {
    grid-column: 7 / 8; }
  #spielfeld .group-3008 {
    grid-column: 8 / 9; }
  #spielfeld .group-3009 {
    grid-column: 9 / 10; }
  #spielfeld .group-3010 {
    grid-column: 10 / 11; }
  #spielfeld .group-3011 {
    grid-column: 11 / 12; }
  #spielfeld .group-3012 {
    grid-column: 12 / 13; }
  #spielfeld .group-3013 {
    grid-column: 13 / 14; }
  #spielfeld .group-3014 {
    grid-column: 14 / 15; }
  #spielfeld .group-3015 {
    grid-column: 15 / 16; }
  #spielfeld .group-3016 {
    grid-column: 16 / 17; }
  #spielfeld .group-3017 {
    grid-column: 17 / 18; }
  #spielfeld .group-3018 {
    grid-column: 18 / 19; }
  #spielfeld .group-3019 {
    grid-column: 19 / 20; }
  #spielfeld .group-3020 {
    grid-column: 20 / 21; }
  #spielfeld .group-3021 {
    grid-column: 2 / 3; }
  #spielfeld .group-3022 {
    grid-column: 3 / 4; }
  #spielfeld .group-3023 {
    grid-column: 4 / 5; }
  #spielfeld .group-3024 {
    grid-column: 5 / 6; }
  #spielfeld .group-3025 {
    grid-column: 6 / 7; }
  #spielfeld .group-3026 {
    grid-column: 7 / 8; }
  #spielfeld .group-3027 {
    grid-column: 8 / 9; }
  #spielfeld .group-3028 {
    grid-column: 9 / 10; }
  #spielfeld .group-3029 {
    grid-column: 10 / 11; }
  #spielfeld .group-3030 {
    grid-column: 11 / 12; }
  #spielfeld .group-3031 {
    grid-column: 12 / 13; }
  #spielfeld .group-3032 {
    grid-column: 13 / 14; }
  #spielfeld .group-3033 {
    grid-column: 14 / 15; }
  #spielfeld .group-3034 {
    grid-column: 15 / 16; }
  #spielfeld .group-3035 {
    grid-column: 16 / 17; }
  #spielfeld .group-3036 {
    grid-column: 17 / 18; }
  #spielfeld .group-3037 {
    grid-column: 18 / 19; }
  #spielfeld .group-3038 {
    grid-column: 19 / 20; }
  #spielfeld .group-3039 {
    grid-column: 20 / 21; }
  #spielfeld .group-3040 {
    grid-column: 2 / 3; }
  #spielfeld .group-3041 {
    grid-column: 3 / 4; }
  #spielfeld .group-3042 {
    grid-column: 4 / 5; }
  #spielfeld .group-3043 {
    grid-column: 5 / 6; }
  #spielfeld .group-3044 {
    grid-column: 6 / 7; }
  #spielfeld .group-3045 {
    grid-column: 7 / 8; }
  #spielfeld .group-3046 {
    grid-column: 8 / 9; }
  #spielfeld .group-3047 {
    grid-column: 9 / 10; }
  #spielfeld .group-3048 {
    grid-column: 10 / 11; }
  #spielfeld .group-3049 {
    grid-column: 11 / 12; }
  #spielfeld .group-3050 {
    grid-column: 12 / 13; }
  #spielfeld .group-3051 {
    grid-column: 13 / 14; }
  #spielfeld .group-3052 {
    grid-column: 14 / 15; }
  #spielfeld .group-3053 {
    grid-column: 15 / 16; }
  #spielfeld .group-3054 {
    grid-column: 16 / 17; }
  #spielfeld .group-3055 {
    grid-column: 17 / 18; }
  #spielfeld .group-3056 {
    grid-column: 18 / 19; }
  #spielfeld .group-3057 {
    grid-column: 19 / 20; }
  #spielfeld .group-3058 {
    grid-column: 20 / 21; }
  #spielfeld .group-3059 {
    grid-column: 2 / 3; }
  #spielfeld .group-3060 {
    grid-column: 3 / 4; }
  #spielfeld .group-3061 {
    grid-column: 4 / 5; }
  #spielfeld .group-3062 {
    grid-column: 5 / 6; }
  #spielfeld .group-3063 {
    grid-column: 6 / 7; }
  #spielfeld .group-3064 {
    grid-column: 7 / 8; }
  #spielfeld .group-3065 {
    grid-column: 8 / 9; }
  #spielfeld .group-3066 {
    grid-column: 9 / 10; }
  #spielfeld .group-3067 {
    grid-column: 10 / 11; }
  #spielfeld .group-3068 {
    grid-column: 11 / 12; }
  #spielfeld .group-3069 {
    grid-column: 12 / 13; }
  #spielfeld .group-3070 {
    grid-column: 13 / 14; }
  #spielfeld .group-3071 {
    grid-column: 14 / 15; }
  #spielfeld .group-3072 {
    grid-column: 15 / 16; }
  #spielfeld .group-3073 {
    grid-column: 16 / 17; }
  #spielfeld .group-3074 {
    grid-column: 17 / 18; }
  #spielfeld .group-3075 {
    grid-column: 18 / 19; }
  #spielfeld .group-3076 {
    grid-column: 19 / 20; }
  #spielfeld .group-3077 {
    grid-column: 20 / 21; }
  #spielfeld .group-3078 {
    grid-column: 2 / 3; }
  #spielfeld .group-3079 {
    grid-column: 3 / 4; }
  #spielfeld .group-3080 {
    grid-column: 4 / 5; }
  #spielfeld .group-3081 {
    grid-column: 5 / 6; }
  #spielfeld .group-3082 {
    grid-column: 6 / 7; }
  #spielfeld .group-3083 {
    grid-column: 7 / 8; }
  #spielfeld .group-3084 {
    grid-column: 8 / 9; }
  #spielfeld .group-3085 {
    grid-column: 9 / 10; }
  #spielfeld .group-3086 {
    grid-column: 10 / 11; }
  #spielfeld .group-3087 {
    grid-column: 11 / 12; }
  #spielfeld .group-3088 {
    grid-column: 12 / 13; }
  #spielfeld .group-3089 {
    grid-column: 13 / 14; }
  #spielfeld .group-3090 {
    grid-column: 14 / 15; }
  #spielfeld .group-3091 {
    grid-column: 15 / 16; }
  #spielfeld .group-3092 {
    grid-column: 16 / 17; }
  #spielfeld .group-3093 {
    grid-column: 17 / 18; }
  #spielfeld .group-3094 {
    grid-column: 18 / 19; }
  #spielfeld .group-3095 {
    grid-column: 19 / 20; }
  #spielfeld .group-3096 {
    grid-column: 20 / 21; }
  #spielfeld .group-3097 {
    grid-column: 2 / 3; }
  #spielfeld .group-3098 {
    grid-column: 3 / 4; }
  #spielfeld .group-3099 {
    grid-column: 4 / 5; }
  #spielfeld .group-3100 {
    grid-column: 5 / 6; }
  #spielfeld .group-3101 {
    grid-column: 6 / 7; }
  #spielfeld .group-3102 {
    grid-column: 7 / 8; }
  #spielfeld .group-3103 {
    grid-column: 8 / 9; }
  #spielfeld .group-3104 {
    grid-column: 9 / 10; }
  #spielfeld .group-3105 {
    grid-column: 10 / 11; }
  #spielfeld .group-3106 {
    grid-column: 11 / 12; }
  #spielfeld .group-3107 {
    grid-column: 12 / 13; }
  #spielfeld .group-3108 {
    grid-column: 13 / 14; }
  #spielfeld .group-3109 {
    grid-column: 14 / 15; }
  #spielfeld .group-3110 {
    grid-column: 15 / 16; }
  #spielfeld .group-3111 {
    grid-column: 16 / 17; }
  #spielfeld .group-3112 {
    grid-column: 17 / 18; }
  #spielfeld .group-3113 {
    grid-column: 18 / 19; }
  #spielfeld .group-3114 {
    grid-column: 19 / 20; }
  #spielfeld .group-3115 {
    grid-column: 20 / 21; }
  #spielfeld .group-3116 {
    grid-column: 2 / 3; }
  #spielfeld .group-3117 {
    grid-column: 3 / 4; }
  #spielfeld .group-3118 {
    grid-column: 4 / 5; }
  #spielfeld .group-3119 {
    grid-column: 5 / 6; }
  #spielfeld .group-3120 {
    grid-column: 6 / 7; }
  #spielfeld .group-3121 {
    grid-column: 7 / 8; }
  #spielfeld .group-3122 {
    grid-column: 8 / 9; }
  #spielfeld .group-3123 {
    grid-column: 9 / 10; }
  #spielfeld .group-3124 {
    grid-column: 10 / 11; }
  #spielfeld .group-3125 {
    grid-column: 11 / 12; }
  #spielfeld .group-3126 {
    grid-column: 12 / 13; }
  #spielfeld .group-3127 {
    grid-column: 13 / 14; }
  #spielfeld .group-3128 {
    grid-column: 14 / 15; }
  #spielfeld .group-3129 {
    grid-column: 15 / 16; }
  #spielfeld .group-3130 {
    grid-column: 16 / 17; }
  #spielfeld .group-3131 {
    grid-column: 17 / 18; }
  #spielfeld .group-3132 {
    grid-column: 18 / 19; }
  #spielfeld .group-3133 {
    grid-column: 19 / 20; }
  #spielfeld .group-3134 {
    grid-column: 20 / 21; }
  #spielfeld .group-3135 {
    grid-column: 2 / 3; }
  #spielfeld .group-3136 {
    grid-column: 3 / 4; }
  #spielfeld .group-3137 {
    grid-column: 4 / 5; }
  #spielfeld .group-3138 {
    grid-column: 5 / 6; }
  #spielfeld .group-3139 {
    grid-column: 6 / 7; }
  #spielfeld .group-3140 {
    grid-column: 7 / 8; }
  #spielfeld .group-3141 {
    grid-column: 8 / 9; }
  #spielfeld .group-3142 {
    grid-column: 9 / 10; }
  #spielfeld .group-3143 {
    grid-column: 10 / 11; }
  #spielfeld .group-3144 {
    grid-column: 11 / 12; }
  #spielfeld .group-3145 {
    grid-column: 12 / 13; }
  #spielfeld .group-3146 {
    grid-column: 13 / 14; }
  #spielfeld .group-3147 {
    grid-column: 14 / 15; }
  #spielfeld .group-3148 {
    grid-column: 15 / 16; }
  #spielfeld .group-3149 {
    grid-column: 16 / 17; }
  #spielfeld .group-3150 {
    grid-column: 17 / 18; }
  #spielfeld .group-3151 {
    grid-column: 18 / 19; }
  #spielfeld .group-3152 {
    grid-column: 19 / 20; }
  #spielfeld .group-3153 {
    grid-column: 20 / 21; }
  #spielfeld .group-3154 {
    grid-column: 2 / 3; }
  #spielfeld .group-3155 {
    grid-column: 3 / 4; }
  #spielfeld .group-3156 {
    grid-column: 4 / 5; }
  #spielfeld .group-3157 {
    grid-column: 5 / 6; }
  #spielfeld .group-3158 {
    grid-column: 6 / 7; }
  #spielfeld .group-3159 {
    grid-column: 7 / 8; }
  #spielfeld .group-3160 {
    grid-column: 8 / 9; }
  #spielfeld .group-3161 {
    grid-column: 9 / 10; }
  #spielfeld .group-3162 {
    grid-column: 10 / 11; }
  #spielfeld .group-3163 {
    grid-column: 11 / 12; }
  #spielfeld .group-3164 {
    grid-column: 12 / 13; }
  #spielfeld .group-3165 {
    grid-column: 13 / 14; }
  #spielfeld .group-3166 {
    grid-column: 14 / 15; }
  #spielfeld .group-3167 {
    grid-column: 15 / 16; }
  #spielfeld .group-3168 {
    grid-column: 16 / 17; }
  #spielfeld .group-3169 {
    grid-column: 17 / 18; }
  #spielfeld .group-3170 {
    grid-column: 18 / 19; }
  #spielfeld .group-3171 {
    grid-column: 19 / 20; }
  #spielfeld .group-3172 {
    grid-column: 20 / 21; }
  #spielfeld .group-3173 {
    grid-column: 2 / 3; }
  #spielfeld .group-3174 {
    grid-column: 3 / 4; }
  #spielfeld .group-3175 {
    grid-column: 4 / 5; }
  #spielfeld .group-3176 {
    grid-column: 5 / 6; }
  #spielfeld .group-3177 {
    grid-column: 6 / 7; }
  #spielfeld .group-3178 {
    grid-column: 7 / 8; }
  #spielfeld .group-3179 {
    grid-column: 8 / 9; }
  #spielfeld .group-3180 {
    grid-column: 9 / 10; }
  #spielfeld .group-3181 {
    grid-column: 10 / 11; }
  #spielfeld .group-3182 {
    grid-column: 11 / 12; }
  #spielfeld .group-3183 {
    grid-column: 12 / 13; }
  #spielfeld .group-3184 {
    grid-column: 13 / 14; }
  #spielfeld .group-3185 {
    grid-column: 14 / 15; }
  #spielfeld .group-3186 {
    grid-column: 15 / 16; }
  #spielfeld .group-3187 {
    grid-column: 16 / 17; }
  #spielfeld .group-3188 {
    grid-column: 17 / 18; }
  #spielfeld .group-3189 {
    grid-column: 18 / 19; }
  #spielfeld .group-3190 {
    grid-column: 19 / 20; }
  #spielfeld .group-3191 {
    grid-column: 20 / 21; }
  #spielfeld .group-3192 {
    grid-column: 2 / 3; }
  #spielfeld .group-3193 {
    grid-column: 3 / 4; }
  #spielfeld .group-3194 {
    grid-column: 4 / 5; }
  #spielfeld .group-3195 {
    grid-column: 5 / 6; }
  #spielfeld .group-3196 {
    grid-column: 6 / 7; }
  #spielfeld .group-3197 {
    grid-column: 7 / 8; }
  #spielfeld .group-3198 {
    grid-column: 8 / 9; }
  #spielfeld .group-3199 {
    grid-column: 9 / 10; }
  #spielfeld .group-3200 {
    grid-column: 10 / 11; }
  #spielfeld .group-3201 {
    grid-column: 11 / 12; }
  #spielfeld .group-3202 {
    grid-column: 12 / 13; }
  #spielfeld .group-3203 {
    grid-column: 13 / 14; }
  #spielfeld .group-3204 {
    grid-column: 14 / 15; }
  #spielfeld .group-3205 {
    grid-column: 15 / 16; }
  #spielfeld .group-3206 {
    grid-column: 16 / 17; }
  #spielfeld .group-3207 {
    grid-column: 17 / 18; }
  #spielfeld .group-3208 {
    grid-column: 18 / 19; }
  #spielfeld .group-3209 {
    grid-column: 19 / 20; }
  #spielfeld .group-3210 {
    grid-column: 20 / 21; }
  #spielfeld .group-3211 {
    grid-column: 2 / 3; }
  #spielfeld .group-3212 {
    grid-column: 3 / 4; }
  #spielfeld .group-3213 {
    grid-column: 4 / 5; }
  #spielfeld .group-3214 {
    grid-column: 5 / 6; }
  #spielfeld .group-3215 {
    grid-column: 6 / 7; }
  #spielfeld .group-3216 {
    grid-column: 7 / 8; }
  #spielfeld .group-3217 {
    grid-column: 8 / 9; }
  #spielfeld .group-3218 {
    grid-column: 9 / 10; }
  #spielfeld .group-3219 {
    grid-column: 10 / 11; }
  #spielfeld .group-3220 {
    grid-column: 11 / 12; }
  #spielfeld .group-3221 {
    grid-column: 12 / 13; }
  #spielfeld .group-3222 {
    grid-column: 13 / 14; }
  #spielfeld .group-3223 {
    grid-column: 14 / 15; }
  #spielfeld .group-3224 {
    grid-column: 15 / 16; }
  #spielfeld .group-3225 {
    grid-column: 16 / 17; }
  #spielfeld .group-3226 {
    grid-column: 17 / 18; }
  #spielfeld .group-3227 {
    grid-column: 18 / 19; }
  #spielfeld .group-3228 {
    grid-column: 19 / 20; }
  #spielfeld .group-3229 {
    grid-column: 20 / 21; }
  #spielfeld .group-3230 {
    grid-column: 2 / 3; }
  #spielfeld .group-3231 {
    grid-column: 3 / 4; }
  #spielfeld .group-3232 {
    grid-column: 4 / 5; }
  #spielfeld .group-3233 {
    grid-column: 5 / 6; }
  #spielfeld .group-3234 {
    grid-column: 6 / 7; }
  #spielfeld .group-3235 {
    grid-column: 7 / 8; }
  #spielfeld .group-3236 {
    grid-column: 8 / 9; }
  #spielfeld .group-3237 {
    grid-column: 9 / 10; }
  #spielfeld .group-3238 {
    grid-column: 10 / 11; }
  #spielfeld .group-3239 {
    grid-column: 11 / 12; }
  #spielfeld .group-3240 {
    grid-column: 12 / 13; }
  #spielfeld .group-3241 {
    grid-column: 13 / 14; }
  #spielfeld .group-3242 {
    grid-column: 14 / 15; }
  #spielfeld .group-3243 {
    grid-column: 15 / 16; }
  #spielfeld .group-3244 {
    grid-column: 16 / 17; }
  #spielfeld .group-3245 {
    grid-column: 17 / 18; }
  #spielfeld .group-3246 {
    grid-column: 18 / 19; }
  #spielfeld .group-3247 {
    grid-column: 19 / 20; }
  #spielfeld .group-3248 {
    grid-column: 20 / 21; }
  #spielfeld .group-3249 {
    grid-column: 2 / 3; }
  #spielfeld .group-3250 {
    grid-column: 3 / 4; }
  #spielfeld .group-3251 {
    grid-column: 4 / 5; }
  #spielfeld .group-3252 {
    grid-column: 5 / 6; }
  #spielfeld .group-3253 {
    grid-column: 6 / 7; }
  #spielfeld .group-3254 {
    grid-column: 7 / 8; }
  #spielfeld .group-3255 {
    grid-column: 8 / 9; }
  #spielfeld .group-3256 {
    grid-column: 9 / 10; }
  #spielfeld .group-3257 {
    grid-column: 10 / 11; }
  #spielfeld .group-3258 {
    grid-column: 11 / 12; }
  #spielfeld .group-3259 {
    grid-column: 12 / 13; }
  #spielfeld .group-3260 {
    grid-column: 13 / 14; }
  #spielfeld .group-3261 {
    grid-column: 14 / 15; }
  #spielfeld .group-3262 {
    grid-column: 15 / 16; }
  #spielfeld .group-3263 {
    grid-column: 16 / 17; }
  #spielfeld .group-3264 {
    grid-column: 17 / 18; }
  #spielfeld .group-3265 {
    grid-column: 18 / 19; }
  #spielfeld .group-3266 {
    grid-column: 19 / 20; }
  #spielfeld .group-3267 {
    grid-column: 20 / 21; }
  #spielfeld .group-3268 {
    grid-column: 2 / 3; }
  #spielfeld .group-3269 {
    grid-column: 3 / 4; }
  #spielfeld .group-3270 {
    grid-column: 4 / 5; }
  #spielfeld .group-3271 {
    grid-column: 5 / 6; }
  #spielfeld .group-3272 {
    grid-column: 6 / 7; }
  #spielfeld .group-3273 {
    grid-column: 7 / 8; }
  #spielfeld .group-3274 {
    grid-column: 8 / 9; }
  #spielfeld .group-3275 {
    grid-column: 9 / 10; }
  #spielfeld .group-3276 {
    grid-column: 10 / 11; }
  #spielfeld .group-3277 {
    grid-column: 11 / 12; }
  #spielfeld .group-3278 {
    grid-column: 12 / 13; }
  #spielfeld .group-3279 {
    grid-column: 13 / 14; }
  #spielfeld .group-3280 {
    grid-column: 14 / 15; }
  #spielfeld .group-3281 {
    grid-column: 15 / 16; }
  #spielfeld .group-3282 {
    grid-column: 16 / 17; }
  #spielfeld .group-3283 {
    grid-column: 17 / 18; }
  #spielfeld .group-3284 {
    grid-column: 18 / 19; }
  #spielfeld .group-3285 {
    grid-column: 19 / 20; }
  #spielfeld .group-3286 {
    grid-column: 20 / 21; }
  #spielfeld .group-3287 {
    grid-column: 2 / 3; }
  #spielfeld .group-3288 {
    grid-column: 3 / 4; }
  #spielfeld .group-3289 {
    grid-column: 4 / 5; }
  #spielfeld .group-3290 {
    grid-column: 5 / 6; }
  #spielfeld .group-3291 {
    grid-column: 6 / 7; }
  #spielfeld .group-3292 {
    grid-column: 7 / 8; }
  #spielfeld .group-3293 {
    grid-column: 8 / 9; }
  #spielfeld .group-3294 {
    grid-column: 9 / 10; }
  #spielfeld .group-3295 {
    grid-column: 10 / 11; }
  #spielfeld .group-3296 {
    grid-column: 11 / 12; }
  #spielfeld .group-3297 {
    grid-column: 12 / 13; }
  #spielfeld .group-3298 {
    grid-column: 13 / 14; }
  #spielfeld .group-3299 {
    grid-column: 14 / 15; }
  #spielfeld .group-3300 {
    grid-column: 15 / 16; }
  #spielfeld .group-3301 {
    grid-column: 16 / 17; }
  #spielfeld .group-3302 {
    grid-column: 17 / 18; }
  #spielfeld .group-3303 {
    grid-column: 18 / 19; }
  #spielfeld .group-3304 {
    grid-column: 19 / 20; }
  #spielfeld .group-3305 {
    grid-column: 20 / 21; }
  #spielfeld .group-3306 {
    grid-column: 2 / 3; }
  #spielfeld .group-3307 {
    grid-column: 3 / 4; }
  #spielfeld .group-3308 {
    grid-column: 4 / 5; }
  #spielfeld .group-3309 {
    grid-column: 5 / 6; }
  #spielfeld .group-3310 {
    grid-column: 6 / 7; }
  #spielfeld .group-3311 {
    grid-column: 7 / 8; }
  #spielfeld .group-3312 {
    grid-column: 8 / 9; }
  #spielfeld .group-3313 {
    grid-column: 9 / 10; }
  #spielfeld .group-3314 {
    grid-column: 10 / 11; }
  #spielfeld .group-3315 {
    grid-column: 11 / 12; }
  #spielfeld .group-3316 {
    grid-column: 12 / 13; }
  #spielfeld .group-3317 {
    grid-column: 13 / 14; }
  #spielfeld .group-3318 {
    grid-column: 14 / 15; }
  #spielfeld .group-3319 {
    grid-column: 15 / 16; }
  #spielfeld .group-3320 {
    grid-column: 16 / 17; }
  #spielfeld .group-3321 {
    grid-column: 17 / 18; }
  #spielfeld .group-3322 {
    grid-column: 18 / 19; }
  #spielfeld .group-3323 {
    grid-column: 19 / 20; }
  #spielfeld .group-3324 {
    grid-column: 20 / 21; }
  #spielfeld .group-3325 {
    grid-column: 2 / 3; }
  #spielfeld .group-3326 {
    grid-column: 3 / 4; }
  #spielfeld .group-3327 {
    grid-column: 4 / 5; }
  #spielfeld .group-3328 {
    grid-column: 5 / 6; }
  #spielfeld .group-3329 {
    grid-column: 6 / 7; }
  #spielfeld .group-3330 {
    grid-column: 7 / 8; }
  #spielfeld .group-3331 {
    grid-column: 8 / 9; }
  #spielfeld .group-3332 {
    grid-column: 9 / 10; }
  #spielfeld .group-3333 {
    grid-column: 10 / 11; }
  #spielfeld .group-3334 {
    grid-column: 11 / 12; }
  #spielfeld .group-3335 {
    grid-column: 12 / 13; }
  #spielfeld .group-3336 {
    grid-column: 13 / 14; }
  #spielfeld .group-3337 {
    grid-column: 14 / 15; }
  #spielfeld .group-3338 {
    grid-column: 15 / 16; }
  #spielfeld .group-3339 {
    grid-column: 16 / 17; }
  #spielfeld .group-3340 {
    grid-column: 17 / 18; }
  #spielfeld .group-3341 {
    grid-column: 18 / 19; }
  #spielfeld .group-3342 {
    grid-column: 19 / 20; }
  #spielfeld .group-3343 {
    grid-column: 20 / 21; }
  #spielfeld .group-3344 {
    grid-column: 2 / 3; }
  #spielfeld .group-3345 {
    grid-column: 3 / 4; }
  #spielfeld .group-3346 {
    grid-column: 4 / 5; }
  #spielfeld .group-3347 {
    grid-column: 5 / 6; }
  #spielfeld .group-3348 {
    grid-column: 6 / 7; }
  #spielfeld .group-3349 {
    grid-column: 7 / 8; }
  #spielfeld .group-3350 {
    grid-column: 8 / 9; }
  #spielfeld .group-3351 {
    grid-column: 9 / 10; }
  #spielfeld .group-3352 {
    grid-column: 10 / 11; }
  #spielfeld .group-3353 {
    grid-column: 11 / 12; }
  #spielfeld .group-3354 {
    grid-column: 12 / 13; }
  #spielfeld .group-3355 {
    grid-column: 13 / 14; }
  #spielfeld .group-3356 {
    grid-column: 14 / 15; }
  #spielfeld .group-3357 {
    grid-column: 15 / 16; }
  #spielfeld .group-3358 {
    grid-column: 16 / 17; }
  #spielfeld .group-3359 {
    grid-column: 17 / 18; }
  #spielfeld .group-3360 {
    grid-column: 18 / 19; }
  #spielfeld .group-3361 {
    grid-column: 19 / 20; }
  #spielfeld .group-3362 {
    grid-column: 20 / 21; }
  #spielfeld .group-3363 {
    grid-column: 2 / 3; }
  #spielfeld .group-3364 {
    grid-column: 3 / 4; }
  #spielfeld .group-3365 {
    grid-column: 4 / 5; }
  #spielfeld .group-3366 {
    grid-column: 5 / 6; }
  #spielfeld .group-3367 {
    grid-column: 6 / 7; }
  #spielfeld .group-3368 {
    grid-column: 7 / 8; }
  #spielfeld .group-3369 {
    grid-column: 8 / 9; }
  #spielfeld .group-3370 {
    grid-column: 9 / 10; }
  #spielfeld .group-3371 {
    grid-column: 10 / 11; }
  #spielfeld .group-3372 {
    grid-column: 11 / 12; }
  #spielfeld .group-3373 {
    grid-column: 12 / 13; }
  #spielfeld .group-3374 {
    grid-column: 13 / 14; }
  #spielfeld .group-3375 {
    grid-column: 14 / 15; }
  #spielfeld .group-3376 {
    grid-column: 15 / 16; }
  #spielfeld .group-3377 {
    grid-column: 16 / 17; }
  #spielfeld .group-3378 {
    grid-column: 17 / 18; }
  #spielfeld .group-3379 {
    grid-column: 18 / 19; }
  #spielfeld .group-3380 {
    grid-column: 19 / 20; }
  #spielfeld .group-3381 {
    grid-column: 20 / 21; }
  #spielfeld .group-3382 {
    grid-column: 2 / 3; }
  #spielfeld .group-3383 {
    grid-column: 3 / 4; }
  #spielfeld .group-3384 {
    grid-column: 4 / 5; }
  #spielfeld .group-3385 {
    grid-column: 5 / 6; }
  #spielfeld .group-3386 {
    grid-column: 6 / 7; }
  #spielfeld .group-3387 {
    grid-column: 7 / 8; }
  #spielfeld .group-3388 {
    grid-column: 8 / 9; }
  #spielfeld .group-3389 {
    grid-column: 9 / 10; }
  #spielfeld .group-3390 {
    grid-column: 10 / 11; }
  #spielfeld .group-3391 {
    grid-column: 11 / 12; }
  #spielfeld .group-3392 {
    grid-column: 12 / 13; }
  #spielfeld .group-3393 {
    grid-column: 13 / 14; }
  #spielfeld .group-3394 {
    grid-column: 14 / 15; }
  #spielfeld .group-3395 {
    grid-column: 15 / 16; }
  #spielfeld .group-3396 {
    grid-column: 16 / 17; }
  #spielfeld .group-3397 {
    grid-column: 17 / 18; }
  #spielfeld .group-3398 {
    grid-column: 18 / 19; }
  #spielfeld .group-3399 {
    grid-column: 19 / 20; }
  #spielfeld .group-3400 {
    grid-column: 20 / 21; }
  #spielfeld .group-3401 {
    grid-column: 2 / 3; }
  #spielfeld .group-3402 {
    grid-column: 3 / 4; }
  #spielfeld .group-3403 {
    grid-column: 4 / 5; }
  #spielfeld .group-3404 {
    grid-column: 5 / 6; }
  #spielfeld .group-3405 {
    grid-column: 6 / 7; }
  #spielfeld .group-3406 {
    grid-column: 7 / 8; }
  #spielfeld .group-3407 {
    grid-column: 8 / 9; }
  #spielfeld .group-3408 {
    grid-column: 9 / 10; }
  #spielfeld .group-3409 {
    grid-column: 10 / 11; }
  #spielfeld .group-3410 {
    grid-column: 11 / 12; }
  #spielfeld .group-3411 {
    grid-column: 12 / 13; }
  #spielfeld .group-3412 {
    grid-column: 13 / 14; }
  #spielfeld .group-3413 {
    grid-column: 14 / 15; }
  #spielfeld .group-3414 {
    grid-column: 15 / 16; }
  #spielfeld .group-3415 {
    grid-column: 16 / 17; }
  #spielfeld .group-3416 {
    grid-column: 17 / 18; }
  #spielfeld .group-3417 {
    grid-column: 18 / 19; }
  #spielfeld .group-3418 {
    grid-column: 19 / 20; }
  #spielfeld .group-3419 {
    grid-column: 20 / 21; }
  #spielfeld .group-3420 {
    grid-column: 2 / 3; }
  #spielfeld .group-3421 {
    grid-column: 3 / 4; }
  #spielfeld .group-3422 {
    grid-column: 4 / 5; }
  #spielfeld .group-3423 {
    grid-column: 5 / 6; }
  #spielfeld .group-3424 {
    grid-column: 6 / 7; }
  #spielfeld .group-3425 {
    grid-column: 7 / 8; }
  #spielfeld .group-3426 {
    grid-column: 8 / 9; }
  #spielfeld .group-3427 {
    grid-column: 9 / 10; }
  #spielfeld .group-3428 {
    grid-column: 10 / 11; }
  #spielfeld .group-3429 {
    grid-column: 11 / 12; }
  #spielfeld .group-3430 {
    grid-column: 12 / 13; }
  #spielfeld .group-3431 {
    grid-column: 13 / 14; }
  #spielfeld .group-3432 {
    grid-column: 14 / 15; }
  #spielfeld .group-3433 {
    grid-column: 15 / 16; }
  #spielfeld .group-3434 {
    grid-column: 16 / 17; }
  #spielfeld .group-3435 {
    grid-column: 17 / 18; }
  #spielfeld .group-3436 {
    grid-column: 18 / 19; }
  #spielfeld .group-3437 {
    grid-column: 19 / 20; }
  #spielfeld .group-3438 {
    grid-column: 20 / 21; }
  #spielfeld .group-3439 {
    grid-column: 2 / 3; }
  #spielfeld .group-3440 {
    grid-column: 3 / 4; }
  #spielfeld .group-3441 {
    grid-column: 4 / 5; }
  #spielfeld .group-3442 {
    grid-column: 5 / 6; }
  #spielfeld .group-3443 {
    grid-column: 6 / 7; }
  #spielfeld .group-3444 {
    grid-column: 7 / 8; }
  #spielfeld .group-3445 {
    grid-column: 8 / 9; }
  #spielfeld .group-3446 {
    grid-column: 9 / 10; }
  #spielfeld .group-3447 {
    grid-column: 10 / 11; }
  #spielfeld .group-3448 {
    grid-column: 11 / 12; }
  #spielfeld .group-3449 {
    grid-column: 12 / 13; }
  #spielfeld .group-3450 {
    grid-column: 13 / 14; }
  #spielfeld .group-3451 {
    grid-column: 14 / 15; }
  #spielfeld .group-3452 {
    grid-column: 15 / 16; }
  #spielfeld .group-3453 {
    grid-column: 16 / 17; }
  #spielfeld .group-3454 {
    grid-column: 17 / 18; }
  #spielfeld .group-3455 {
    grid-column: 18 / 19; }
  #spielfeld .group-3456 {
    grid-column: 19 / 20; }
  #spielfeld .group-3457 {
    grid-column: 20 / 21; }
  #spielfeld .group-3458 {
    grid-column: 2 / 3; }
  #spielfeld .group-3459 {
    grid-column: 3 / 4; }
  #spielfeld .group-3460 {
    grid-column: 4 / 5; }
  #spielfeld .group-3461 {
    grid-column: 5 / 6; }
  #spielfeld .group-3462 {
    grid-column: 6 / 7; }
  #spielfeld .group-3463 {
    grid-column: 7 / 8; }
  #spielfeld .group-3464 {
    grid-column: 8 / 9; }
  #spielfeld .group-3465 {
    grid-column: 9 / 10; }
  #spielfeld .group-3466 {
    grid-column: 10 / 11; }
  #spielfeld .group-3467 {
    grid-column: 11 / 12; }
  #spielfeld .group-3468 {
    grid-column: 12 / 13; }
  #spielfeld .group-3469 {
    grid-column: 13 / 14; }
  #spielfeld .group-3470 {
    grid-column: 14 / 15; }
  #spielfeld .group-3471 {
    grid-column: 15 / 16; }
  #spielfeld .group-3472 {
    grid-column: 16 / 17; }
  #spielfeld .group-3473 {
    grid-column: 17 / 18; }
  #spielfeld .group-3474 {
    grid-column: 18 / 19; }
  #spielfeld .group-3475 {
    grid-column: 19 / 20; }
  #spielfeld .group-3476 {
    grid-column: 20 / 21; }
  #spielfeld .group-3477 {
    grid-column: 2 / 3; }
  #spielfeld .group-3478 {
    grid-column: 3 / 4; }
  #spielfeld .group-3479 {
    grid-column: 4 / 5; }
  #spielfeld .group-3480 {
    grid-column: 5 / 6; }
  #spielfeld .group-3481 {
    grid-column: 6 / 7; }
  #spielfeld .group-3482 {
    grid-column: 7 / 8; }
  #spielfeld .group-3483 {
    grid-column: 8 / 9; }
  #spielfeld .group-3484 {
    grid-column: 9 / 10; }
  #spielfeld .group-3485 {
    grid-column: 10 / 11; }
  #spielfeld .group-3486 {
    grid-column: 11 / 12; }
  #spielfeld .group-3487 {
    grid-column: 12 / 13; }
  #spielfeld .group-3488 {
    grid-column: 13 / 14; }
  #spielfeld .group-3489 {
    grid-column: 14 / 15; }
  #spielfeld .group-3490 {
    grid-column: 15 / 16; }
  #spielfeld .group-3491 {
    grid-column: 16 / 17; }
  #spielfeld .group-3492 {
    grid-column: 17 / 18; }
  #spielfeld .group-3493 {
    grid-column: 18 / 19; }
  #spielfeld .group-3494 {
    grid-column: 19 / 20; }
  #spielfeld .group-3495 {
    grid-column: 20 / 21; }
  #spielfeld .group-3496 {
    grid-column: 2 / 3; }
  #spielfeld .group-3497 {
    grid-column: 3 / 4; }
  #spielfeld .group-3498 {
    grid-column: 4 / 5; }
  #spielfeld .group-3499 {
    grid-column: 5 / 6; }
  #spielfeld .group-3500 {
    grid-column: 6 / 7; }
  #spielfeld .group-3501 {
    grid-column: 7 / 8; }
  #spielfeld .group-3502 {
    grid-column: 8 / 9; }
  #spielfeld .group-3503 {
    grid-column: 9 / 10; }
  #spielfeld .group-3504 {
    grid-column: 10 / 11; }
  #spielfeld .group-3505 {
    grid-column: 11 / 12; }
  #spielfeld .group-3506 {
    grid-column: 12 / 13; }
  #spielfeld .group-3507 {
    grid-column: 13 / 14; }
  #spielfeld .group-3508 {
    grid-column: 14 / 15; }
  #spielfeld .group-3509 {
    grid-column: 15 / 16; }
  #spielfeld .group-3510 {
    grid-column: 16 / 17; }
  #spielfeld .group-3511 {
    grid-column: 17 / 18; }
  #spielfeld .group-3512 {
    grid-column: 18 / 19; }
  #spielfeld .group-3513 {
    grid-column: 19 / 20; }
  #spielfeld .group-3514 {
    grid-column: 20 / 21; }
  #spielfeld .group-3515 {
    grid-column: 2 / 3; }
  #spielfeld .group-3516 {
    grid-column: 3 / 4; }
  #spielfeld .group-3517 {
    grid-column: 4 / 5; }
  #spielfeld .group-3518 {
    grid-column: 5 / 6; }
  #spielfeld .group-3519 {
    grid-column: 6 / 7; }
  #spielfeld .group-3520 {
    grid-column: 7 / 8; }
  #spielfeld .group-3521 {
    grid-column: 8 / 9; }
  #spielfeld .group-3522 {
    grid-column: 9 / 10; }
  #spielfeld .group-3523 {
    grid-column: 10 / 11; }
  #spielfeld .group-3524 {
    grid-column: 11 / 12; }
  #spielfeld .group-3525 {
    grid-column: 12 / 13; }
  #spielfeld .group-3526 {
    grid-column: 13 / 14; }
  #spielfeld .group-3527 {
    grid-column: 14 / 15; }
  #spielfeld .group-3528 {
    grid-column: 15 / 16; }
  #spielfeld .group-3529 {
    grid-column: 16 / 17; }
  #spielfeld .group-3530 {
    grid-column: 17 / 18; }
  #spielfeld .group-3531 {
    grid-column: 18 / 19; }
  #spielfeld .group-3532 {
    grid-column: 19 / 20; }
  #spielfeld .group-3533 {
    grid-column: 20 / 21; }
  #spielfeld .group-3534 {
    grid-column: 2 / 3; }
  #spielfeld .group-3535 {
    grid-column: 3 / 4; }
  #spielfeld .group-3536 {
    grid-column: 4 / 5; }
  #spielfeld .group-3537 {
    grid-column: 5 / 6; }
  #spielfeld .group-3538 {
    grid-column: 6 / 7; }
  #spielfeld .group-3539 {
    grid-column: 7 / 8; }
  #spielfeld .group-3540 {
    grid-column: 8 / 9; }
  #spielfeld .group-3541 {
    grid-column: 9 / 10; }
  #spielfeld .group-3542 {
    grid-column: 10 / 11; }
  #spielfeld .group-3543 {
    grid-column: 11 / 12; }
  #spielfeld .group-3544 {
    grid-column: 12 / 13; }
  #spielfeld .group-3545 {
    grid-column: 13 / 14; }
  #spielfeld .group-3546 {
    grid-column: 14 / 15; }
  #spielfeld .group-3547 {
    grid-column: 15 / 16; }
  #spielfeld .group-3548 {
    grid-column: 16 / 17; }
  #spielfeld .group-3549 {
    grid-column: 17 / 18; }
  #spielfeld .group-3550 {
    grid-column: 18 / 19; }
  #spielfeld .group-3551 {
    grid-column: 19 / 20; }
  #spielfeld .group-3552 {
    grid-column: 20 / 21; }
  #spielfeld .group-3553 {
    grid-column: 2 / 3; }
  #spielfeld .group-3554 {
    grid-column: 3 / 4; }
  #spielfeld .group-3555 {
    grid-column: 4 / 5; }
  #spielfeld .group-3556 {
    grid-column: 5 / 6; }
  #spielfeld .group-3557 {
    grid-column: 6 / 7; }
  #spielfeld .group-3558 {
    grid-column: 7 / 8; }
  #spielfeld .group-3559 {
    grid-column: 8 / 9; }
  #spielfeld .group-3560 {
    grid-column: 9 / 10; }
  #spielfeld .group-3561 {
    grid-column: 10 / 11; }
  #spielfeld .group-3562 {
    grid-column: 11 / 12; }
  #spielfeld .group-3563 {
    grid-column: 12 / 13; }
  #spielfeld .group-3564 {
    grid-column: 13 / 14; }
  #spielfeld .group-3565 {
    grid-column: 14 / 15; }
  #spielfeld .group-3566 {
    grid-column: 15 / 16; }
  #spielfeld .group-3567 {
    grid-column: 16 / 17; }
  #spielfeld .group-3568 {
    grid-column: 17 / 18; }
  #spielfeld .group-3569 {
    grid-column: 18 / 19; }
  #spielfeld .group-3570 {
    grid-column: 19 / 20; }
  #spielfeld .group-3571 {
    grid-column: 20 / 21; }
  #spielfeld .group-3572 {
    grid-column: 2 / 3; }
  #spielfeld .group-3573 {
    grid-column: 3 / 4; }
  #spielfeld .group-3574 {
    grid-column: 4 / 5; }
  #spielfeld .group-3575 {
    grid-column: 5 / 6; }
  #spielfeld .group-3576 {
    grid-column: 6 / 7; }
  #spielfeld .group-3577 {
    grid-column: 7 / 8; }
  #spielfeld .group-3578 {
    grid-column: 8 / 9; }
  #spielfeld .group-3579 {
    grid-column: 9 / 10; }
  #spielfeld .group-3580 {
    grid-column: 10 / 11; }
  #spielfeld .group-3581 {
    grid-column: 11 / 12; }
  #spielfeld .group-3582 {
    grid-column: 12 / 13; }
  #spielfeld .group-3583 {
    grid-column: 13 / 14; }
  #spielfeld .group-3584 {
    grid-column: 14 / 15; }
  #spielfeld .group-3585 {
    grid-column: 15 / 16; }
  #spielfeld .group-3586 {
    grid-column: 16 / 17; }
  #spielfeld .group-3587 {
    grid-column: 17 / 18; }
  #spielfeld .group-3588 {
    grid-column: 18 / 19; }
  #spielfeld .group-3589 {
    grid-column: 19 / 20; }
  #spielfeld .group-3590 {
    grid-column: 20 / 21; }
  #spielfeld .group-3591 {
    grid-column: 2 / 3; }
  #spielfeld .group-3592 {
    grid-column: 3 / 4; }
  #spielfeld .group-3593 {
    grid-column: 4 / 5; }
  #spielfeld .group-3594 {
    grid-column: 5 / 6; }
  #spielfeld .group-3595 {
    grid-column: 6 / 7; }
  #spielfeld .group-3596 {
    grid-column: 7 / 8; }
  #spielfeld .group-3597 {
    grid-column: 8 / 9; }
  #spielfeld .group-3598 {
    grid-column: 9 / 10; }
  #spielfeld .group-3599 {
    grid-column: 10 / 11; }
  #spielfeld .group-3600 {
    grid-column: 11 / 12; }
  #spielfeld .group-3601 {
    grid-column: 12 / 13; }
  #spielfeld .group-3602 {
    grid-column: 13 / 14; }
  #spielfeld .group-3603 {
    grid-column: 14 / 15; }
  #spielfeld .group-3604 {
    grid-column: 15 / 16; }
  #spielfeld .group-3605 {
    grid-column: 16 / 17; }
  #spielfeld .group-3606 {
    grid-column: 17 / 18; }
  #spielfeld .group-3607 {
    grid-column: 18 / 19; }
  #spielfeld .group-3608 {
    grid-column: 19 / 20; }
  #spielfeld .group-3609 {
    grid-column: 20 / 21; }
  #spielfeld .group-0 {
    grid-row: 2 / 3; }
  #spielfeld .group-1 {
    grid-row: 2 / 3; }
  #spielfeld .group-2 {
    grid-row: 2 / 3; }
  #spielfeld .group-3 {
    grid-row: 2 / 3; }
  #spielfeld .group-4 {
    grid-row: 2 / 3; }
  #spielfeld .group-5 {
    grid-row: 2 / 3; }
  #spielfeld .group-6 {
    grid-row: 2 / 3; }
  #spielfeld .group-7 {
    grid-row: 2 / 3; }
  #spielfeld .group-8 {
    grid-row: 2 / 3; }
  #spielfeld .group-9 {
    grid-row: 2 / 3; }
  #spielfeld .group-10 {
    grid-row: 2 / 3; }
  #spielfeld .group-11 {
    grid-row: 2 / 3; }
  #spielfeld .group-12 {
    grid-row: 2 / 3; }
  #spielfeld .group-13 {
    grid-row: 2 / 3; }
  #spielfeld .group-14 {
    grid-row: 2 / 3; }
  #spielfeld .group-15 {
    grid-row: 2 / 3; }
  #spielfeld .group-16 {
    grid-row: 2 / 3; }
  #spielfeld .group-17 {
    grid-row: 2 / 3; }
  #spielfeld .group-18 {
    grid-row: 2 / 3; }
  #spielfeld .group-19 {
    grid-row: 3 / 4; }
  #spielfeld .group-20 {
    grid-row: 3 / 4; }
  #spielfeld .group-21 {
    grid-row: 3 / 4; }
  #spielfeld .group-22 {
    grid-row: 3 / 4; }
  #spielfeld .group-23 {
    grid-row: 3 / 4; }
  #spielfeld .group-24 {
    grid-row: 3 / 4; }
  #spielfeld .group-25 {
    grid-row: 3 / 4; }
  #spielfeld .group-26 {
    grid-row: 3 / 4; }
  #spielfeld .group-27 {
    grid-row: 3 / 4; }
  #spielfeld .group-28 {
    grid-row: 3 / 4; }
  #spielfeld .group-29 {
    grid-row: 3 / 4; }
  #spielfeld .group-30 {
    grid-row: 3 / 4; }
  #spielfeld .group-31 {
    grid-row: 3 / 4; }
  #spielfeld .group-32 {
    grid-row: 3 / 4; }
  #spielfeld .group-33 {
    grid-row: 3 / 4; }
  #spielfeld .group-34 {
    grid-row: 3 / 4; }
  #spielfeld .group-35 {
    grid-row: 3 / 4; }
  #spielfeld .group-36 {
    grid-row: 3 / 4; }
  #spielfeld .group-37 {
    grid-row: 3 / 4; }
  #spielfeld .group-38 {
    grid-row: 4 / 5; }
  #spielfeld .group-39 {
    grid-row: 4 / 5; }
  #spielfeld .group-40 {
    grid-row: 4 / 5; }
  #spielfeld .group-41 {
    grid-row: 4 / 5; }
  #spielfeld .group-42 {
    grid-row: 4 / 5; }
  #spielfeld .group-43 {
    grid-row: 4 / 5; }
  #spielfeld .group-44 {
    grid-row: 4 / 5; }
  #spielfeld .group-45 {
    grid-row: 4 / 5; }
  #spielfeld .group-46 {
    grid-row: 4 / 5; }
  #spielfeld .group-47 {
    grid-row: 4 / 5; }
  #spielfeld .group-48 {
    grid-row: 4 / 5; }
  #spielfeld .group-49 {
    grid-row: 4 / 5; }
  #spielfeld .group-50 {
    grid-row: 4 / 5; }
  #spielfeld .group-51 {
    grid-row: 4 / 5; }
  #spielfeld .group-52 {
    grid-row: 4 / 5; }
  #spielfeld .group-53 {
    grid-row: 4 / 5; }
  #spielfeld .group-54 {
    grid-row: 4 / 5; }
  #spielfeld .group-55 {
    grid-row: 4 / 5; }
  #spielfeld .group-56 {
    grid-row: 4 / 5; }
  #spielfeld .group-57 {
    grid-row: 5 / 6; }
  #spielfeld .group-58 {
    grid-row: 5 / 6; }
  #spielfeld .group-59 {
    grid-row: 5 / 6; }
  #spielfeld .group-60 {
    grid-row: 5 / 6; }
  #spielfeld .group-61 {
    grid-row: 5 / 6; }
  #spielfeld .group-62 {
    grid-row: 5 / 6; }
  #spielfeld .group-63 {
    grid-row: 5 / 6; }
  #spielfeld .group-64 {
    grid-row: 5 / 6; }
  #spielfeld .group-65 {
    grid-row: 5 / 6; }
  #spielfeld .group-66 {
    grid-row: 5 / 6; }
  #spielfeld .group-67 {
    grid-row: 5 / 6; }
  #spielfeld .group-68 {
    grid-row: 5 / 6; }
  #spielfeld .group-69 {
    grid-row: 5 / 6; }
  #spielfeld .group-70 {
    grid-row: 5 / 6; }
  #spielfeld .group-71 {
    grid-row: 5 / 6; }
  #spielfeld .group-72 {
    grid-row: 5 / 6; }
  #spielfeld .group-73 {
    grid-row: 5 / 6; }
  #spielfeld .group-74 {
    grid-row: 5 / 6; }
  #spielfeld .group-75 {
    grid-row: 5 / 6; }
  #spielfeld .group-76 {
    grid-row: 6 / 7; }
  #spielfeld .group-77 {
    grid-row: 6 / 7; }
  #spielfeld .group-78 {
    grid-row: 6 / 7; }
  #spielfeld .group-79 {
    grid-row: 6 / 7; }
  #spielfeld .group-80 {
    grid-row: 6 / 7; }
  #spielfeld .group-81 {
    grid-row: 6 / 7; }
  #spielfeld .group-82 {
    grid-row: 6 / 7; }
  #spielfeld .group-83 {
    grid-row: 6 / 7; }
  #spielfeld .group-84 {
    grid-row: 6 / 7; }
  #spielfeld .group-85 {
    grid-row: 6 / 7; }
  #spielfeld .group-86 {
    grid-row: 6 / 7; }
  #spielfeld .group-87 {
    grid-row: 6 / 7; }
  #spielfeld .group-88 {
    grid-row: 6 / 7; }
  #spielfeld .group-89 {
    grid-row: 6 / 7; }
  #spielfeld .group-90 {
    grid-row: 6 / 7; }
  #spielfeld .group-91 {
    grid-row: 6 / 7; }
  #spielfeld .group-92 {
    grid-row: 6 / 7; }
  #spielfeld .group-93 {
    grid-row: 6 / 7; }
  #spielfeld .group-94 {
    grid-row: 6 / 7; }
  #spielfeld .group-95 {
    grid-row: 7 / 8; }
  #spielfeld .group-96 {
    grid-row: 7 / 8; }
  #spielfeld .group-97 {
    grid-row: 7 / 8; }
  #spielfeld .group-98 {
    grid-row: 7 / 8; }
  #spielfeld .group-99 {
    grid-row: 7 / 8; }
  #spielfeld .group-100 {
    grid-row: 7 / 8; }
  #spielfeld .group-101 {
    grid-row: 7 / 8; }
  #spielfeld .group-102 {
    grid-row: 7 / 8; }
  #spielfeld .group-103 {
    grid-row: 7 / 8; }
  #spielfeld .group-104 {
    grid-row: 7 / 8; }
  #spielfeld .group-105 {
    grid-row: 7 / 8; }
  #spielfeld .group-106 {
    grid-row: 7 / 8; }
  #spielfeld .group-107 {
    grid-row: 7 / 8; }
  #spielfeld .group-108 {
    grid-row: 7 / 8; }
  #spielfeld .group-109 {
    grid-row: 7 / 8; }
  #spielfeld .group-110 {
    grid-row: 7 / 8; }
  #spielfeld .group-111 {
    grid-row: 7 / 8; }
  #spielfeld .group-112 {
    grid-row: 7 / 8; }
  #spielfeld .group-113 {
    grid-row: 7 / 8; }
  #spielfeld .group-114 {
    grid-row: 8 / 9; }
  #spielfeld .group-115 {
    grid-row: 8 / 9; }
  #spielfeld .group-116 {
    grid-row: 8 / 9; }
  #spielfeld .group-117 {
    grid-row: 8 / 9; }
  #spielfeld .group-118 {
    grid-row: 8 / 9; }
  #spielfeld .group-119 {
    grid-row: 8 / 9; }
  #spielfeld .group-120 {
    grid-row: 8 / 9; }
  #spielfeld .group-121 {
    grid-row: 8 / 9; }
  #spielfeld .group-122 {
    grid-row: 8 / 9; }
  #spielfeld .group-123 {
    grid-row: 8 / 9; }
  #spielfeld .group-124 {
    grid-row: 8 / 9; }
  #spielfeld .group-125 {
    grid-row: 8 / 9; }
  #spielfeld .group-126 {
    grid-row: 8 / 9; }
  #spielfeld .group-127 {
    grid-row: 8 / 9; }
  #spielfeld .group-128 {
    grid-row: 8 / 9; }
  #spielfeld .group-129 {
    grid-row: 8 / 9; }
  #spielfeld .group-130 {
    grid-row: 8 / 9; }
  #spielfeld .group-131 {
    grid-row: 8 / 9; }
  #spielfeld .group-132 {
    grid-row: 8 / 9; }
  #spielfeld .group-133 {
    grid-row: 9 / 10; }
  #spielfeld .group-134 {
    grid-row: 9 / 10; }
  #spielfeld .group-135 {
    grid-row: 9 / 10; }
  #spielfeld .group-136 {
    grid-row: 9 / 10; }
  #spielfeld .group-137 {
    grid-row: 9 / 10; }
  #spielfeld .group-138 {
    grid-row: 9 / 10; }
  #spielfeld .group-139 {
    grid-row: 9 / 10; }
  #spielfeld .group-140 {
    grid-row: 9 / 10; }
  #spielfeld .group-141 {
    grid-row: 9 / 10; }
  #spielfeld .group-142 {
    grid-row: 9 / 10; }
  #spielfeld .group-143 {
    grid-row: 9 / 10; }
  #spielfeld .group-144 {
    grid-row: 9 / 10; }
  #spielfeld .group-145 {
    grid-row: 9 / 10; }
  #spielfeld .group-146 {
    grid-row: 9 / 10; }
  #spielfeld .group-147 {
    grid-row: 9 / 10; }
  #spielfeld .group-148 {
    grid-row: 9 / 10; }
  #spielfeld .group-149 {
    grid-row: 9 / 10; }
  #spielfeld .group-150 {
    grid-row: 9 / 10; }
  #spielfeld .group-151 {
    grid-row: 9 / 10; }
  #spielfeld .group-152 {
    grid-row: 10 / 11; }
  #spielfeld .group-153 {
    grid-row: 10 / 11; }
  #spielfeld .group-154 {
    grid-row: 10 / 11; }
  #spielfeld .group-155 {
    grid-row: 10 / 11; }
  #spielfeld .group-156 {
    grid-row: 10 / 11; }
  #spielfeld .group-157 {
    grid-row: 10 / 11; }
  #spielfeld .group-158 {
    grid-row: 10 / 11; }
  #spielfeld .group-159 {
    grid-row: 10 / 11; }
  #spielfeld .group-160 {
    grid-row: 10 / 11; }
  #spielfeld .group-161 {
    grid-row: 10 / 11; }
  #spielfeld .group-162 {
    grid-row: 10 / 11; }
  #spielfeld .group-163 {
    grid-row: 10 / 11; }
  #spielfeld .group-164 {
    grid-row: 10 / 11; }
  #spielfeld .group-165 {
    grid-row: 10 / 11; }
  #spielfeld .group-166 {
    grid-row: 10 / 11; }
  #spielfeld .group-167 {
    grid-row: 10 / 11; }
  #spielfeld .group-168 {
    grid-row: 10 / 11; }
  #spielfeld .group-169 {
    grid-row: 10 / 11; }
  #spielfeld .group-170 {
    grid-row: 10 / 11; }
  #spielfeld .group-171 {
    grid-row: 11 / 12; }
  #spielfeld .group-172 {
    grid-row: 11 / 12; }
  #spielfeld .group-173 {
    grid-row: 11 / 12; }
  #spielfeld .group-174 {
    grid-row: 11 / 12; }
  #spielfeld .group-175 {
    grid-row: 11 / 12; }
  #spielfeld .group-176 {
    grid-row: 11 / 12; }
  #spielfeld .group-177 {
    grid-row: 11 / 12; }
  #spielfeld .group-178 {
    grid-row: 11 / 12; }
  #spielfeld .group-179 {
    grid-row: 11 / 12; }
  #spielfeld .group-180 {
    grid-row: 11 / 12; }
  #spielfeld .group-181 {
    grid-row: 11 / 12; }
  #spielfeld .group-182 {
    grid-row: 11 / 12; }
  #spielfeld .group-183 {
    grid-row: 11 / 12; }
  #spielfeld .group-184 {
    grid-row: 11 / 12; }
  #spielfeld .group-185 {
    grid-row: 11 / 12; }
  #spielfeld .group-186 {
    grid-row: 11 / 12; }
  #spielfeld .group-187 {
    grid-row: 11 / 12; }
  #spielfeld .group-188 {
    grid-row: 11 / 12; }
  #spielfeld .group-189 {
    grid-row: 11 / 12; }

#endscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(49, 141, 166, 0.8);
  display: none; }
  #endscreen.active {
    display: flex; }
  #endscreen .content {
    background: #161616;
    padding: 2rem;
    width: 40rem;
    max-width: 90vw;
    text-align: center;
    border-radius: 5px; }
    #endscreen .content h1 {
      color: #ffb81c; }
    #endscreen .content h2 {
      color: white; }
